const UserForShipperRequestKeys = {
  TITLE: "title",
  USER_PROFILE_ID: "userProfileId",
  PARTY_ID: "partyId",
  UserShipperRequestType: {
    TITLE: "userShipperRequestType.title",
    SHIPPER_REQUEST_TYPE_ID: "userShipperRequestType.shipperRequestTypeId"
  }
}

export default UserForShipperRequestKeys
