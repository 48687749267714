import React from 'react'
import { Typography } from '@material-ui/core'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

function MandatoryFieldsLabel({
    ...props
}) {

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const mandatoryFieldsText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.MANDATORY_FIELDS_WITHOUT_ASTERISK);

    return (
        <Typography
            component='sub' color='textSecondary'
            style={{ fontSize: 'small', display: 'block', marginBottom: '1.5em' }}
            {...props}
        >
            <span style={{ color: 'red' }}>*</span>{mandatoryFieldsText}
        </Typography>
    )
}

export default MandatoryFieldsLabel
