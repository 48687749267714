const PlatformPortMappingKeys = {
  TITLE: "title",
  PLATFORM: "platform",
  CARRIER_PARTY_ID: "carrierPartyId",
  CALISTA_PORT_CODE: "calistaPortCode",
  PARTY_PORT_CODE: "partyPortCode",
  PARTY_PORT_DESC: "partyPortDesc",
  MESSAGE_TYPE: "messageType",
  CREATED_DATE: "createdDate"
}

export default PlatformPortMappingKeys
