import React from 'react'
import { Button, } from '@material-ui/core'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeLight)

function EditButton2({
	label = false,
	...props
}) {

	const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
	const defaultLabel = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.EDIT);

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Button
			variant='contained'
			classes={{ root: 'ng-button-filled-primary' }}
			{...props}
		>
			<FontAwesomeIcon icon={['far', 'edit']} />
			<span style={{ marginLeft: '0.5em' }}>{label || defaultLabel}</span>
		</Button>
		//* End JSX ------------------------------------------------------------
	);

	//* End of function --------------------------------------------------------
}

export default EditButton2
