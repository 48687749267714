import {
  Avatar,
  Card,
  CardActionArea,
  Grid,

  makeStyles,
  withStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  holder: {
  },
  card: {
    cursor: 'pointer',
    backgroundColor: '#1C1D2105',
    fontWeight: 600,
    minHeight: '2.5em',
    borderRadius: 8,
    padding: theme.spacing(1, 1.25)
  },
  contentExpanded: {
    borderRadius: theme.spacing(1,1,0,0)
  },
  expanded: {
    color: '#4298FF'
  },
  active: {
    backgroundColor: '#5E81F419'
  }
}))

const Icon = withStyles(theme => ({
  root: {
    color: theme.palette.background.default,
    border: '1px solid #eee',
    background: theme.palette.common.white,
    marginRight: '.5em',
    height: '1.7em',
    width: '1.7em',
    fontSize: '1em',
    '&.expanded': {
      color: '#4298FF',
      borderColor: '#4298FF'
    },
    '&.active': {
      '& > *': { opacity: 1 }
    }
  },
  rounded: { borderRadius: 4 },
  circular: {
    '& > *': { opacity: 0 }
  }
}))(Avatar)

function Header(props) {
  const classes = useStyles()

  const getIconProps = () => {
    if (props.loading)
      return {icon: ['fal', 'spinner-third'], spin: true}
    if (props.hasChildren)
      return {icon: ['fal', props.expanded ? 'minus' : 'plus']}
    return {icon: ['fal', 'check']}
  }


  return (<Card elevation={0} className={clsx(
    classes.holder,
    (props.isContentNode && props.expanded) && classes.contentExpanded
  )}>
    <CardActionArea onClick={props.onClick}>
      <Grid container
        className={clsx(
          classes.card,
          props.expanded && classes.expanded,
          props.active && classes.active,
          (props.isContentNode && props.expanded) && classes.contentExpanded
        )}
        wrap='nowrap' alignItems='center'
      >
        <Icon className={clsx({
            ['expanded']: props.expanded,
            ['active']: props.active
          })}
          variant={props.hasChildren ? 'rounded' : 'circular'}
        >
          <FontAwesomeIcon {...getIconProps()} fixedWidth  />
        </Icon>
        {props.children}
      </Grid>
    </CardActionArea>
  </Card>)
}

Header.defaultProps = {
  onClick: e => e
}

export default Header
