const ComplianceKeys = {
    TITLE: 'title',

    CREATE_NEW_PERMIT_TITLE: 'createNewPermit',
    MANAGE_PERMIT_TITLE: 'Manage permit',
    VIEW_PERMIT_TITLE: 'View permit',
    UPDATE_PERMIT_TITLE: 'Update permit',
    BACK_TO_MANAGE_PERMIT_PAGE: 'backToManagePermitPage',
    FILLUP_DETAILS: 'fillupDetails',
    REVIEW_DETAILS: 'reviewDetails',
    DRAFTS: 'drafts',
    SUBMIT: 'submit',
    FINISH: 'finish',
    STATUS: 'status',

    DRAFT_SUCCESS_MSG: 'draftSuccessMsg',
    ENQUIRY_SUCCESS_MSG: 'enquirySuccessMsg',
    UPDATE_PERMIT: 'updatePermit',

    PERMIT_TYPE_TITLE: 'permitTypeTitle',
    DECLARANT_CR_NO: 'declarantCrNo',
    MESSAGE_TYPE: 'messageType',
    DECLARATION_TYPE: 'declarationType',
    DECLARATION: 'declaration',
    PREVIOUS_PERMIT_NO: 'previousPermitNo',
    PERMIT_NO: 'permitNo',
    REMARKS: 'remarks',
    ADDITIONAL_PERMIT_TYPE_DETAILS: 'additionalPermitTypeDetails',

    CARGO_TITLE: 'cargoTitle',
    CARGO_PACKING_TYPE: 'cargoPackingType',
    RELEASE_LOCATION: 'releaseLocation',
    RECEIPT_LOCATION: 'receiptLocation',

    TRANSPORT_TITLE: 'transportTitle',
    INWARD_TRANSPORT: 'inwardTransport',
    OUTWARD_TRANSPORT: 'outwardTransport',
    TRANSPORT_MODE: 'transportMode',
    TRANSPORT_MODE_VOYAGE: 'transportModeVoyage',
    PARTY_TITLE: 'partiesTitle',
    ADD_PARTY: "addParty",
    PARTY_TYPE: "partyType",
    PARTY_NAME: "partyName",
    PARTY_ADDRESS: "partyAddress",
    PARTY_CR_NO: "partyCrNo",
    ACTION: "action",

    LICENSE_TITLE: "licenseTitle",
    LICENSE_NO_1: "licenseNo1",
    LICENSE_NO_2: "licenseNo2",
    LICENSE_NO_3: "licenseNo3",
    LICENSE_NO_4: "licenseNo4",
    LICENSE_NO_5: "licenseNo5",
    ADDITIONAL_LICENSE_NO: "additionalLicenseNo",

    ITEMS_TITLE: "itemsTitle",
    ITEMS_HS_CODE: "itemsHSCode",
    ITEMS_QUANTITY: "itemsQuantity",
    ITEMS_UNIT: "itemsUnit",
    ITEMS_AMOUNT: "itemsAmount",
    SHIPPING_MARKS: "shippingMarks",

    ITEMS_SUMMARY_TITLE: "itemsSummaryTitle",
    ADD_ITEMS: "addItems",
    TOTAL_ITEM_QUANTITY: "totalItemQuantity",
    TOTAL_AMOUNT: "totalAmount",
    TOTAL_OUTER_PACK_QUANTITY: "totalOuterPackQuantity",
    TOTAL_OUTER_PACK_UNIT: "totalOuterPackUnit",
    GROSS_WEIGHT: "grossWeight",
    TOTAL_GROSS_WEIGHT: "totalGrossWeight",
    TOTAL_GROSS_WEIGHT_UNIT: "totalGrossWeightUnit",

    DOCUMENT: 'document',
    DOCUMENTS: 'documents',
    ATTACH_DOCUMENT: 'attachDocument',
    DOCUMENT_INSTRUCTION: 'documentInstruction',
    FILE_TYPE: 'fileType',
    FILE_DESC: 'fileDesc',
    FILE_NAME: 'fileName',
    COST_TYPE: 'costType',
    POL_PLANNED_ETD: 'polPlannedETD',
    POD_PLANNED_ETA: 'podPlannedETA',
    BOL_NO: 'bolNo',
    LAST_MODIFIED_ON: 'lastModifiedOn',
    POD: 'pod',
    POL: 'pol',
    ENQUIRE_STATUS: 'enquireStatus',

    DRAFTED_ON: 'The following permit was drafted on ',
    SUBMITTED_ON: 'The following permit was submitted on ',
    APPROVED_ON: 'The following permit was approved on ',
    REJECTED_ON: 'The following permit was rejected on ',
    AMEND_SUBMITTED_ON: 'The following permit amendment was submitted on ',
    AMEND_CREATED_ON: 'The following permit amendment was created on ',
    DOWNLOAD_PERMIT: 'Download Permit',

    BOOKING_ID: 'Booking ID:',
    PERMIT_DRAFT_ID: 'Permit Draft ID:',
    PERMIT_ID: 'Permit ID:',

    PARTY_ID: "partyId",
    DECLARANT_MAINTENANCE_TITLE: "declarantMaintenanceTitle",
    DECLARANT_MAINTENANCE_PARTY_NAME: "declarantMaintenancePartyName",
    DECLARANT_MAINTENANCE_PARTY_ID: "declarantMaintenancePartyId",
    MAPPED_DECLARANTS: "mappedDeclarants",
    DECLARANT_MAINTENANCE_PARTY_DETAILS: "declarantMaintenancePartyDetails",
    DECLARANT_MAINTENANCE_DECLARANT_ID: "declarantMaintenanceDeclarantId",
    DECLARANT_MAINTENANCE_DECLARANT_CR_NO: "declarantMaintenanceCrNo",
    DECLARANT_MAPPING_FORM: "declarantMappingForm",
    DECLARANT_MAPPING: "declarantMapping",
    DECLARANT_MAPPING_DETAILS: "declarantMappingDetails",
    DECLARANT_MAINTENANCE_DETAILS: "declarantMaintenanceDetails",

    CONFIRM_DELETE_PERMIT_TITLE: 'confirmDeletePermitTitle',
    CONFIRM_CANCEL_PERMIT_TITLE: 'confirmCancelPermitTitle',
    CONFIRM_ARCHIVE_PERMIT_TITLE: 'confirmArchivePermitTitle',
    CONFIRM_DELETE_PERMIT_CONTENT: 'confirmDeletePermitContent',
    CONFIRM_CANCEL_PERMIT_CONTENT: 'confirmCancelPermitContent',
    CONFIRM_ARCHIVE_PERMIT_CONTENT: 'confirmArchivePermitContent',
    CONFIRM_DELETE_PERMIT_OPTION: 'confirmDeletePermitOption',
    CONFIRM_CANCEL_PERMIT_OPTION: 'confirmCancelPermitOption',
    CONFIRM_ARCHIVE_PERMIT_OPTION: 'confirmArchivePermitOption',
    HS_UNIT: "hsUnit",
    HS_QUANTITY: "hsQuantity",
    BRAND_NAME: "brandName",
    MODEL: "model",
    PHONE_NO: "phoneNo",

    PARTY_TYPE_DETAILS : "partyTypeDetails",
    CR_NO_DETAILS : "crNoDetails",
    NAME_DETAILS_1 : "nameDetails1",
    PHONE_NO_DETAILS : "phoneNoDetails",
    ADDRESS_DETAILS_1 : "addressDetails1",
    CITY_DETAILS : "cityDetails",
    PERSON_CODE: "personCode",
    PERSON_NAME: "personName",


    RESET_FILTER_DESC: 'resetFilterDesc',

    INVOICE_TITLE: 'invoiceTitle',
    INVOICE_NO: 'invoiceNo',
    INVOICE_DATE: 'invoiceDate',
    INVOICE_PERSON_CODE: 'invoicePersonCode',
    INVOICE_PERSON_NAME: 'invoicePersonName',
    INVOICE_AMOUNT: 'invoiceAmount',
    INVOICE_CURRENCY: 'invoiceCurrency',
    ADDITIONAL_INVOICE_DETAILS: 'additionalInvoiceDetails',
    TOTAL_INVOICE_TITLE: 'totalInvoiceTitle',
    INVOICE_TERM_TYPE: 'invoiceTermType',
    FREIGHT_CHARGES_TITLE: 'freightChargesTitle',
    FREIGHT_CHARGES_AMOUNT: 'freightChargesAmount',
    FREIGHT_CHARGES_PERCENT: 'freightChargesPercent',
    FREIGHT_CHARGES_CURRENCY: 'freightChargesCurrency',
    INSURANCE_TITLE: 'insuranceTitle',
    INSURANCE_AMOUNT: 'insuranceAmount',
    INSURANCE_PERCENT: 'insurancePercent',
    INSURANCE_CURRENCY: 'insuranceCurrency',
    OTHER_CHARGES_TITLE: 'otherChargesTitle',
    OTHER_CHARGES_AMOUNT: 'otherChargesAmount',
    OTHER_CHARGES_PERCENT: 'otherChargesPercent',
    OTHER_CHARGES_CURRENCY: 'otherChargesCurrency',
    DIALOG_DISCARD_PERMIT_TITLE: 'dialogDiscardPermitTitle',
    DIALOG_DISCARD_PERMIT_CONTENT: 'dialogDiscardPermitContent',
    SHOWN: 'shown'
}

export default ComplianceKeys;