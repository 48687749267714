import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'

import { Columns } from 'react-feather'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem)

export default function ViewColumnComponent(props) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Tooltip title='View Columns' placement='bottom'>
        <IconButton color={'default'} onClick={handleClick}>
          <Columns size='16px' />
        </IconButton>
      </Tooltip>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={clsx(props.toolbarClasses.menu)}
      >
        <MenuItem key={'text'} className={clsx('ng-mattbl-popover-title')}>
          {'View columns'}
        </MenuItem>
        {props.columns.map((item, index) => (
          <MenuItem key={index}>
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  size='small'
                  color="primary"
                  checked={item.show}
                  onChange={props.onChange(index)}
                  className={clsx('ng-mattbl-popover-checkbox')}
                />
              }
              label={
                <Typography variant='body2' gutterBottom>
                  {item.title}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
