const TcalVsVesselKeys = {
  TITLE: 'title',
  MANAGE_VESSELS_TITLE: 'manageVesselsTitle',
  VIEW_AMEND_VESSELS_TITLE: 'viewAmendVesselTitle',
  ADD_VESSELS_TITLE: 'addVesselsTitle',
  VESSEL_INFORMATION: 'vesselInformation',
  ADD_ANOTHER_VESSEL: 'addAnotherVessel',
  DISCARD: 'discard',
  ADDVESSELS: 'addVessels',
  VESSELSTATUS: 'vesselStatus',
  ADDITIONALVESSELDETAILS: 'additionalVesselDetails',
  SEARCHDESC: 'searchDesc',
  SEQNO: 'seqNo',
  ACTION: 'action',
  ITEMSSELECTED: 'itemsSelected',
  USER: 'user',
  LASTMODIFIED: 'lastModified',
  VESSEL_ID: 'vesselId',
  CREATED_BY: 'createdBy',
  CREATED_DATE: 'createdDate',
  UPDATED_BY: 'updatedBy',
  UPDATED_DATE: 'updatedDate',
  VERSION: 'version',
  NAME: 'name',
  DESCRIPTION: 'description',
  PARTY_ID: 'partyId',
  STATUS: 'status',
  IMO_NO: 'imoNo',
  VESSEL_MODE: 'vesselMode',
  BACK_TO_M_VESSEL: 'backToManageVessel',
  AMEND: 'amend',
  W1: 'W1',
  W2: 'W2',
  SAVE_CHANGES: 'saveChanges',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  AMENDVESSELINFO: 'amendVesselInfo',
  DELETERECORDS: 'deleteRecords',
  NOTAKEMEBACK: 'noTakeMeBack',
  YESDELETE: 'yesDelete',
  D1: 'D1',
  D2: 'D2',
  D3: 'D3',
  ITEMS: 'items',
  DELETESUCCESS: 'deleteSuccess',
  ADDSUCCESS: 'addSuccess',
  CHANGESUCCESS: 'changeSuccess'
}

export default TcalVsVesselKeys
