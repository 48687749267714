import { Box, Card, Grid, Typography, Button,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import { withStyles, makeStyles } from '@material-ui/core/styles';

const {
    CngGridItem
  } = components

  const StyledTableCell = withStyles((theme) => ({
    head: {
      //backgroundColor: theme.palette.common.black,
      //color: theme.palette.common.white,
      fontSize: 16,
      fontWeight: "bolder"
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const styleNovalue = {color: '#8996AFCC'}
  const styleOld = {color: '#FF808B'}
  const styleNew = {color: '#7CE7AC'}

const AuditTrailRowOfChangeComplex = (props) => {
    const {
        label,
        group,
        subGroup,
        object,
        fields,
        added,
        removed,
        delimiter=' '
    } = props

    let oldValue
    let newValue

    if(object===null || object === undefined) return <div/>

    let value = []
    for(let i=0; i< fields.length; i++) {
        value.push(object[fields[i]])        
    }

    if(added) {
        newValue = value.join(delimiter)
    } else if (removed) {
        oldValue = value.join(delimiter)
    } else {
        const values = Utils.getAuditTrailDiffs(object, fields)
        if(Utils.noChanges(values)) {
            return <div/>
        }
        oldValue = values.valueOld.join(delimiter)
        newValue = values.valueNew.join(delimiter)
    }
    if(Utils.objectsEqual(newValue , oldValue)) return <div/>

    
    
    return ( 
        <StyledTableRow>
            <StyledTableCell>                
                {
                   group && (
                       <>
                        <b>{group}</b>
                        <span class='blackDot'></span>
                        {label}:	&nbsp; 
                       </>
                   )
                } 
                
                {
                   !group && (
                       <>
                        <b>{label}</b>:	&nbsp;                         
                       </>
                   )
                } 
                
                {   oldValue && 
                    <span style={styleOld}>{oldValue}</span> 
                }
                {
                    oldValue && newValue && ' > '
                }
                {
                    newValue &&
                    <span style={styleNew}>{newValue}</span>
                }
            
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default AuditTrailRowOfChangeComplex
