import React from 'react'


function InputComponent({ label, error = false, required, innerRef, className = '', afterIcon = null, beforeIcon = null, ...rest }) {
    return (<div className={`input-component`}>
        {afterIcon && <div className="after-icon">{afterIcon}</div>}
        <input ref={innerRef} className={`${className}  ${error ? 'error' : ''}`} {...rest} placeholder={' '} />
        {beforeIcon && <div className="before-icon">{beforeIcon}</div>}
        <label>{label}{required && <span className="text-error">*</span>}</label>
    </div>)
}
export default React.forwardRef((props, ref) => <InputComponent innerRef={ref} {...props} />)