const TcalVsManageCarriersKeys = {
  PREFERRED_CARRIERS_TITLE: 'preferredCarriersTitle',
  BLACKLISTED_CARRIERS_TITLE: 'blacklistedCarriersTitle',
  PREFERRED_PORT_PAIRS_TITLE: 'preferPortPairsTitle',
  CARRIERS_NOVCC_PARTY: 'carrierNovccParty',
  STARTING_DATE: 'startingDate',
  ENDING_DATE: 'endingDate',
  ADD_CARRIER: 'addCarrier',
  CONTRACTED: 'contracted',
  REMOVE: 'remove',
  SET_PREFERRED_TITLE: 'setPreferredTitle',
  ADD_PREFERRED_CARRIER_SUCCESS: 'addPreferredCarrierSuccess',
  EDIT_PREFERRED_CARRIER_SUCCESS: 'editPreferredCarrierSuccess',
  ADD: 'add',
  DOWNLOAD: 'download',
  APPLY: 'apply',
  EXPORT_TABLE: 'exportTable',
  CARRIER_IS_REQUIRED: 'carrierIsRequired',
  SET_BLACKLISTED_TITLE: 'setBlacklistedTitle',
  ADD_BLACKLISTED_SUCCESS: 'addBlacklistedSuccess',
  PORT_OF_LOADING: 'portOfLoading',
  PORT_OF_DISCHARGE: 'portOfDischarge',
  ADD_PREFERRED_PORT_PAIR_SUCCESS: 'addPreferredPortPairSuccess',
  EDIT_PREFERRED_PORT_PAIR_SUCCESS: 'editPreferredPortPairSuccess',
  DELETE_PREFERRED_PORT_PAIR_SUCCESS: 'deletePreferredPortPairSuccess',
  ADD_PREFERRED_CARRIER: 'addPreferredCarrier',
  EXPORT_PREFERRED_CARRIER: 'exportPreferredCarrier',
  EDIT_PREFERRED_CARRIER: 'editPreferredCarrier',
  ADD_PREFERRED_PORT_PAIR: 'addPreferredPortPair',
  EDIT_PREFERRED_PORT_PAIR: 'editPreferredPortPair',
  ADD_BLACKLISTED_CARRIER: 'addBlacklistedCarrier',
  SELECT_CARRIER: 'selectCarrier',
  DEFINE_PORT_PAIRS: 'definePortPairs',
  SET_PREF_CARRIER_OR_ADD: 'setPrefCarrierOrAdd',
  EDIT_PREF_CARRIER_OR_ADD: 'editPrefCarrierOrAdd',
  SET_PORT_PAIR_TITLE: 'setPortPairTitle',
  ORIGIN: 'origin',
  DESTINATION: 'destination',
  CONTINUE: 'continue',
  ADD_NEW_CARRIER: 'addNewCarrier',
  ADD_NEW: 'addNew',
  PORT_OF_LOADING_IS_REQ: 'portOfLoadingIsReq',
  PORT_OF_DISCHARGE_IS_REQ: 'portOfDischargeIsReq',
  FILE_FORMAT: 'fileFormat',
  PORT_PAIR_EXIST: 'portPairExist'
}

export default TcalVsManageCarriersKeys
