const TtfbTprPartyKeys = {
  TITLE: "title",
  PARTY_ID: "partyId",
  PARTY_NAME: "partyName",
  TcalAsUserGroupCompany: {
    TITLE: "tcalAsUserGroupCompany.title",
    USER_GROUP_COMPANY_ID: "tcalAsUserGroupCompany.userGroupCompanyId",
    USER_GROUP_MASTER_ID: "tcalAsUserGroupCompany.userGroupMasterId",
    CREATED_BY: "tcalAsUserGroupCompany.createdBy",
    CREATED_DATE: "tcalAsUserGroupCompany.createdDate",
    UPDATED_BY: "tcalAsUserGroupCompany.updatedBy",
    UPDATED_DATE: "tcalAsUserGroupCompany.updatedDate"
  }
}

export default TtfbTprPartyKeys
