const CompanyPreferenceKeys = {
  TITLE: 'title',
  CLOSE_BOOKING_UPDATE: 'closeBookingUpdate',
  CLOSE_SHIPPING_INFO_UPDATE: 'closeShippingInfoUpdate',
  COMPANY_PREFERENCE_TITLE: 'companyPreferenceTitle',
  CONTACT_DETAILS: 'contactDetails',
  ADDRESS_BOOK: 'addressBook',
  COMPANY_CONTACT: 'companyContact',
  BULK_UPLOAD_CONTACT: 'bulkUploadContact',
  PARTY_TEMPLATE: 'partyTemplate',
  VIEW_NAMES_BY: 'viewNamesBy',
  SEQUENCE_NO: 'sequenceNo',
  CATEGORY: 'category',
  NAME: 'name',
  EMAIL_ID: 'emailId',
  CONTACT_NO: 'contactNo',
  COUNTRY: 'country',
  CITY: 'city',
  STATE: 'state',
  POSTAL_CODE: 'postalCode',
  ADDRESS: 'address',

  ADD_NEW_CONSIGNEE: 'addNewConsignee',
  ADD_NEW_SHIPPER: 'addNewShipper',
  ADD_NEW_NOTIFY_PARTY: 'addNewNotifyParty',
  ADD_NEW_REPRESENTATIVE: 'addNewRepresentative',
  ADD_NEW_AGENT: 'addNewAgent',

  CONNECTED_PARTY_TEMPLATES: 'connectedPartyTemplates',
  CONNECTED_PARTY_TEMPLATES_NOTE: 'connectedPartyTemplatesNote',

  TEMPLATE_NAME: 'templateName',
  SAVE_CONTACT: 'saveContact',
  SAVE_TEMPLATE: 'saveTemplate',
  SAVE_CHANGES: 'saveChanges',

  EXPORT_CONTACTS_EXCEL: 'exportContactsExcel',
  INSERT_AND_POPULATE: 'insertAndPopulate',
  BACK_TO_SUMMARY: 'backToSummary',

  FILTER_CLEAR_ALL: 'filterClearAll',
  FILTER_RESET_FILTERS: 'filterResetFilters',

  TABLE_CREATION_DATE: 'tableTitleCreationDate',
  TABLE_TITLE_ACTION: 'tableTitleAction',
  TABLE_DETAILS_NO: 'tableDetailsNo',
  TABLE_CONTACT_NAME: 'tableContactName',
  TABLE_CONTACT_ADDRESS: 'tableContactAddress',

  ADD_NEW_CONTACT: 'addNewContact',
  VIEW_CONTACT: 'viewContact',
  MANAGE_CONTACT: 'manageContact',
  UPLOAD_FILE_TEMPLATE: 'uploadFileTemplate',
  ADD_NEW_TEMPLATE: 'addNewTemplate',
  MANAGE_TEMPLATE: 'manageTemplate',
  VIEW_TEMPLATE: 'viewTemplate',
  UPLOAD_AND_VALIDATE: 'uploadAndValidate',
  SAVE_FILE: 'saveFile',

  NO_CONTACTS_TITLE: 'noContactsTitle',
  NO_CONTACTS_DESC: 'noContactsDesc',
  NO_BULK_UPLOAD_TITLE: 'noBulkUploadTitle',
  NO_BULK_UPLOAD_DESC: 'noBulkUploadDesc',
  NO_PARTY_TEMPLATE_TITLE: 'noPartyTemplateTitle',
  NO_PARTY_TEMPLATE_DESC: 'noPartyTemplateDesc',
  NO_RESULTS_FILTER_TITLE: 'noResultsFilterTitle',
  NO_RESULTS_FILTER_DESC: 'noResultsFilterDesc',

  OPERATION_CANNOT_BE_UNDONE: 'operationCannotBeUndone',
  DELETE_CONTACT_TITLE: 'deleteContactTitle',
  DELETE_CONTACT_MSG: 'deleteContactMsg',
  DELETE_BULK_UPLOAD_TITLE: 'deleteBulkUploadTitle',
  DELETE_BULK_UPLOAD_MSG: 'deleteBulkUploadMsg',
  DELETE_PARTY_TEMPLATE_TITLE: 'deletePartyTemplateTitle',
  DELETE_PARTY_TEMPLATE_MSG: 'deletePartyTemplateMsg',
  DISCARD_BULK_UPLOAD_TITLE: 'discardBulkUploadTitle',
  DISCARD_BULK_UPLOAD_MSG: 'discardBulkUploadMsg',

  DATE_FILTER_TITLE: 'dateFilterTitle',
  DATE_FILTER_ALL: 'dateFilterAll',
  DATE_FILTER_DAY_15: 'dateFilterDay15',
  DATE_FILTER_DAY_30: 'dateFilterDay30',
  DATE_FILTER_DAY_60: 'dateFilterDay60',

  NOTIFY_CONTACT_SAVED: 'notifyContactSaved',
  NOTIFY_CONTACT_DELETED: 'notifyContactDeleted',
  NOTIFY_BULK_UPLOAD_DELETED: 'notifyBulkUploadDeleted',
  NOTIFY_BULK_UPLOAD_FILE_ERROR: 'notifyBulkUploadNoFileError',
  NOTIFY_BULK_UPLOAD_FILE_UPLOADED: 'notifyBulkUploadFileUploaded',
  NOTIFY_BULK_UPLOAD_FILE_SAVED: 'notifyBulkUploadFileSaved',
  NOTIFY_PARTY_TEMPLATE_SAVED: 'notifyPartyTemplateSaved',
  NOTIFY_PARTY_TEMPLATE_DELETED: 'notifyPartyTemplateDeleted',

  ERROR_DELETE_CONTACT: 'errorDeleteContact',
  ERROR_DELETE_RECORD: 'errorDeleteRecord',
  ERROR_DELETE_FILE: 'errorDeleteFile',
  ERROR_UPLOAD_FILE: 'errorUploadingFile',
  ERROR_DOWNLOAD_FILE: 'errorDownloadingFile',
  ERROR_DOWNLOAD_ERROR_LOG: 'errorDownloadingErrorLog',
  ERROR_SAVING_TEMPLATE: 'errorSavingTemplate',
  ERROR_GET_DROPDOWN_LIST: 'errorGetDropdownList',

  VALIDATION_INVALID_EMAIL: 'invalidEmailMsg',
  VALIDATION_INVALID_PHONE: 'invalidPhoneMsg',
  VALIDATION_INVALID_ADDRESS_MAX_LENGTH: 'invalidAddressMaxLengthMsg',
  VALIDATION_INVALID_POSTAL_CODE: 'invalidPostalCode',

  SI_SETTING: 'siSetting',
  BOL_PREFERENCE: 'bolPreference',
  ISSUE_PLACE: 'issuePlace',
  DEFAULT_SIGN_STAMP: 'defaultSignStamp',
  SELECT_SIGN: 'selectSign',
  OTHER_PREFERENCE: 'otherPreference',
  AUTO_POPULATE_SHP_DATE: 'autoPopulateShpDate',
  SKIP_MANAGER_APPROVAL: 'skipManagerApproval',
  SKIP_MANAGER_APPROVAL_WARN: 'skipManagerApprovalWarn',
  ALLOW_SI_EMPTY_CONT: 'allowSiEmptyCont',
  UPDATE_SUBMIT_SUCCESS_MSG: 'updateSubmitSuccessMsg',

  SMTH_WRONG: 'smthWrong',
  SMTH_WRONG_DESC: 'smthWrongDesc',
  REFRESH: 'refresh',
  VALIDATION_NAME_EXCEED_MAX_LENGTH: 'nameExceedMaxLengthMsg',
  BOOKING_PREF_TITLE: 'bookingCompanyPreference',
  SI_PREF_TITLE: 'siCompanyPreference',
  FRB_COM_PREF_TITLE: 'frbPrefTitle',
  SI_COM_PREF_TITLE: 'siPrefTitle'
}
export default CompanyPreferenceKeys
