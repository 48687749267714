const BatchSftpProcessKeys = {
  TITLE: "title",
  ID: "id",
  PROCESS_NAME: "processName",
  PROCESS_NODE: "processNode",
  NEXT_TRIGGER_DATE: "nextTriggerDate",
  START_PROCESS_DATE: "startProcessDate",
  END_PROCESS_DATE: "endProcessDate"
}

export default BatchSftpProcessKeys
