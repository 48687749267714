import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
    Box,
    Chip,
    Step,
    StepConnector,
    StepLabel,
    Stepper,
    Tooltip
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'
import {
    faAngleDoubleRight,
    faPlane,
    faShip,
    faSubway,
    faTruck,
    faTruckContainer,
    faTruckLoading
} from "@fortawesome/pro-light-svg-icons";
import { makeStyles, withStyles } from '@material-ui/core/styles'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CommuteIcon from '@material-ui/icons/Commute';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DirectionsRailwayIcon from '@material-ui/icons/DirectionsRailway';
import FlightIcon from '@material-ui/icons/Flight';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import clsx from 'clsx'
import useFetchCodeMaintainance from 'src/views/common/serviceHook/useFetchCodeMaintainance'

const {
    CodeMaintenanceType
} = constants

const PortTransportModeStepper = (props) => {

    const QontoConnector = withStyles({
        alternativeLabel: {
          top: 10,
        //   left: 'calc(-50% + 30px)',
        //   right: 'calc(50% + 30px)'
        },
        active: {
          '& $line': {
            borderColor: '#eaeaf0'
          }
        },
        completed: {
          '& $line': {
            borderColor: '#eaeaf05'
          }
        },
        line: {
          borderColor: '#eaeaf0',
          borderTopWidth: 3,
          borderRadius: 1
        }
      })(StepConnector)

    const classes = makeStyles({
        root: {
          color: '#eaeaf0',
          display: 'flex',
          height: 22,
          alignItems: 'center'
        },
        active: {
          color: '#784af4'
        },
        circle: {
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'currentColor'
        },
        completed: {
          color: '#784af4',
          zIndex: 1,
          fontSize: 18
        }
      })

    const { fetchRecords } = useServices()
    // const [port, setPort] = useState([])
    const [country, setCountry] = useState([])
    const [shouldRender, setShouldRender] = useState(false)

    // useEffect(() => {       
    // },[props])

    const TransportIcon = (transportMode) => {
        if(transportMode == CalistaConstant.TRANSPORT_MODE_SEA){
            return (
                <Tooltip title={transportMode} placement='bottom'>
                    {/* <DirectionsBoatIcon /> */}
                    <FontAwesomeIcon icon={faShip} size='lg' className='icon-circle-border'/>
                </Tooltip>
            )
        }else if(transportMode == CalistaConstant.TRANSPORT_MODE_AIR){
            return (
                <Tooltip title={transportMode} placement='bottom'>
                    {/* <FlightIcon /> */}
                    <FontAwesomeIcon icon={faPlane} size='lg' className='icon-circle-border'/>
                </Tooltip>
            )
        }else if(transportMode == CalistaConstant.TRANSPORT_MODE_RAIL){
            return (
                <Tooltip title={transportMode} placement='bottom'>
                    {/* <DirectionsRailwayIcon /> */}
                    <FontAwesomeIcon icon={faSubway} size='lg' className='icon-circle-border'/>
                </Tooltip>
            )
        }else if(transportMode == CalistaConstant.TRANSPORT_MODE_TRUCK){
            return (
                <Tooltip title={transportMode} placement='bottom'>
                    {/* <LocalShippingIcon /> */}
                    <FontAwesomeIcon icon={faTruck} size='lg' className='icon-circle-border'/>
                </Tooltip>
            )
        }else if(transportMode == CalistaConstant.TRANSPORT_MODE_FEEDER){
            return (
                <Tooltip title={transportMode} placement='bottom'>
                    {/* <LocalShippingIcon /> */}
                    <FontAwesomeIcon icon={faTruckLoading} size='lg' className='icon-circle-border'/>
                </Tooltip>
            )
        }else if(transportMode == CalistaConstant.TRANSPORT_MODE_LAND){
            return (
                <Tooltip title={transportMode} placement='bottom'>
                    {/* <LocalShippingIcon /> */}
                    <FontAwesomeIcon icon={faTruckContainer} size='lg' className='icon-circle-border'/>
                </Tooltip>
            )
        }else{
            return (
                <Tooltip title={transportMode} placement='bottom'>
                    <FontAwesomeIcon icon={faAngleDoubleRight}/>
                </Tooltip>
            )
        }
    }

    const TransportMode = (transportMode) => (props) =>{
        const { active, completed } = props
        return (
            <div >
                {TransportIcon(transportMode)}
            </div>
        )
    }

    
    let className = props.bgClassName ? 'no-padding '.concat(props.bgClassName) : 'no-padding '

    return (

        <div>
            <Box display='flex' justifyContent='flex-start' alignItems="center">
                  <Box>
                    <Chip label={<b>{props.fromPortCode}</b>} size='small' />
                  </Box>
                  <span className='center-line-transport'></span>
                  <Box>
                    {TransportMode(props.transportMode)}
                  </Box>
                  <span className='center-line-transport'></span>
                  <Box>
                    <Chip label={<b>{props.toPortCode}</b>} size='small' />
                  </Box>
            </Box>

        </div>
    )
}

export default PortTransportModeStepper