const EvgmKeys = {
    TITLE: 'title',
    EVGM: 'evgm',
    MY_EVGM_TITLE: 'myEvgmTitle',
    EVGM_ID: 'evgmId',
    EVGM_STATUS: 'evgmStatus',
    CARRIER: 'carrier',
    CONTAINER_NO: 'containerNo',
    CONTAINER_TYPE: 'containerType',
    VERIFIED_GROSS_MASS: 'verifiedGrossMass',
    FREIGHT_FORWARDER: 'freightForwarder',
    CONSIGNOR: 'consignor',
    BOOKING_NUMBER: 'bookingNumber',
    SHIPPER_IDENTIFYING_NUMBER: 'shipperIdentifyingNumber',
    DOC_ID: 'docId',
    EVGM_CONTAINER_ID: 'evgmContainerId',
    LAST_MODIFIED_ON: 'lastModifiedOn',

    //Create Evgm
    CREATE_EVGM: 'createEvgm',
    FILLUP_DETAILS: 'fillupDetails',
    REVIEW_DETAILS: 'reviewDetails',
    DRAFTS: 'drafts',
    SUBMIT: 'submit',
    FINISH: 'finish',

    //Invoice
    INVOICE_INFORMATION_TITLE: 'invoiceInformationTitle',
    REF_ID_TYPE: 'refIdType',
    REF_ID_VALUE: 'refIdValue',
    ADD_REF_ID: 'addRefId',

    //Common
    PARTY_ID_TYPE: 'partyIdType',
    AGENCY_CODE: 'agencyCode',
    CONTACT_NAME: 'contactName',
    CONTACT_ADDRESS: 'contactAddress',
    PHONE_NUMBER: 'phoneNumber',
    EMAIL_ADDRESS: 'emailAddress',

    //Carrier
    CARRIER_INFO_TITLE: 'carrierInfoTitle',
    CARRIER_PARTY_ID: 'carrierPartyId',
    CARRIER_CONTACT_TYPE: 'carrierContactType',
    CARRIER_ADDITIONAL: 'carrierAdditional',

    //Shipper
    SHIPPER_INFO_TITLE: 'shipperInfoTitle',
    SHIPPER_PARTY_ID: 'shipperPartyId',
    SHIPPER_CONTACT_TYPE: 'shipperContactType',
    SHIPPER_ADDITIONAL: 'shipperAdditional',

    //Consignor
    CONSIGNOR_INFO_TITLE: 'consignorInfoTitle',
    CONSIGNOR_PARTY_ID: 'consignorPartyId',
    CONSIGNOR_CONTACT_TYPE: 'consignorContactType',
    CONSIGNOR_ADDITIONAL: 'consignorAdditional',

    //My Evgm
    MY_EVGM: 'myEvgm',
    EVGM_FILTER_DATE: 'date',
    LAST_MODIFIED_DATE: 'lastModifiedDate',
    EVGM_FILTER_ALL: 'All',
    EVGM_FILTER_DATE_15: 'Past15Days',
    EVGM_FILTER_DATE_30: 'Past30Days',
    EVGM_FILTER_DATE_60: 'Past60Days',
    SHOWN: 'shown',

    //Evgm Status Header
    EVGM_NEW_PENDING: 'evgmNewPending',
    EVGM_SUBMITTED: 'evgmSubmitted',
    EVGM_UPDATE_PENDING: 'evgmUpdatePending',
    EVGM_CANCELLED_PENDING: 'evgmCancelledPending',
    EVGM_CANCELLED_SUBMITTED: 'evgmCancelledSubmitted',
    EVGM_ACCEPTED: 'evgmAccepted',
    EVGM_REJECTED: 'evgmRejected',

    NOTHING_HERE_YET: 'nothingHereYet',
    NO_ITEM_HERE_YET: 'noItemHereYet',
    NO_ITEM_DESC_SHIPPER: 'noItemDescShipper',
    NO_ITEM_DESC_CARRIER: 'noItemDescCarrier',
    SMTH_WRONG: 'smthWrong',
    SMTH_WRONG_DESC: 'smthWrongDesc',
    REFRESH: 'refresh',

    //Container
    CONTAINER_DETAILS_TITLE: 'containerDetailsTitle',
    CONTAINER_NUMBER: 'containerNumber',
    CONTAINER_SIZE_TYPE_HEIGHT: 'containerSizeTypeHeight',
    CONTAINER_STATUS: 'containerStatus',

    //VGN
    VGM: 'vgm',
    VGM_UOM: 'vgmUom',
    VGM_DATE: 'vgmDate',

    //Container ref number
    CTN_REF_NO_TITLE: 'ctnRefNoTitle',
    CTN_REF_NO_TYPE: 'ctnRefNoType',
    CTN_REF_NO_NUMBER: 'ctnRefNoNumber',
    CTN_REF_NO_ADD_ROW: 'ctnRefNoAddRow',

    //Container seals info
    CTN_SEALS_INFO_TITLE: 'ctnSealsInfoTitle',
    CTN_SEALS_INFO_CODE: 'ctnSealsInfoCode',
    CTN_SEALS_INFO_NUMBER: 'ctnSealsInfoNumber',
    CTN_SEALS_ADD_ROW: 'ctnSealsAddRow',

    //Container location
    CTN_LOCATION_TITLE: 'ctnLocationTitle',
    CTN_LOCATION_TYPE: 'ctnLocationType',
    CTN_LOCATION_NUMBER: 'ctnLocationNumber',
    CTN_LOCATION_NAME: 'ctnLocationName',
    CTN_LOCATION_ADD_ROW: 'ctnLocationAddRow',

    //Container document
    CTN_DOC_TITLE: 'ctnDocTitle',
    CTN_DOC_CODE: 'ctnDocCode',
    CTN_DOC_ID: 'ctnDocId',
    CTN_DOC_DATE: 'ctnDocDate',

    CTN_DOC_INFO_TITLE: 'ctnDocInfoTitle',
    CTN_DOC_TYPE: 'ctnDocType',
    CTN_DOC_PARTY_NAME: 'ctnDocPartyName',
    CTN_DOC_PARTY_ADDRESS: 'ctnDocPartyAddress',
    CTN_DOC_CONTACT_TYPE: 'ctnDocContactType',
    CTN_DOC_CONTACT_NAME: 'ctnDocContactName',

    CTN_DOC_COMM_TYPE: 'ctnDocCommType',
    CTN_DOC_COMM_VALUE: 'ctnDocCommValue',

    DISCARD_EVGM_CONTENT: 'dialogDiscardEvgmContent',
    DISCARD_EVGM_TITLE: 'dialogDiscardEvgmTitle',

    DRAFT_SUCCESS_MSG: 'draftSuccessMsg',
    BACK_TO_MY_EVGM: 'backToMyEvgm',
    DISCARD: 'discard',
    BACK_TO_EDIT: 'backToEdit',
    SUBMIT_EVGM: 'submitEvgm'

}

export default EvgmKeys