import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import React from 'react'
import { components } from 'cng-web-lib'
import { Button } from '@material-ui/core'

const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function IssueButton(buttonProps) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <Button
        ariant='contained'
        className='button-light-green originalText'
        {...buttonProps}
      >
        <CheckCircleOutlineRoundedIcon />
        {buttonProps.otherText != null
          ? buttonProps.otherText
          : translatedTextsObject.approve}
      </Button>
    </div>
  )
}

export default IssueButton
