import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const {
  button: { CngSecondaryButton }
} = components
const useStyles = makeStyles((theme) => ({

  secondStyle: {
    backgroundColor: "#5e81f419",
    borderRadius: "8px",
    borderColor: "#5e81f419",
    boxShadow: "0 0 1px 0 rgba(94, 129, 244, 0.1), 0 2px 2px -2px rgba(94, 129, 244, 0.25)",
    color: "#FF808B",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434',
    },
    '&:focus': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434',
    },
    '&:active': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434',
    },
    '&:linkButton': {
      padding: "6px 13px",

      '&:hover': {
        textDecoration: "none"
      }
    }
  }
}
))
function CancelButtonWithIcon(buttonProps) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  const classes = useStyles()

  return <Button
    startIcon={<NotInterestedIcon />}
    variant='contained'
    // classes={{ root: 'ng-button-filled-secondary' }}
    className={classes.secondStyle}
    {...buttonProps}
  >{translatedTextsObject.cancel}
  </Button>
  //return <CngSecondaryButton startIcon={<NotInterestedIcon />} {...buttonProps}>{translatedTextsObject.cancel}</CngSecondaryButton>
}


export default CancelButtonWithIcon
