import {
    Box,
    Card,
    Grid,
    Badge,
    Button
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'

import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import useFetchContainerType from 'src/views/common/serviceHook/useFetchContainerType'
import Utils from 'src/views/common/utils/Utils'
import { withStyles } from "@material-ui/core/styles";
import LabelValueVertical from '../../views/freightbooking/components/LabelValueVertical'
import ViewReeferDetailsDialog from '../../views/freightbooking/components/ViewReeferDetailsDialog'
const {
    CngGridItem
} = components


const CustomizedBadge = withStyles(() => ({
    badge: {
        backgroundColor: "white",
        color: "#1C1D21CC",
        borderStyle: "solid",
        borderColor: "#1C1D21CC",
        borderWidth: "thin",

    }

}))(Badge);


const ContainerItemViewField = (props) => {
    const { auditBooking, id } = props
    const { fetchRecords } = useServices()
    const translatedTextsObject = FreightBookingTranslationText();

    const [containerTypeMatched, setContainerTypeMatched] = useState({})
    const [shouldRender, setShouldRender] = useState(false)

    const initialHighLight = {
        contQty: {},
        contIsoType: {},
        contHeightDesc: {},
        contTypeDesc: {},
        contSize: {},
        contStatus: {},
        container: {}
    }

    const [highlight, setHighlight] = useState(initialHighLight)
    const [containerTypes, setContainerTypes] = useState([])

    function onContainerTypeLoadSuccess(data) {
        setContainerTypes(data)
        const matched = data.find((container) => container?.isoCode === props.containerIsoType)
        setContainerTypeMatched(matched)
    }

    useEffect(() => {
        SharedServiceApi.fetchContainerType(fetchRecords, false, onContainerTypeLoadSuccess)
    }, [props])

    useEffect(() => {
        if (auditBooking && auditBooking.containers) {
            let hl = Utils.auditHighLightContainer(auditBooking.containers, id, containerTypes)
            setHighlight(hl)
        }
    }, [props, containerTypes])

    const [
        isReeferDetailsDialogOpen,
        setReeferDetailsDialogOpen
    ] = useState(false)
    const [currentReeferDetails, setCurrentReeferDetails] = useState()

    function viewReeferDetails(containerItem) {
        setReeferDetailsDialogOpen(true)
        setCurrentReeferDetails(containerItem)
    }

    function closeDialog() {
        setReeferDetailsDialogOpen(false)
    }


    return (
        <Card>
            <Grid container xs={12} sm={12} className="view-card view-card-content" style={highlight.container}>
                <Grid container xs={2} sm={2}
                    className="view-card-item-content" style={{ backgroundColor: "#f4f4fc" }}>
                    <Box className="view-card-content-label">
                        {translatedTextsObject.containerSizeType}
                    </Box>
                    <CustomizedBadge
                        badgeContent={props.containerSOC ? "SOC" : null}
                        style={{ width: "100%" }}>

                        <Box className="view-card-content-value">
                            <span style={highlight.contQty}>{props.containerQty}</span> x <span className='bold-large-text' style={highlight.contIsoType}>{props.containerIsoType}</span>
                        </Box>
                    </CustomizedBadge>
                </Grid>
                <Grid container xs={10} sm={10} className="view-card-item-content inner-card-background">

                    <CngGridItem xs={1} sm={1}>

                    </CngGridItem>
                    <CngGridItem xs={2} sm={2} style={highlight.contSize}>
                        <Box className="view-card-content-label">
                            {translatedTextsObject.size}
                        </Box>
                        <Box className="view-card-content-value">
                            {containerTypeMatched != null ? containerTypeMatched.contSize : null}
                        </Box>
                    </CngGridItem>
                    <CngGridItem xs={3} sm={3} style={highlight.contTypeDesc}>
                        <Box className="view-card-content-label">
                            {translatedTextsObject.type}
                        </Box>
                        <Box className="view-card-content-value">
                            {containerTypeMatched != null ? containerTypeMatched.contTypeDesc : null}
                        </Box>
                    </CngGridItem>
                    <CngGridItem xs={3} sm={3} style={highlight.contHeightDesc}>
                        <Box className="view-card-content-label">
                            {translatedTextsObject.heightType}
                        </Box>
                        <Box className="view-card-content-value">
                            {containerTypeMatched != null ? containerTypeMatched.contHeightDesc : null}
                        </Box>
                    </CngGridItem>
                    <CngGridItem xs={3} sm={3} justify="end" style={highlight.contStatus}>
                        <Box className="view-card-content-label">
                            {translatedTextsObject.status}
                        </Box>
                        <Box className="view-card-content-value">
                            <CodeMasterViewField
                                codeType='CAL_CONT_STATUS'
                                code={props.containerStatus}
                            />
                        </Box>
                    </CngGridItem>
                </Grid>
                {props.currentReeferDetails.reeferAvailable &&
                    <Grid container xs={12} sm={12} className="view-card-item-content inner-card-background">
                        <CngGridItem xs={4} sm={4} /*style={highlight.contStatus}*/>
                            {!props.currentReeferDetails.nonActiveReefer ?
                                (
                                    <Grid container xs={12} sm={12} className="view-card-item-content inner-card-background">
                                        <CngGridItem /*style={highlight.contStatus}*/>
                                            <LabelValueVertical label={translatedTextsObject.nonActiveReefer}
                                                value='No' />
                                        </CngGridItem>
                                        <CngGridItem  /*style={highlight.contStatus}*/>
                                            <Box display='flex' flexDirection={'row-reverse'} mt={2}>
                                                <Button variant='contained' className="button-blue originalText"
                                                    onClick={() => { viewReeferDetails(props.currentReeferDetails) }}
                                                >
                                                    View Reefer Details
                                                </Button>
                                            </Box>
                                        </CngGridItem>
                                    </Grid>

                                )
                                :
                                <Grid container xs={12} sm={12} className="view-card-item-content inner-card-background">
                                    <CngGridItem xs={6} sm={6} /*style={highlight.contStatus}*/>
                                        <LabelValueVertical label={translatedTextsObject.nonActiveReefer}
                                            value='Yes' />
                                    </CngGridItem>
                                </Grid>
                            }
                        </CngGridItem>

                        <ViewReeferDetailsDialog
                            isDialogOpen={isReeferDetailsDialogOpen}
                            closeDialog={closeDialog}
                            currentReeferDetails={currentReeferDetails}
                            containerType={props.containerIsoType}
                        />
                    </Grid>
                }
            </Grid>
        </Card>
    )
}

export default ContainerItemViewField