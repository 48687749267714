const SCOInvMgmt = {
    // General
    MODULE_TITLE: "moduleTitle",
    SUPPLIER_NAME: "supplierName",
    BUYER_NAME: "buyerName",
    SUPPLIER: "supplier",
    BUYER: "buyer",
    PARTY_NAME: "partyName",
    SUPPLIER_DETAILS: "supplierDetails",
    SUPPLIER_ADDRESS: "supplierAddress",
    ADDRESS_COUNTRY: "addressCountry",
    ADDRESS_STATE: "addressState",
    ADDRESS_STREET: "addressStreet",
    ADDRESS_CITY: "addressCity",
    ADDRESS_POST_CODE: "addressPostCode",
    EXCEED_CHAR_LIMIT_MSG: "exceedCharLimitMsg",
    BUYER_DETAILS: "buyerDetails",
    BUYER_ADDRESS: "buyerAddress",
    SKU_CATEGORY: "skuCategory",
    SKU_CAT_NAME_COL: "skuCatNameCol",
    SKU_CAT_DETAILS: "skuCatDetails",
    CATEGORY_NAME: "categoryName",
    CATEGORY_ATTRIBUTES: "categoryAttributes",
    ATTRIBUTE_NAME: "attributeName",
    DATA_TYPE: "dataType",
    ADD_ATTRIBUTE: "addAttribute",
    DUPLICATE_ATTR_NAME_MSG: "duplicateAttrNameMsg",
    NO_ATTR_MSG: "noAttrMsg",
    WAREHOUSE_TITLE: "warehouseTitle",
    NON_STORAGE_TITLE: "nonStorageTitle",
    WAREHOUSE_NAME: "warehouseName",
    WAREHOUSE_TAGS: "warehouseTags",
    WAREHOUSE_DETAILS_TITLE: "warehouseDetailsTitle",
    WAREHOUSE_ADDRESS_TITLE: "warehouseAddressTitle",
    WAREHOUSE_STORAGE_LOC_TITLE: "warehouseStorageLocationTitle",
    LOCATION_NAME: "locationName",
    PARENT_LOCATION: "parentLocation",
    LOCATION_TAGS: "locationTags",
    LOCATION_CODE: "locationCode",
    STORAGE_LOCATION_DETAIL: "storageLocationDetails",
    NON_STORAGE_LOCATION: "nonStorageLocation",
    LOCATION_DETAILS: "locationDetails",
    LOCATION_REF_ID: "locationRefId",
    SKU: "sku",
    SKU_NUMBER: "skuNumber",
    SKU_DESC: "skuDesc",
    SKU_DETAILS: "skuDetails",
    SUPPLIER_SKU_NUM: "supplierSku",
    UOM: "uom",
    BASE_UOM: "baseUom",
    QUANTITY: "quantity",
    ALT_UOM: "alternateUom",
    ADD_ALT_UOM: "addAlternateUom",
    QUANTITY_TO_BASE_UOM: "quantityToBaseUom",
    DUPLICATE_ALT_UOM_MSG: "duplicateAltUomMsg",
    ATTRIBUTES: "attributes",
    ATTRIBUTE: "attribute",
    VALUE: "value",
    SELECT_ATTRIBUTE: "selectAttribute",
    UPLOAD_SKU: "uploadSku",
    CONFIRM_UPLOADED_SKU_DETAILS: "confirmUploadedSkuDetails",
    FORM_ERROR: "formError",
    DEF_LOC_CONFIG: "defaultLocationConfig",
    MOVEMENT_TYPE: "movementType",
    DEFAULT_LOC: "defaultLocation",
    DEFAULT_LOCS: "defaultLocations",
    PRINCIPAL: "Principal",
    LOT_NUMBER: "lotNumber",
    MANUFACTURING_DATE: "manufacturingDate",
    EXPIRY_DATE: "expiryDate",
    ASN_NUMBER: "asnNumber",
    ASN_DATE: "asnDate",
    GRN_NUMBER: "grnNumber",
    GRN_DATE: "grnDate",
    ACTIONS: "actions",
    STOCK_DETAILS: "stockDetails",
    STOCK_HISTORY: "stockHistory",
    STOCK_ALLOCATIONS: "stockAllocations",
    OPERATOR: "operator",
    LOCATION: "location",
    DUTY_STATUS: "dutyStatus",
    PERMIT_TYPE: "permitType",
    PERMIT_NUMBER: "permitNumber",
    UPDATED_DATE: "updatedDate",
    OPERATION_TYPE: "operationType",
    ORIGIN: "origin",
    DESTINATION: "destination",
    INVENTORY_LIST: "inventoryList",
    STOCK_HISTORY_MOVEMENT: "stockMovementHistory",
    SKU_STOCK_HISTORY_MOVEMENT: "skuStockMovementHistory",
    PRINCIPAL_MAPPING: "principalMapping",
    PRINCIPAL_MAPPING_DETAILS: "principalMappingDetails",
    PARTY_ID: "partyId",
    MAPPED_PRINCIPALS: "mappedPrincipals",
    PARTY_DETAILS: "partyDetails",
    PRINCIPAL_COMPANY_ID: "principalCompanyId",
    PRINCIPAL_PARTY_ID: "principalPartyId",
    PRINCIPAL_MAPPING_FORM: "principalMappingForm",
    UPDATED_DATETIME: "updatedDatetime",
    FROM_LOCATION: "fromLocation",
    TO_LOCATION: "toLocation",
    DUTY_UNPAID: "Duty Unpaid",
    DUTY_PAID: "Duty Paid",
    INVENTORY_LIST_UPDATE: "inventoryListUpdate",
    INVENTORY_LIST_DETAILS: "inventoryListDetails",
    INVENTORY_COST: "Inventory Cost",
    PURCHASING_INFORMATION: "Purchasing Information",
    CURRENCY: "Currency",
    PRODUCT_COST: "Product Cost",
    IMPORT_HANDLING_COST: "Import Handling Cost",
    FREIGHT_COST: "Freight Cost",
    INSURANCE_COST: "Insurance Cost",
    TOTAL_LANDED_COST: "Total Landed Cost",
    TAX_CODE: "Tax Code",
    INBOUND_CHARGES: "Inbound Charges",
    INVENTORY_UNIT_COST: "Inventory Unit Cost",
    COST_DETAILS: "Cost Details",
    CANCELLED:"Cancelled",
    OVER_HEAD_COST: "Overhead Cost",
    CUSTOM_DUTY: "Custom Duty",
    EXCISE_DUTY: "Excise Duty",
    TOTAL_LANDED_COST_NET: "Total Landed Cost Net",
    EXCLUDING_DUTIES_AND_TAXES: "Excluding Duties and Taxes",
    EXCLUDING_DUTIES_AND_TAXES_DESC: "Product + Import Handling + Freight + Insurance  + Overhead",
    WITH_DUTIES: "With Duties",
    WITH_DUTIES_DESC: "Product + Import Handling + Freight + Insurance  + Overhead + Custom Duty + Excise Duty",
    WITH_DUTIES_AND_TAXES:  "With Duties and Taxes",
    WITH_DUTIES_AND_TAXES_DESC: "Product + Import Handling + Freight + Insurance  + Overhead + Custom Duty + Excise Duty + Tax",
    CLONE_BUTTON: "Clone",
    WEIGHTED_COST: "Weighted Cost",
    WEIGHTED_AVG_COST_DU: "WAC DU",
    CURRENT_VALUE_DU: "Current Value DU",
    WEIGHTED_AVG_COST_DP: "WAC DP",
    CURRENT_VALUE_DP: "Current Value DP",
    WAC_DU_CAL: "(WAC DU * avail DU qty in SKU  + new inbound’s TLC) / Total avail DU qty in SKU",
    CV_DU_CAL: "WAC DU * Available DU Loose Qty",
    WAC_DP_CAL: "(WAC DP * avail DP qty in SKU + ((WAC DU + Excise Duties) * Transferred Qty)) / Total avail DP qty in SKU",
    CV_DP_CAL: "WAC DP* Available DP Loose Qty"
}
export default SCOInvMgmt