import {
  Box,
  Checkbox,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Paper
} from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'

import { Eye } from 'react-feather'
import ManageScheduleTranslationText from 'src/views/vesselschedule/manageschedule/ManageScheduleTranslationText'
import PortCountryViewField from 'src/components/field/PortCountryViewField'
import PortTransportModeStepper from 'src/components/field/PortTransportModeStepper'
import bytesToSize from 'src/views/freightbooking/components/DocumentByteToSize'
import moment from 'moment'
import { useServices } from 'cng-web-lib'

export default function CngTableBody(props) {
  const mscheduletranslatedTextsObject = ManageScheduleTranslationText()
  const defaultDateFormat = 'DD MMM YYYY HH:mm'

  return (
    <Fragment>
      {props.data.length > 0 ? (
        <TableBody>
          {(props.rowsPerPage > 0
            ? props.data.slice(
                props.page * props.rowsPerPage,
                props.page * props.rowsPerPage + props.rowsPerPage
              )
            : props.data
          ).map((item, itemIndex) => (
            
            <Fragment key={`Fragment-${item.id}`}>
              
              <TableRow
                key={item.id}
                onClick={props.onRowClick(item.id)}
                selected={item.checked}
              >
                
                {!props.viewOnly ? (
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color="primary"
                      checked={item.checked}
                      onClick={props.onRowCheck(item.id)}
                    />
                  </TableCell>
                ) : null}
                <TableCell
                  className={'vs-table-cell-padding'}
                  component='th'
                  scope='row'
                  className={props.customClassName}
                >
                  <div>{item.seqNo}</div>
                </TableCell>

                {props.columns.map((x, i) => {
                  // console.log('CngTableBody x > ' + JSON.stringify(x))
                  // console.log('CngTableBody item > ' + JSON.stringify(item))
                  // console.log('CngTableBody item > ' + x.field + ' ' + item[x.field])

                  if (x.show) {
                    if (x.type == 'date') {
                      return (
                        <TableCell
                          className={`vs-table-cell-padding ${props.customClassName}`}
                        >
                          {moment(item[x.field]).isValid()
                            ? moment(item[x.field]).format(
                                x.dateFormat ? x.dateFormat : defaultDateFormat
                              )
                            : null}
                        </TableCell>
                      )
                    } else if (x.type == 'portCountry') {
                      return (
                        <TableCell
                          className={`vs-table-cell-padding ${props.customClassName}`}
                        >
                          <PortCountryViewField
                            portCode={item[x.field]} //portCode
                          />
                        </TableCell>
                      )
                    } else if (x.type == 'portTransportStepper') {
                      return (
                        <TableCell
                          className={`vs-table-cell-padding ${props.customClassName}`}
                        >
                          {itemIndex < props.data.length - 1 ? (
                            <PortTransportModeStepper
                              fromPortCode={item['portCode']}
                              toPortCode={props.data[itemIndex + 1]['portCode']}
                              // toPortCode={item['portCode']}
                              transportMode={item['transportMode']}
                              // bgClassName='schedule-info-row-bg'
                            />
                          ) : null}
                        </TableCell>
                      )
                    } else if (x.lookup == 'user') {
                      return (
                        <TableCell
                          className={`vs-table-cell-padding ${props.customClassName}`}
                        >
                          {/* {console.log('CngTableBody userProfile> ' + JSON.stringify(props.userProfile))}
                        {console.log('CngTableBody user> ' + JSON.stringify(props.user))} */}

                          {
                            props.userProfile[
                              props.userProfile.findIndex(
                                (userProfile) =>
                                  userProfile.id ===
                                  props.user[
                                    props.user.findIndex(
                                      (user) => user.id === item[x.field]
                                    )
                                  ]?.userProfileId
                              )
                            ]?.name1
                          }
                        </TableCell>
                      )
                    } else if (x.type == 'truncate') {
                      return (
                        <TableCell
                          className={`vs-table-cell-padding ${props.customClassName}`}
                        >
                          <Tooltip title={item[x.field]} placement='top'>
                            <div className={'truncate'}>{item[x.field]}</div>
                          </Tooltip>
                        </TableCell>
                      )
                    } else if (x.type == 'status') {
                      return (
                        <TableCell className={'vs-table-cell-padding'}>
                          {item[x.field] ? (
                            <div className={'rs-green'}>{'Active'}</div>
                          ) : (
                            <div className={'rs-grey'}>{'Inactive'}</div>
                          )}
                        </TableCell>
                      )
                    } else if (x.type == 'fileSize') {
                      return (
                        <TableCell className={'vs-table-cell-padding'}>
                          {bytesToSize(item[x.field])}
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell
                          className={`vs-table-cell-padding ${props.customClassName}`}
                        >
                          {item[x.field]}
                        </TableCell>
                      )
                    }

                    // return (
                    //   <TableCell className={'vs-table-cell-padding'}>
                    //     {
                    //     x.type == 'date' ?
                    //       moment(item[x.field]).format('DD MMM YYYY HH:mm')
                    //     :
                    //       item[x.field]
                    //     }
                    //   </TableCell>
                    // )
                  }
                })}

                {/* {props.columns.map((x) => {
              return (
                  <TableCell className={'vs-table-cell-padding'}>
                    {item[x.field]}
                  </TableCell>
              );
             })} */}

                {/* {props.columns.map((x, i) => (
                <TableCell className={'vs-table-cell-padding'}>
                  {item[x.field]}
                </TableCell>
            ))} */}

                {!props.viewOnly ? (
                  <TableCell className={'vs-table-cell-padding'}>
                    <Tooltip title='View and Edit' placement='top' arrow>
                      <IconButton color={'default'}>
                        <Eye size='16px' />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                ) : null}
                
              </TableRow>
              
            </Fragment>
            
          ))}
        </TableBody>
      ) : (
        <TableBody>
          <TableRow>
            <TableCell colSpan={props.columns.length + 2}>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Box style={{ minHeight: '70vh' }}>
                  <Box pt={20}>No record(s) to display</Box>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Fragment>
  )
}
