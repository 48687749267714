const CecDoUomMappingKeys = {

	//------------UOM Conversion------------------
	CEC_DO_UOM_MAP: "cecDoUomMap",
	CEC_DO_UOM_MAP_TITLE: "cecDoUomMapTitle",
	CEC_DO_UOM_MAP_CREATE: "cecDoUomMapCreate",
	CEC_DO_UOM_MAP_EDIT: "cecDoUomMapEdit",
	CEC_DO_UOM_MAP_VIEW: "cecDoUomMapView",
	CEC_DO_UOM_MAP_WEIGHT: "cecDoUomMapWeightUom",
	CEC_DO_UOM_MAP_VOLWEIGHT: "cecDoUomMapVolumentricWeightUom",
	CEC_DO_UOM_MAP_PACKDIMENSION: "cecDoUomMapOutputUOM"

}

export default CecDoUomMappingKeys