import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import FileCopyIcon from '@material-ui/icons/FileCopy';
const {
  button: { CngPrimaryButton, CngSecondaryButton },
} = components

function CopyButton(buttonProps) {

  const isPrimary = buttonProps.isPrimary
  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      {(isPrimary) ?
        (<CngPrimaryButton {...buttonProps}><FileCopyIcon />{translatedTextsObject.copy}</CngPrimaryButton>)
        :
        (<CngSecondaryButton {...buttonProps}><FileCopyIcon />{translatedTextsObject.copy}</CngSecondaryButton>)}
    </div>
  )

}

export default CopyButton
