import React, { useState, useEffect } from 'react'

function LoadMoreObserver({ onInView, ...props }) {

    const [elementRef, setElementRef] = useState(null)

    const observer = new IntersectionObserver((entries, _observer) => {

        /*
            The observer will trigger the callback when:
            1) First trigger to observe()
            2) When come into view
            3) When goes out of view

            The entry.isIntersecting will be "true" when come into view,
            so this component will only trigger the parent's callback when this is the case.
        */
        if ((entries) && (entries.length) && (entries[0].isIntersecting)) {
            onInView(entries, _observer)
        }
    })

    useEffect(() => {
        if (elementRef) {
            observer.observe(elementRef)
        }
    }, [elementRef])

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <div className='loadMoreObserver' ref={setElementRef}>
            {props.children}
        </div>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default LoadMoreObserver