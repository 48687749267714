const CommonConfigKeys = {
    TITLE: "title",
    CONFIG_FOR: "configFor",
    CONFIG_VALUE1: "configValue1",
    CONFIG_VALUE2: "configValue2",
    CONFIG_VALUE3: "configValue3",
    CONFIG_DESCRIPTION: "configDescription"
  }
  
  export default CommonConfigKeys
  