const TcalVsManageScheduleValidationKeys = {
  ARRIVAL_EARLIER_THAN_DEPARTURE: "arrivalEarlierThanDeparture",
  DEPARTURE_EARLIER_THAN_ARRIVAL: "departureEarlierThanArrival",
  CY_CUTOFF_LATER_THAN_DEPARTURE: "cyCutoffLaterThanDeparture",
  INVALID_TRASIT_TIME: "invalidTrasitTime",
  DATE_TIME_ERROR: "dateTimeError",
  DATE_TIME_ERROR_DDMMYYYY_HHmm: "dateTimeError_DDMMYY_HHmm",
  ARRIVAL_PORT_SAME_ERROR: "arrivalPortSameError",
  EXCEED_MAX_CHARACTERS: "exceedMaxCharacters",
  EMOJI_CHARACTER_PRESENT: "emojiCharacterPresent"

}

export default TcalVsManageScheduleValidationKeys
