import { Box, Card, Chip, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import Utils from 'src/views/common/utils/Utils'
import { useLocation } from 'react-router-dom'
import React from 'react'
const {
    CngGridItem
  } = components

const AuditTrailDualColumnMultipleFields = (props) => {
    const {
        label,
        object,
        fields,
        delimiter,
        codeType,
        codeMasterList,
        added,
        removed,
        match,
    } = props
    const NO_VALUE = 'No values'

    let oldValue
    let newValue
  
    if(object===null || object === undefined) {
        return <div/>
    }

    let value=[]
    for(let i=0; i< fields.length; i++) {
        let fieldV = object[fields[i]]
        if(codeType && i==1) {
            fieldV = Utils.matchCodeMaster(fieldV, codeType, codeMasterList)
        } 
        if(match && i==1) {
            fieldV = Utils.matchField(fieldV, match)
        }
        value.push(fieldV)        
    }
    let valueJoined = Utils.isNotEmpty(value.join(delimiter).trim()) ? value.join(delimiter) : NO_VALUE
    if(added) {
        oldValue = NO_VALUE        
        newValue = valueJoined
    } else if (removed) {
        oldValue = valueJoined
        newValue = NO_VALUE
    } else {
        const values = Utils.getAuditTrailDiffs(object, fields)
        if(Utils.noChanges(values)) {
            return <div/>
        }
        values.valueOld[1] = Utils.matchCodeMaster(values.valueOld[1], codeType, codeMasterList)
        values.valueNew[1] = Utils.matchCodeMaster(values.valueNew[1], codeType, codeMasterList)
        values.valueOld[1] = Utils.matchField(values.valueOld[1], match)
        values.valueNew[1] = Utils.matchField(values.valueNew[1], match)
        
        oldValue = values.valueOld.join(delimiter)
        newValue = values.valueNew.join(delimiter)
        
    }
    
    if(Utils.objectsEqual(newValue , oldValue)) return <div/>

    return (
        <Grid container xs={12} sm={12} spacing={0}>
                    <CngGridItem xs={6} sm={6}>
                         <LabelValueVertical label={label}
                                            value={oldValue} 
                                            valueStyle={oldValue===NO_VALUE ? 'greyLabel paddingLeft16' : undefined} />
                         </CngGridItem>
                    <CngGridItem xs={6} sm={6}>
                        <LabelValueVertical label={label}
                                            value={newValue} 
                                            valueStyle={newValue===NO_VALUE ? 'greyLabel paddingLeft16' : undefined}/>                     

                    </CngGridItem>
        </Grid>            
    )
}

export default AuditTrailDualColumnMultipleFields
