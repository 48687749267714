const ShippingInstructionValidationKeys = {
  VALUE_TO_SMALL: 'valueTooSmallMsg',
  NUMBER_ERROR: 'numberErrorMsg',
  CONT_NUMBER_ERROR: 'contNumberErrorMsg',
  CONT_NUMBER_UNIQUE: 'contNumberUniqueMsg',
  INVALID_STRING: 'invalidStrMsg',
  INVALID_ADDRESS: 'invalidAddressMsg',
  INVALID_BLANK_SPACE: 'invalidBlankSpaceMsg',
  INVALID_HSCODE: 'invalidHSCode',
  VALUE_TOO_BIG: 'valueTooBigMsg',
  INTEGER_ONLY: 'IntegerOnlyMsg',
  INVALID_PHONE: 'invalidPhoneMsg',
  WORD_LIMIT_EXCEED: 'wordLimitExceedMsg',
  INVALID_PARTY_NAME: 'invalidPartyName',
  INVALID_EMOJI: 'invalidEmojiMsg',
  INVALID_EMOJI_NEW_LINE: 'invalidEmojiEndNewLineMsg',
  INVALID_EMAIL_LIST: 'invalidEmailListMsg',
  EXCEED_EMAIL_LIST_LENGTH: 'exceedEmailListLengthMsg',
  INVALID_PARTY_NAME_LENGTH_MSG: 'invalidPartyNameLengthMsg',
  INVALID_PARTY_NAME_PIL_LENGTH_MSG: 'invalidPartyNamePILLengthMsg',
  INVALID_PARTY_ADDRESS_LENGTH_MSG: 'invalidPartyAddressLengthMsg',
  INVALID_PARTY_ADDRESS_PIL_LENGTH_MSG: 'invalidPartyAddressPILLengthMsg',
  AGENT_ADDRESS_EXCEED_MAX_LINES: 'agentAddressExceedMaxLines',
  AGENT_ADDRESS_EXCEED_MAX_LENGTH: 'agentAddressExceedMaxLength',
  INVALID_BL_NUMBER: 'invalidBLNumber',
  REQUIRED_CONT_NUMBER: 'requiredContNumber',
  REQUIRED_CONT_TYPE_SIZE: 'requiredContTypeSize',
  REQUIRED_CONT_SEAL_NUMBER: 'requiredContSealNo',
  REQUIRED_CONT_WEIGHT: 'requiredContWeight',
  NON_DEC_WEIGHT: 'nonDecWeight',
  MIN_CONT_WEIGHT: 'minContWeight',
  MAX_CONT_WEIGHT: 'maxContWeight',
  REQUIRED_CARGO_TYPE: 'requiredCargoType',
  REQUIRED_RELEASE_LOC_CODE: 'requiredReleaseLocCode',
  REQUIRED_RECEIPT_LOC_CODE: 'requiredReceiptLocCode',
  MIN_ITEM_QTY: 'minItemQuantity',
  MIN_ITEM_CIF_FOB: 'minItemCifFob',
  MIN_ITEM_GROSS_WEIGHT: 'minItemGrossWeight',
  MAX_ITEM_MARKS_AND_NOS: "maxItemMarksAndNos",
  REQUIRED_AMOUNT_AND_CURRENCY: "requiredAmountAndCurrency",
  CHARGE_AMOUNT_TYPE_ERROR: "chargeAmountTypeError"
}
export default ShippingInstructionValidationKeys
