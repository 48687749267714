import { Avatar, Box, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import CarrierApi from 'src/views/common/api/CarrierApi'
import Utils from 'src/views/common/utils/Utils'
import useFetchCarrier from 'src/views/common/serviceHook/useFetchCarrier'
import { useServices } from 'cng-web-lib'
import { faAnchor } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CarrierViewWithVesselField = (props) => {
  // alert('carrierPartyId ' + JSON.stringify(props.carrierPartyId))

  const { fetchRecords } = useServices()
  const [carrier, setCarrier] = useState([])
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    CarrierApi.fetchCarrier(fetchRecords, onLoadCarrierSuccess)
  }, [props])

  // useFetchCarrier(onLoadCarrierSuccess)

  function onLoadCarrierSuccess(data) {
    console.log('CarrierViewWithVesselField ' + JSON.stringify(data))
    let matched = data.find((c) => {
      console.log(
        c.partyId +
          ' == ' +
          props.carrierPartyId +
          ' > ' +
          (c.partyId == props.carrierPartyId)
      )
      return c.partyId == props.carrierPartyId
    })

    console.log('CarrierViewWithVesselField matched ' + matched)

    if (matched === undefined) {
      console.log('null')
      // carrier.code = "test"
    }
    setCarrier(matched)
    setShouldRender(true)
  }

  if (!shouldRender) {
    return null
  }

  console.log(
    'CarrierViewWithVesselField return ' +
      props.carrierPartyId +
      ' > ' +
      JSON.stringify(carrier)
  )

  return (
    <div className={'rs-content'}>
      {/* <div> */}
      <Grid container sm={12} xs={12}>
        <Grid md={3} sm={12} xs={12}>
          <Avatar
            alt={carrier == undefined ? ' ' : carrier.name}
            src={`${process.env.PUBLIC_URL}/static/images/carriers/${
              carrier == undefined ? ' ' : carrier.code
            }.svg`}
            classes={{
              root: 'cng-summary-card--avatar circle',
              colorDefault: 'default-bg',
              img: 'img-src'
            }}
            variant='rounded'
          >
            {/* <img
                            className={'view-field-img-lg'}
                            src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                        ></img> */}
            <FontAwesomeIcon icon={faAnchor} />
          </Avatar>
        </Grid>
        <Grid md={9} sm={12} xs={12} style={{ paddingLeft: '12px' }}>
          <Box>{carrier == undefined ? '-' : carrier.code}</Box>
          <Box fontSize={14} fontWeight='Normal'>
            {Utils.getDashIfNullOrEmpty(props.vesselName)}
          </Box>
          <Box className='schedule-carrier-voyage-service'>
            {Utils.getDashIfNullOrEmpty(props.service)}/
            {Utils.getDashIfNullOrEmpty(props.voyageNo)}
          </Box>
        </Grid>
      </Grid>

      {/* </div> */}
      {/* <div>
                {(carrier == undefined ? " " : carrier.name)}
            </div> */}
    </div>
  )
}

export default CarrierViewWithVesselField
