const TradeManagementKeys = {
  FTACALCULATORTITLE: 'ftacalculatorTitle',
  EXPORT: 'export',
  SectionTitles: {
    PRODUCT_INFORMATION: 'sectionTitle.productInformation',
    CALCULATION_RESULTS: 'sectionTitle.calculationResults',
    ORIGIN_DESTINATION_INFORMATION: 'sectionTitle.originDestinationInformation',
    DUTY_SAVINGS_INFORMATION: 'sectionTitle.dutySavingsInfomation',
  },
  BreadCrumbs: {
    BC_EXPORT: 'breadCrumbs.export',
    BC_FTA_CALCULATOR: 'breadCrumbs.ftaCalculator',
    BC_VIEW_FTA_CALCULATIONS: 'breadCrumbs.viewFtaCalculations',
    BC_FTA_ACTIVITY_HISTORY: 'breadCrumbs.ftaActivityHistory'
  },
  General: {
    PROVIDE_NECESSARY_INFORMATION: 'general.provideNecessaryInfo',
    ALL_FIELDS_ARE_MANDATORY: 'general.allFieldsAreMandatory',
    OTHER_OPTIONS: 'general.otherOptions',
    FIELDS_ARE_NON_MANDATORY: 'general.fieldsAreNonMandatory',
    ABOUT_FTA_POPOVER_CONTENT: 'general.aboutFTAPopoverContent',
    ITEM: 'general.item',
    REFRESH: 'general.refresh',
    PROBLEM_RETRIEVING: 'general.problemRetrieving',
    NO_ITEMS_FOUND: 'general.noItemsFound',
    NO_ADJUST_SEARCH: 'general.adjustSearch',
    NO_ITEMS_YET: 'general.noItemsYet',
    NO_ITEMS_SUBMITTED: 'general.noItemsSubmitted',
    SEARCH_INPUT_PLACEHOLDER_LABEL: 'general.searchInputPlaceholderLabel'
  },
  FieldLabels: {
    MATERIAL_NO_SKU_PDT_DESCRIPTION: 'fieldLabels.materialNoSkuPdtDescription',
    MATERIAL_NO_SKU: 'fieldLabels.materialNoSku',
    HSCODE_DESCRIPION: 'fieldLabels.hsCodeAndDescription',
    MATERIAL_NO: 'fieldLabels.materialNo',
    SKU: 'fieldLabels.sku',
    PRODUCT_DESCRIPTION: 'fieldLabels.productDescription',
    HSCODE: 'fieldLabels.hsCode',
    HS_DESCIRPTION: 'fieldLabels.hsDescription',
    DESCIRPTION: 'fieldLabels.description',
    MODEL: 'fieldLabels.model',
    UNIT_OF_MEASUREMENT: 'fieldLabels.unitOfMeasurement',
    UOM: 'fieldLabels.uom',
    CURRENCY: 'fieldLabels.currency',
    EX_WORKS_COST: 'fieldLabels.exworksCost',
    ORIGIN: 'fieldLabels.origin',
    DESTINATION: 'fieldLabels.destination',
    CIF_VALUE: 'fieldLabels.cifValue',
    BACK_TO_CALCULATOR: 'fieldLabels.backToCalculator',
    CALCULATION_RESULTS: 'fieldLabels.calculationResults',
    ITEMS_PER_PAGE: 'fieldLabels.itemsPerPage',
    PREVIOUS: 'fieldLabels.previous',
    NEXT: 'fieldLabels.next',
    DUTY_SAVING_PERCENT_AMOUNT: 'fieldLabels.dutySavingPercentAndAmount',
    DUTY_SAVING_PERCENT: 'fieldLabels.dutySavingPercentage',
    AMOUNT: 'fieldLabels.amount',
    QUALIFICATION_DATE: 'fieldLabels.qualificationDate',
    QUALIFICATION_EXPIRY: 'fieldLabels.qualificationExpiry',
    ASSESSED_BY: 'fieldLabels.assessedBy',
    CHAPTER: 'fieldLabels.chapter',
    CHAPTER_DESCRIPTION: 'fieldLabels.chapterDescription',
    CALCULATION: 'fieldLabels.calculation',
    THRESHOLD_VALUE_PER_UOM: 'fieldLabels.thresholdValuePerUOM',
    PROFIT: 'fieldLabels.profit',
    NON_ORIGINATING_VALUE: 'fieldLabels.nonOriginatingValue',
    NON_ORIGINATING_VALUE_PERCENT: 'fieldLabels.nonOriginatingValuePercentage',
    DIRECT_LABOUR_COST: 'fieldLabels.directLabourCost',
    DIRECT_OVERHEARD_COST: 'fieldLabels.directOverheardCost',
    OTHER_COST: 'fieldLabels.otherCost',
    BASE_UNIT: 'fieldLabels.baseUnit',
    QVC: 'fieldLabels.qvc',
    RVC: 'fieldLabels.rvc',
    DEFAULT_DUTY_AMOUNT: 'fieldLabels.defaultDutyAmount',
    FTA_DUTY_AMOUNT: 'fieldLabels.ftaDutyAmount',
    POTENTIAL_DUTY_SAVINGS: 'fieldLabels.potentialDutySavings',
    POTENTIAL_DUTY_SAVINGS_PERCENT:
      'fieldLabels.potentialDutySavingsPercentage',
    COUNTRY_ORIGIN: 'fieldLabels.countryOrigin',
    COUNTRY_DESTINATION: 'fieldLabels.countryDestination',
    MATERIAL_TYPE: 'fieldLabels.materialType',
    ORIGINATING_VALUE: 'fieldLabels.originatingValue',
    ORIGINATING_VALUE_PERCENT: 'fieldLabels.originatingValuePercentage',
    LTSD_STATUS: 'fieldLabels.ltsdStatus',
    LTSD_START_END_DATE: 'fieldLabels.ltsdStartEndDate',
    UNIT: 'fieldLabels.unit',
    STANDARD_PRICE_PER_UOM: 'fieldLabels.standardPricePerUOM',
    CUSTOMS_TARIFF_NO: 'fieldLabels.customsTariffNo',
    COMPONENT_NO: 'fieldLabels.componentNo',
    BOM_NAME: 'fieldLabels.bomName',
    COMPANY_NAME: 'fieldLabels.companyName',
    COMPANY_REF_NO: 'fieldLabels.companyRefNo',
    LAST_MODIFIED_ON: 'fieldLabels.lastModifiedOn',
    FREE_ON_BOARD_PRICE: 'fieldLabels.fobPrice',
    TRANSACTION_VALUE: 'fieldLabels.transactionValue',
    NET_COSTS: 'fieldLabels.netCosts',
    TOTAL_COSTS: 'fieldLabels.totalCosts'
  },
  Statuses: {
    STATUS_QUALIFIED: 'statuses.statusQualified',
    STATUS_DN_QUALIFIED: 'statuses.statusDNQualified',
    STATUS_SELF_ASSESSMENT_REQUIRED: 'statuses.statusSelfAssessmentRequired',
    STATUS_PRICE_DEPENDENT: 'statuses.statusPriceDependent',
    STATUS_DELETED: 'statuses.statusDeleted',
    STATUS_ACTIVE: 'statuses.statusActive',
    STATUS_INSUFFICIENT_DATA: 'statuses.statusInsufficientData'
  },
  ButtonLabels: {
    BTN_BACK_TO_CALCULATOR: 'buttonLabels.btnBackToCalculator',
    BTN_BACK_TO_CALCULATION_RESULTS: 'buttonLabels.btnBackToCalculationResults',
    BTN_BACK_TO_SEARCH_ACTIVITY_HISTORY:
      'buttonLabels.btnBackToSearchActivityHistory',
    BTN_CALCULATE: 'buttonLabels.btnCalculate',
    BTN_CLEAR_FIELDS: 'buttonLabels.btnClearFields',
    BTN_GO_BACK: 'buttonLabels.btnGoBack',
    BTN_SEARCH: 'buttonLabels.btnSearch',
    BTN_RULES_OF_ORIGIN: 'buttonLabels.btnRulesOfOrigin',
    BTN_DUTY_SAVINGS: 'buttonLabels.btnDutySavings',
    BTN_RELTED_BOM: 'buttonLabels.btnRelatedBoM'
  },
  Tooltip: {
    TTIP_EDIT_PRODUCT_INFORMATION: 'tooltipMessages.ttipEditProductInformation'
  },
  YupValidation: {
    MATERIAL_NO_SKU_PDT_DESCRIPTION_REQUIRED_MSG:
      'validationMessages.materialNoSKURequiredMsg',
    MATERIAL_NO_SKU_PDT_DESCRIPTION_INVALID_MSG:
      'validationMessages.materialNoSKUInvalidMsg',
    HSCODE_DESCRIPION_REQUIRED_MSG:
      'validationMessages.hsCodeAndDescRequiredMsg',
    HSCODE_DESCRIPION_INVALID_MSG: 'validationMessages.hsCodeAndDescInvalidMsg',
    MODEL_REQUIRED_MSG: 'validationMessages.modelRequiredMsg',
    MODEL_INVALID_MSG: 'validationMessages.modelInvalidMsg',
    UNIT_OF_MEASUREMENT_REQUIRED_MSG:
      'validationMessages.unitOfMeasurementRequiredMsg',
    UNIT_OF_MEASUREMENT_INVALID_MSG:
      'validationMessages.unitOfMeasurementInvalidMsg',
    CURRENCY_REQUIRED_MSG: 'validationMessages.currencyRequiredMsg',
    CURRENCY_INVALID_MSG: 'validationMessages.currencyInvalidMsg',
    EX_WORKS_COST_REQUIRED_MSG: 'validationMessages.exworksCostRequiredMsg',
    EX_WORKS_COST_INVALID_MSG: 'validationMessages.exworksCostInvalidMsg',
    ORIGIN_REQUIRED_MSG: 'validationMessages.originRequiredMsg',
    ORIGIN_INVALID_MSG: 'validationMessages.originInvalidMsg',
    ORIGIN_CANNOT_BE_SAME_MSG: 'validationMessages.originCannotBeSameMsg',
    DESTINATION_REQUIRED_MSG: 'validationMessages.destinationRequiredMsg',
    DESTINATION_INVALID_MSG: 'validationMessages.destinationInvalidMsg',
    DESTINATION_CANNOT_BE_SAME_MSG:
      'validationMessages.destinationCannotBeSameMsg',
    CIF_VALUE_REQUIRED_MSG: 'validationMessages.cifValueRequiredMsg',
    CIF_VALUE_INVALID_MSG: 'validationMessages.cifValueInvalidMsg',
    CALCULATION_SUCCESS_MSG: 'validationMessages.calculationSuccessMsg',
    PROBLEM_FETCHING_DATA_MSG: 'validationMessages.problemFetchingData',
    IMPORT_HS_CODE_REQUIRED: 'validationMessages.importHsCodeRequired'
  }
}
export default TradeManagementKeys
