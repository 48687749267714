const BatchMilestoneKeys = {
  TITLE: "title",
  ID: "id",
  PROCESSED_DATE: "processedDate",
  RECORD_STATUS: "recordStatus",
  TYPE: "type",
  SOURCE: "source",
  PORTOFSTATUS: "portofstatus",
  DESCRIPTION: "description",
  BOOKINGREF: "bookingref",
  DETAILS: "details",
  PARTY: "party",
  CODE: "code",
  EVENTDATE: "eventdate",
  EVENTTIME: "eventtime",
  EVENT_TIMEZONE: "eventTimezone",
  CONT_NO: "contNo",
  B_L_NUM: "bLNum",
  EVENT_DETAILS: "eventDetails",
  VESSEL_NAME: "vesselName",
  VOYAGE_NUM: "voyageNum",
  VOYAGE_NO: "voyageNo",
  REMARKS: "remarks"
}

export default BatchMilestoneKeys
