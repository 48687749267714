const NmotValidationKeys = {
    
    WORD_LIMIT_EXCEED_MSG: "wordLimitExceedMsg",
    WORD_LIMIT_MIN_MSG: "wordLimitMinMsg",
    INVALID_STRING_W_SPACE: "invalidStringWspace",
    INVALID_EMOJI_MSG: "invalidEmojiMsg",
    INVALID_STRING_W_SPECIAL_CHAR: "invalidStringWSpecialChar",
    INVALID_CONT_NO: "invalidContNo"

}
export default NmotValidationKeys