const ShippingInstructionKeys = {
  TITLE: 'title',
  CREATE_NEW_SI_TITLE: 'createNewSITitle',
  MY_SHIPPING_INSTRUCTION_TITLE: 'myShippingInstructionTitle',
  VIEW_SI_ONLY_TITLE: 'viewSIOnlyTitle',
  MANAGE_SHIPPING_INSTRUCTIONS_TITLE: 'manageShippingInstructionsTitle',
  CREATE_SI: 'createSITitle',
  FILLUP_DETAILS: 'fillupDetails',
  REVIEW_DETAILS: 'reviewDetails',
  SUBMIT: 'submit',
  FINISH: 'finish',
  SHIPMENT_OVERVIEW: 'shipmentOverview',
  DRAFTS: 'drafts',
  CREATESI_NOTE: 'createSINote',
  PRE_CARRIAGE_VESSEL_NAME: 'preCarriageVesselName',
  PRE_CARRIAGE_VOYAGE_NO: 'preCarriageVoyageNo',
  BL_APPLICATION_TYPE: 'blApplicationType',
  BL_NO: 'blNo',
  APPROVE_SI: 'approveSI',
  REJECT_SI: 'rejectSI',
  ISSUE_BL: 'issueBL',
  REVOKE_BL: 'revokeBL',
  REVOKE_ISSUED_BL: 'revokeIssuedBL',
  PRINT_BL: 'printBl',
  SURRENDER_BL: 'surrenderBl',
  RETRACT_SI: 'retractSI',
  NO_OF_ORIGINALS: 'noOfOriginals',
  NVOCC_REF_NUMBER: 'nvoccRefNumber',
  ADDITIONAL_REF_DETAILS: 'additionalRefDetails',
  ADDITIONAL_BL_DETAILS: 'additionalBLDetails',
  ADDITIONAL_PARTY_DETAILS: 'additionalPartyDetails',
  BL_DETAILS: 'blDetails',
  PARTIES: 'parties',
  SHIPPED_ON_BOARD_DATE_TIME: 'shippedOnBoardDateTime',
  NO_OF_CONTAINERS_PACKAGES_IN_WORDS: 'noOfContainersPackagesInWords',
  ISSUE_DATE_TIME: 'issueDateTime',
  ISSUE_PLACE: 'issuePlace',
  CARRIER_CLAUSE: 'carrierClause',
  CARRIER_CLAUSE_DESC: 'carrierClauseDesc',
  DOCUMENT_LAYOUT: 'documentLayout',
  FREIGHT_CHARGE: 'freightCharge',
  DEPOT: 'depot',
  SHIPPER: 'shipper',
  SHIPPER_NAME: 'shipperName',
  SHIPPER_ADDRESS: 'shipperAddress',
  CONSIGNEE_NAME: 'consigneeName',
  CONSIGNEE_ADDRESS: 'consigneeAddress',
  NOTIFY_PARTY_NAME: 'notifyPartyName',
  NOTIFY_PARTY_ADDRESS: 'notifyPartyAddress',
  USCC_NO: 'usccNo',
  USCC_NO_NOTE: 'usccNoNote',
  BOOKING_REPRESENTATIVE_DETAILS: 'bookingRepresentativeDetails',
  PRESHIPMENT_NOTIFICATION_CONTACTS: 'preshipmentNotificationContacts',
  AGENT_NAME: 'agentName',
  AGENT_ADDRESS: 'agentAddress',
  PARTY_TEMPLATE_BUTTON: 'partyTemplateButton',
  EXCEL_TEMPLATE_BUTTON: 'excelTemplateButton',
  CARGO_TEMPLATE_BUTTON: 'cargoTemplateButton',
  PARTICULARS: 'particulars',
  CONTAINER: 'container',
  TYPE: 'type',
  STATUS: 'status',
  SPECIAL_HANDLING: 'specialHandling',
  STOWAGE: 'stowage',
  ADD_A_CONTAINER: 'addAContainer',
  CONTAINER_DETAILS: 'containerDetails',
  CONTAINER_NO: 'containerNo',
  SEAL_NO: 'sealNo',
  CONTAINER_GROSS_WEIGHT: 'containerGrossWeight',
  CONTAINER_GROSS_WEIGHT_TONNE: 'containerGrossWeightTonne',
  TOTAL_VGM_WEIGHT: 'totalVgmWeight',
  SHIPPER_OWNED: 'shipperOwned',
  NONACTIVE_REEFER: 'nonActiveReefer',
  ADD_REEFER_DETAILS: 'addReeferDetails',
  REEFER_CONTAINER: 'reeferContainer',
  EQUIPMENT_TEMP: 'equipmentTemp',
  TEMP_UOM: 'tempUom',
  EQUIPMENT_AIRFLOW: 'equipmentAirFlow',
  EQUIPMENT_COMMENTS: 'equipmentComments',
  EDIT_SETTINGS: 'editSettings',
  MARKS_NOS: 'marksNos',
  ADD_CARGO: 'addCargo',
  UPLOAD_CARGO: 'uploadCargo',
  CARGO: 'cargo',
  CARGO_ID: 'cargoId',
  GROSS: 'gross',
  NET: 'net',
  CARGO_DESC: 'cargoDesc',
  HSCODE: 'hsCode',
  PACKAGE_TYPE: 'packageType',
  PACKAGE_TYPE_NUMBER: 'pkgCnt',
  VOLUME: 'volume',
  GROSS_VOLUME: 'grossVolume',
  GROSS_WEIGHT: 'grossWeight',
  GROSS_WEIGHT_T: 'grossWeightT',
  NET_VOLUME: 'netVolume',
  NET_WEIGHT: 'netWeight',
  LENGTH: 'length',
  HEIGHT: 'height',
  WIDTH: 'width',
  UOM: 'uom',
  ADDITIONAL_CARGO_DETAILS: 'additionalCargoDetails',
  QUANTITY: 'quantity',
  EXPORTCSV: 'exportCsv',
  PARTNEREMAIL: 'partnerEmailNotifications',
  EMAILTONOTIFY: 'email/sToNotify',
  EMAIL_DESC: 'emailDesc',
  NOTIFY_BOOKING_STATUS: 'notifyBookingStatus',
  NOTIFY_EVENT_MILESTONES: "notifyEventMilestones",
  TERM: 'term',
  ALIGN_CONTAINER_WITH_CARGO: 'alignContainerWithCargo',
  CONTROLTOTAL: 'controlTotal',
  CONTROL_TOTAL_DESC: 'controlTotalDesc',
  CREATE_SI_VIEW_SI_DRAFT: 'viewSIDraft',
  UPDATE_SI_DRAFTS: 'updateSIDrafts',
  VIEW_SI_DETAILS: 'viewSIDetails',
  MANAGE_SI: 'manageSI',
  UPLOAD_SI: 'uploadSI',
  UPLOAD: 'upload',
  BOOKING_TYPE: 'bookingType',
  TYPE_OF_SHIPMENT: 'typeOfShipment',
  CONTACT_DETAILS: 'contactDetails',
  REPRESENTATIVE_NAME: 'representativeName',
  REPRESENTATIVE_EMAIL: 'representativeEmail',
  REPRESENTATIVE_CONTACT_NUMBER: 'representativeContactNumber',
  PURCHASE_ORDER_NUMBER: 'purchaseOrderNumber',
  SHIPPER_REF_NUMBER: 'shipperRefNumber',
  FORWARDER_REF_NUMBER: 'forwarderRefNumber',
  SHIPPER_REMARKS: 'shipperRemarks',
  CARRIER_REMARKS: 'carrierRemarks',
  ADDITIONAL_SHIPMENT_DETAILS: 'additionalShipmentDetails',
  REFERENCES: 'references',
  ADD_PAYMENT_INSTRUCTION: 'addPaymentInstruction',
  PAYMENT_INSTRUCTION: 'paymentInstruction',
  CHARGE_TYPE: 'chargeType',
  PAYER: 'payer',
  FREIGHT_TERM: 'freightTerm',
  PMT_LOC: 'pmtLoc',
  SCHEDULE: 'schedule',
  PORT_OF_LOADING: 'portOfLoading',
  PORT_OF_DISCHARGE: 'portOfDischarge',
  POL_DESC: 'polDesc',
  POD_DESC: 'podDesc',
  POL_PLANNED_ETA: 'polPlannedEta',
  POD_PLANNED_ETA: 'podPlannedEta',
  POL_PLANNED_ETD: 'polPlannedEtd',
  POD_PLANNED_ETD: 'podPlannedEtd',
  DISCHARGE_TERMINAL: 'dischargeTerminal',
  VESSEL_NAME: 'vesselName',
  INTERNATIONAL_VOYAGE_NUMBER: 'internationalVoyageNumber',
  VOYAGE_NUMBER: 'voyageNumber',
  ADDITIONAL_SCHEDULE_DETAILS: 'additionalScheduleDetails',
  CARRIER_NVOCC_BOOKING_AGENT: 'carrierNvoccBookingAgent',
  LOAD_TERMINAL: 'loadTerminal',
  SHIPMENT_TTTLE: 'shipmentTitle',
  MOVE_TYPE: 'moveType',
  EMPTY_PICKUP_TITLE: 'emptyPickupTitle',
  SHIP_TO_TITLE: 'shipToTitle',
  SHIP_FROM_TITLE: 'shipFromTitle',
  PLACE_OF_RECEIPT: 'placeOfReceipt',
  PLACE_OF_DELIVERY: 'placeOfDelivery',
  CARGO_READY_DATE: 'cargoReadyDate',
  CARGO_DESTINATION_DATE: 'cargoDestinationDate',
  PLACE_OF_RECEIPT_DESC: 'placeOfReceiptDesc',
  PLACE_OF_DELIVERY_DESC: 'placeOfDeliveryDesc',
  PLACE_OF_RECEIPT_ETD: 'placeOfReceiptEtd',
  PLACE_OF_DELIVERY_ETA: 'placeOfDeliveryEta',
  DESTINATION_CODE: 'destinationCode',
  FINAL_DESTINATION: 'finalDestination',
  EMPTY_PICKUP_DATE: 'emptyPickupDate',
  FULL_PICKUP_DATE: 'fullPickupDate',
  SHIP_FROM_DATE_POSITIONING: 'shipFromDatePositioning',
  COMPANY: 'company',
  CONTACT: 'contact',
  DOCUMENT: 'document',
  ATTACH_DOCUMENT: 'attachDocument',
  FILE_DESC: 'fileDesc',
  FILE_TYPE: 'fileType',
  UPLOAD_SI_INFO: 'uploadSiInfo',
  CONTAINER1: 'container1',
  CONTAINER2: 'container2',
  TOTAL_QUANTITY: 'totalQuantity',
  TOTAL_GROSS_VOLUME: 'totalGrossVolume',
  TOTAL_NET_VOLUME: 'totalNetVolume',
  TOTAL_GROSS_WEIGHT: 'totalGrossWeight',
  TOTAL_NET_WEIGHT: 'totalNetWeight',
  CBM: 'cbm',
  FT: 'ft',
  KG: 'kg',
  MARK_AND_NOS: 'markAndNos',
  NO_OF_CONTAINERS: 'noOfContainers',
  NO_OF_PACKAGES: 'noOfPackages',
  SHIPMENT_WEIGHT: 'shipmentWeight',
  SHIPMENT_VOLUME: 'shipmentVolume',
  SHIPMENT_WEIGHT_NO_METRIC: 'shipmentWeightNoMetric',
  VGM_WEIGHT_NO_METRIC: 'vgmWeightNoMetric',
  CONTAINER_SIZE_TYPE_HEIGHT: 'containerSizeTypeHeight',
  SIZE: 'size',
  HEIGHT_TYPE: 'heightType',
  SOC: 'soc',
  SUCCESS: 'success',
  FILE_ERROR: 'fileError',
  PENDING_SI_SUBMISSION: 'pendingSISubmission',
  PREVIEW_BL_DOCUMENT: 'previewBLDocument',
  DRAFTED_ON: 'draftedOn',
  SUBMITTED_ON: 'submittedOn',
  APPROVED_ON: 'approvedOn',
  ISSUED_ON: 'issuedOn',
  SURRENDERED_ON: 'surrenderedOn',
  TO: 'to',
  BY: 'by',
  BOOKING_ID: 'bookingId',
  SHIPPING_INFO_ID: 'shippingInfoId',
  BACK: 'back',
  BACK_TO_MY_SI_PAGE: 'backToMySIPage',
  BACK_TO_VIEW_ONLY_SI_PAGE: 'backToViewOnlySIPage',
  BACK_TO_MANAGE_SI_PAGE: 'backToManageSIPage',
  MANAGE_MY_SI: 'manageMySI',
  REFERENCE_HEADER: 'referenceHeader',
  FORWARD_HUB_SCHEME: 'fowardHubScheme',
  OUTBOUND_REFERENCE_NO: 'outboundReferenceNo',
  NOTIFY_ME: 'notifyMe',
  TERM_POR: 'termPor',
  TERM_POD: 'termPod',
  ALIGN_CONTAINER: 'alignContainer',
  BOOKING_REF_NO: 'bookingRefNo',
  SHIPPER_REF_NO: 'shipperRefNo',
  BL_REF_NO: 'blrefNo',
  BOOKING_DATE_AND_TIME: 'bookingDateAndTime',
  NO_ITEM_HERE: 'noItemHere',
  NO_ITEM_DESC_SHIPPER: 'noItemDescShipper',
  CREATE_DIRECT_SI: 'createDirectSI',
  DIRECT_SI: 'directSI',
  CREATE_BOOKING: 'createBooking',
  SMTH_WRONG: 'smthWrong',
  SMTH_WRONG_DESC: 'smthWrongDesc',
  REFRESH: 'refresh',
  LAST_MODIFIED_ON: 'lastModifiedOn',
  CREATED_ON: 'createdOn',
  CREATED_SHIPPING_INSTRUCTIONS: 'createdShippingInstructions',
  SUBMITTED_SHIPPING_INSTRUCTIONS: 'submittedShippingInstructions',
  SHIPMENT_TYPE: 'shipmentType',
  BOOKING_REF_NUMBER: 'bookingReferenceNumber',
  FREIGHT_BOOKING_REF_NUMBER: 'freightBookingReferenceNumber',
  ADDITIONAL_BOOKING_TYPE_DET: 'additionalBookingTypeDetails',
  OUTBOUND_REF_NO: 'outboundRefNo',
  HAZARDOUS: 'hazardous',
  BOOKING_EXIST: 'bookingExist',
  POPULATE_FORM_1: 'populateForm1',
  POPULATE_FORM_2: 'populateForm2',
  CONFIRM_DELETE_SI_TITLE: 'confirmDeleteSITitle',
  CONFIRM_CANCEL_SI_TITLE: 'confirmCancelSITitle',
  CONFIRM_ARCHIVE_SI_TITLE: 'confirmArchiveSITitle',
  CONFIRM_DELETE_SI_CONTENT: 'confirmDeleteSIContent',
  CONFIRM_CANCEL_SI_CONTENT: 'confirmCancelSIContent',
  CONFIRM_ARCHIVE_SI_CONTENT: 'confirmArchiveSIContent',
  CONFIRM_DELETE_SI_OPTION: 'confirmDeleteSIOption',
  CONFIRM_CANCEL_SI_OPTION: 'confirmCancelSIOption',
  CONFIRM_ARCHIVE_SI_OPTION: 'confirmArchiveSIOption',
  CONFIRM_WITHOUT_CHANGE_CAPTION:'confirmWithoutChangeCaption',
  DIALOG_DISCARD_SI_TITLE: 'dialogDiscardSITitle',
  DIALOG_DISCARD_SI_CONTENT: 'dialogDiscardSIContent',
  DIALOG_DISCARD_CARGO_TITLE: 'dialogDiscardCargoTitle',
  DIALOG_DISCARD_CARGO_CONTENT: 'dialogDiscardCargoContent',
  DIALOG_OVERWRITE_CARGO_TITLE: 'dialogOverwriteCargoTitle',
  DIALOG_OVERWRITE_CARGO_CONTENT: 'dialogOverwriteCargoContent',
  DIALOG_CLOSE_DIALOG: 'dialogCloseDialog',

  SI_SUBMITTED: 'siSubmitted',
  SI_SUBMITTED_ON: 'siSubmittedOn',
  SI_CONFIRMED: 'siConfirmed',
  SI_CONFIRMED_ON: 'siConfirmedOn',
  SI_APPROVED: 'siApproved',
  SI_APPROVED_ON: 'siApprovedOn',
  SI_UPDATE_CONFIRMED: 'siUpdateConfirmed',
  SI_UPDATE_CONFIRMED_ON: 'siUpdateConfirmedOn',
  SI_SUBMITTED_FOR_APPROVAL: 'siSubmittedForApproval',

  AUDIT_SUMMARY_HEADER: 'auditSummaryHeader',
  RELATED_CONTAINER_NOS: 'relatedContainerNos',
  REEFER_SETTINGS: 'reeferSettings',
  CARGO_DETAILS: 'cargoDetails',
  RESET_FILTER_DESC: 'resetFilterDesc',
  SEAWAY_BILL_CLAUSE: 'seawayBillClause',
  BOMBAY_CLAUSE: 'bombayClause',
  DELETE_SUCCESS_MSG: 'deleteSuccessMsg',
  CANCEL_SUCCESS_MSG: 'cancelSuccessMsg',
  ARCHIVE_SUCCESS_MSG: 'archiveSuccessMsg',
  COPY_SUCCESS_MSG: 'copySuccessMsg',
  SUBMIT_SUCCESS_MSG: 'submitSuccessMsg',
  UPDATE_SUBMIT_SUCCESS_MSG: 'updateSubmitSuccessMsg',
  DRAFT_SUCCESS_MSG: 'draftSuccessMsg',
  CONFIRM_SUCCESS_MSG: 'confirmSuccessMsg',
  UPDATE_CONFIRM_SUCCESS_MSG: 'updateConfirmSuccessMsg',
  UPDATE_SUCCESS_MSG: 'updateSuccessMsg',
  SEND_SUCCESS_MSG: 'sendSuccessMsg',
  RETRACT_SUCCESS_MSG: 'retractSuccessMsg',
  APPROVE_SUCCESS_MSG: 'approveSuccessMsg',
  REJECTED_SUCCESS_MSG: 'rejectedSuccessMsg',
  ISSUE_SUCCESS_MSG: 'issueSuccessMsg',
  REVOKE_SUCCESS_MSG: 'revokeSuccessMsg',
  SURRENDER_SUCCESS_MSG: 'surrenderSuccessMsg',

  CANNOT_CREATE_SI_MSG: 'cannotCreateSIMsg',
  CANNOT_ADD_CONTAINER_MSG: 'cannotAddContainerMsg',
  CANNOT_ADD_CONTAINER_DIRECT_SI_MSG: 'cannotAddContainerDirectSIMsg',
  PROXY_PRINT_LOADING_MESSAGE: 'proxyPrintLoadingMessage',
  PROXY_PRINT_DOWNLOAD_MESSAGE: 'proxyPrintDownLoadMessage',
  PROXY_PRINT_REFRESH_MESSAGE: 'proxyPrintRefreshMessage',
  PROXY_PRINT_JOBSENDING_MESSAGE: 'proxyPrintJobSendingMessage',
  PROXY_PRINT_JOBSENT_MESSAGE: 'proxyPrintJobSentMessage',
  PROXY_PRINT_NO_PRINTER_MESSAGE: 'proxyPrintNoPrinterMessage',
  PROXY_PRINT_NO_PRINT_MESSAGE: 'proxyPrintNoPrintMessage',
  PROXY_PRINT: 'proxyPrint',
  AVAILABLE_PRINT_REMAINING: 'availablePrintRemaining',
  PRINTER_NAME: 'printerName',
  PRINT_OPTION: 'printOption',
  PRINT_OPTION_ALL: 'printOptionAll',
  PRINT_OPTION_CURRENT: 'printOptionCurrent',
  REFRESH_PRINTERS: 'refreshPrinters',

  FREIGHT_BOOKING_ID: 'freightBookingId',
  CARRIER_NVOCC_BOOKING_AGENT_NAME: 'carrierNvoccBookingAgentName',
  PRINTED_ORIGINALS: 'printedOriginals',
  TRADE: 'trade',
  DECLARATION: 'declaration',
  COMPLIANCE_STATUS: 'complianceStatus',

  PUSH_TO_ESSDOCS: 'pushToEssdocs',
  DOC_PUSH_COUNT: 'docPushCount',
  DOC_PUSH_DATE: 'docPushDate',

  CREATE_PERMIT: 'createPermit'
}
export default ShippingInstructionKeys
