import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const { CngGridItem } = components

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: theme.palette.common.black,
    //color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bolder'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

const styleNovalue = { color: '#8996AFCC' }
const styleOld = { color: '#FF808B' }
const styleNew = { color: '#7CE7AC' }

const AuditTrailRowOfChange = (props) => {
  const {
    label,
    group,
    subGroup,
    object,
    field,
    uom,
    added,
    removed,
    checkbox,
    timestamp,
    ports,
    status
  } = props
  const NO_VALUE = 'Empty'
  const OLD_VALUE = 'old_value'
  const NEW_VALUE = 'new_value'
  const CHANGE = '_changes_'
  let oldValue
  let newValue

  if (object === null || object === undefined) return <div />

  if (added) {
    oldValue = NO_VALUE
    newValue = object[field]
  } else if (removed) {
    oldValue = object[field]
    newValue = NO_VALUE
  } else {
    const values = Utils.getAuditTrailDiffs(object, [field])
    if (Utils.noChanges(values)) {
      return <div />
    }
    newValue = values.valueNew[0] ? values.valueNew[0] : NO_VALUE
    oldValue = values.valueOld[0] ? values.valueOld[0] : NO_VALUE
  }
  oldValue = convertTimestamp(oldValue)
  newValue = convertTimestamp(newValue)

  oldValue = convertBoolean(oldValue)
  newValue = convertBoolean(newValue)

  oldValue = getPortName(oldValue)
  newValue = getPortName(newValue)

  oldValue = getStatusDesc(oldValue)
  newValue = getStatusDesc(newValue)

  if (Utils.objectsEqual(newValue, oldValue)) return <div />

  function getStatusDesc(value) {
    console.log('getStatusDesc')
    let matched
    if (status && status.length > 0 && value && value != NO_VALUE) {
      matched = status.find((p) => p.id === value)
    }
    return matched ? matched.name : value
  }

  function getPortName(value) {
    let matched
    if (ports && ports.length > 0 && value && value != NO_VALUE) {
      matched = ports.find((p) => p.code === value)
    }
    return matched ? matched.descriptionEn : value
  }

  function concatUom(value) {
    if (uom && value && value != NO_VALUE) {
      return value + uom
    }
    return value
  }

  function convertTimestamp(value) {
    if (timestamp && value && value != NO_VALUE) {
      //let date = new Date(value)
      return Utils.formatDate(value, Utils.FORMAT_DATE_TIME)
    }
    return value
  }
  function convertBoolean(value) {
    if (value === true) {
      return checkbox ? 'Checked' : 'Yes'
    } else if (value === false) {
      return checkbox ? 'Unchecked' : 'No'
    }
    return value
  }

  return (
    <StyledTableRow>
      <StyledTableCell>
        {group && (
          <>
            <b>{group}</b>
            <span class='blackDot'></span>
            {subGroup && (
              <>
                <b>{subGroup}</b>
                <span class='blackDot'></span>
              </>
            )}
            {label}: &nbsp;
          </>
        )}

        {!group && (
          <>
            <b>{label}</b>: &nbsp;
          </>
        )}

        <span style={oldValue === NO_VALUE ? styleNovalue : styleOld}>
          {oldValue}
        </span>
        {' > '}
        <span style={newValue === NO_VALUE ? styleNovalue : styleNew}>
          {newValue}
        </span>
      </StyledTableCell>
    </StyledTableRow>
  )
}

export default AuditTrailRowOfChange
