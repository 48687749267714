import React from 'react'
import { CircularProgress, Button, Box } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

function GreenSubmitButton({
    children,
    disabled,
    ...props
}) {

    const { formState: { isSubmitting } } = useFormContext()

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const submitText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.SUBMIT);

    return (
        <Button
            className='button-light-green originalText'
            type='submit'
            disabled={isSubmitting || disabled}
            {...props}
        >
            {children || submitText}
            {isSubmitting && (
                <Box mx={0.5}>
                    <CircularProgress color='primary' size={10} />
                </Box>
            )}
        </Button>
    )
}

export default GreenSubmitButton
