import React, { useState } from 'react'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeLight)

function KebabMenuButton({
    hasEdit = false,
    isEditDisabled = false,
    onEditClick = () => { /* Do nothing. */ },

    hasDelete = false,
    isDeleteDisabled = false,
    onDeleteClick = () => { /* Do nothing. */ },

    hasDownload = false,
    isDownloadDisabled = false,
    onDownloadClick = () => { /* Do nothing. */ },

    iconProps = {},
    menuProps = {},
    ...props
}) {

    const [anchorElement, setAnchorElement] = useState(null);
    const open = Boolean(anchorElement);

    const menuClickHandler = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const menuCloseHandler = () => {
        setAnchorElement(null);
    };

    function _handleMenuItemClick(parentClickHandler) {
        if (parentClickHandler) {
            parentClickHandler()
        }

        setAnchorElement(null);
    }

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const editText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.EDIT);
    const deleteText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.DELETE);
    const downloadText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.DOWNLOAD);

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <div {...props}>
            <IconButton onClick={menuClickHandler} {...iconProps}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorElement}
                getContentAnchorEl={null}
                keepMounted
                open={open}
                onClose={menuCloseHandler}
                {...menuProps}
            >
                {
                    (hasEdit) &&
                    (
                        <MenuItem disabled={isEditDisabled}
                            onClick={() => { _handleMenuItemClick(onEditClick) }}
                        >
                            <FontAwesomeIcon icon={['fal', 'edit']} style={{ marginRight: '0.5em' }} />
                            {editText}
                        </MenuItem>
                    )
                }
                {
                    (hasDelete) &&
                    (
                        <MenuItem disabled={isDeleteDisabled}
                            onClick={() => { _handleMenuItemClick(onDeleteClick) }}
                        >
                            <FontAwesomeIcon icon={['fal', 'trash']} style={{ marginRight: '0.5em' }} />
                            {deleteText}
                        </MenuItem>
                    )
                }
                {
                    (hasDownload) &&
                    (
                        <MenuItem disabled={isDownloadDisabled}
                            onClick={() => { _handleMenuItemClick(onDownloadClick) }}
                        >
                            <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} style={{ marginRight: '0.5em' }} />
                            {downloadText}
                        </MenuItem>
                    )
                }
            </Menu>
        </div>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default KebabMenuButton
