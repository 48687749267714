const TransactionLogKeys = {
  TITLE: "title",
  PROCESS_CLASS: "processClass",
  SOURCE: "source",
  DESTINATION: "destination",
  FILE_NAME: "fileName",
  FILE_CONTENT: "fileContent",
  FILE_SIZE: "fileSize",
  FILE_CREATION_DATE: "fileCreationDate",
  SERVER_FILE_PATH: "serverFilePath",
  TRANSFER_TYPE: "transferType",
  TRANSFER_MODE: "transferMode",
  PROCESS_STATUS: "processStatus",
  STATUS_DETAIL: "statusDetail",
  START_TIME: "startTime",
  END_TIME: "endTime",
  TRANSACTION_ID: "transactionId",
  DOCKEY: "dockey",
  RETRY: "retry",
  CREATED_DATE: "createdDate"
}

export default TransactionLogKeys
