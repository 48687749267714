import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'
import Utils from 'src/views/common/utils/Utils'
import {
    Chip
} from '@material-ui/core'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import useFetchCodeMaintainance from 'src/views/common/serviceHook/useFetchCodeMaintainance'

const {
    filter: {
        EQUAL
    }
} = constants

const CodeMasterMiscViewField = (props) => {

    const { fetchRecords } = useServices()
    const [codeMaster, setCodeMaster] = useState()
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {
        if (!Utils.isEmpty(props.code)) {
            CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, props.codeType,
                [{
                    field: 'code',
                    operator: EQUAL,
                    value: props.code
                }], false, onLoadPortSuccess)
        }

    }, [props])


    function onLoadPortSuccess(data) {

        if (data.length > 0) {
            console.log("not null")
            setCodeMaster(data[0])
        }

        setShouldRender(true)
    }

    if (!shouldRender) {
        return null;
    }

    //console.log('CodeMasterMiscViewField' + JSON.stringify(codeMaster))

    return (

        <div>
            {codeMaster != null ? codeMaster.descriptionEn : "-"}
        </div>

    )
}

export default CodeMasterMiscViewField