import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core'
import { LogOut, PlusCircle, Printer, Search, X } from 'react-feather'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CancelButton from 'src/components/button/CancelButtonWithIcon'
import DeleteButton from 'src/components/button/DeleteButton'
import ExportButton from 'src/components/button/ExportButton'
import ExportComponent from './ExportComponent'
import PrintButton from 'src/components/button/PrintButton'
import React from 'react'
import ViewColumnComponent from './ViewColumnComponent'
import clsx from 'clsx'
import { components } from 'cng-web-lib'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const {
  button: { CngPrimaryButton },
  CngGridItem
} = components

const toolbarStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: '-.5em !important',
    paddingLeft: '0 !important'
  },
  selectionText: {
    marginTop: '0 !important',
    paddingLeft: '0 !important',
    fontSize: '.85em !important'
  },
  search: {
    '& .MuiInput-underline': {
      '&:before, &:after': {
        border: '0 !important'
      }
    },
    '& .MuiIconButton-root': {
      marginRight: '-.65em'
    }
  },
  selectButtons: {
    padding: `5px ${theme.spacing(3)}px`
  },
  buttonIcons: {
    '& .svg-inline--fa': { fontSize: 'inherit' }
  },
  menu: {
    '& .MuiMenu': {
      '&-paper': { borderRadius: 10 },
      '&-list': {
        maxWidth: '60vw',
        padding: 2,
        '& .MuiListItem-root:not(.Mui-disabled)': {
          letterSpacing: 0.21,
          fontSize: '.9em',
          padding: '8px 10px',
          minWidth: '12em',
          '&:hover': {
            backgroundColor:
              theme.palette.component.popoverMenu.listItemHoverFill
          }
        }
      }
    },
    '& .ng-mattbl-popover-checkbox': {
      padding: 0,
      paddingRight: theme.spacing(1)
    },
    '& .ng-mattbl-popover-title': {
      backgroundColor:
        theme.palette.component.textFormControl.dropdownMenuSelectedFill,
      borderRadius: 8,
      fontWeight: 900,
      color: theme.palette.text.navActive,
      padding: '10px 8px',
      marginBottom: theme.spacing(0.5)
    }
  }
}))

export default function CngToolbarTable(props) {
  const toolbarClasses = toolbarStyles()
  const translatedTextsObject = CalistaUiComponentTranslationText()
  const history = useHistory()

  return (
    <Toolbar>
      {props.data.filter((item) => item.checked === true).length > 0 ? (
        <Grid container='true' item md={12} alignItems='center'>
          <CngGridItem xs={12}>
            <Box display='flex' alignItems='center'>
              <Box flexGrow={1}>
                <Typography
                  className={props.classes.title}
                  color='inherit'
                  variant='subtitle1'
                  component='div'
                >
                  {props.data.filter((item) => item.checked === true).length}{' '}
                  {translatedTextsObject.itemsSelected}
                </Typography>
              </Box>
              <Box>
                <Box display='flex' alignItems='center'>
                  <Box mr={1}>
                    <CancelButton
                      onClick={props.onCancel}
                      variant='outlined'
                      classes={{
                        root: clsx(
                          toolbarClasses.selectButtons,
                          'ng-button-outline'
                        ),
                        iconSizeSmall: clsx(toolbarClasses.buttonIcons),
                        iconSizeMedium: clsx(toolbarClasses.buttonIcons),
                        iconSizeLarge: clsx(toolbarClasses.buttonIcons)
                      }}
                    />
                  </Box>
                  {/* <Box mr={1}>
                    <PrintButton
                      variant='outlined'
                      classes={{
                        root: clsx(
                          toolbarClasses.selectButtons,
                          'ng-button-outline'
                        ),
                        iconSizeSmall: clsx(toolbarClasses.buttonIcons),
                        iconSizeMedium: clsx(toolbarClasses.buttonIcons),
                        iconSizeLarge: clsx(toolbarClasses.buttonIcons)
                      }}
                    />
                  </Box> */}
                  <Box mr={1}>
                    <ExportComponent
                      showNotification={props.showNotification}
                      toolbarClasses={toolbarClasses}
                      columns={props.columns}
                      data={props.data}
                      dataType={props.dataType}
                      multiSelect={true}
                      user={props.user}
                      userProfile={props.userProfile}
                    />
                  </Box>
                  {!props.viewOnly ? (
                    <Box>
                      <DeleteButton
                        onClick={props.onOpenConfirmDialog}
                        variant='outlined'
                        classes={{
                          root: clsx(
                            toolbarClasses.selectButtons,
                            'ng-button-outline'
                          ),
                          iconSizeSmall: clsx(toolbarClasses.buttonIcons),
                          iconSizeMedium: clsx(toolbarClasses.buttonIcons),
                          iconSizeLarge: clsx(toolbarClasses.buttonIcons)
                        }}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </CngGridItem>
        </Grid>
      ) : props.openSearchPanel ? (
        <TextField
          value={props.search}
          onChange={props.onSearchChange}
          className={clsx(toolbarClasses.search)}
          id='input-search'
          // placeholder={translatedTextsObject.searchDesc}
          placeholder={props.searchDesc}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search color='#8282a6' size='16px' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  color={'default'}
                  onClick={props.onCloseSearchPanel}
                >
                  <X color='#8282a6' size='16px' aria-label='clear' />
                </IconButton>
              </InputAdornment>
            ),
            'aria-label': 'naked'
          }}
        />
      ) : (
        <Grid container='true' item md={12} alignItems='center'>
          <CngGridItem xs={12}>
            <Box display='flex' alignItems='center'>
              <Box flexGrow={1}>
                <Typography variant='body2'>{`${Math.min(
                  props.page * props.rowsPerPage + 1,
                  props.data.length
                )}-${Math.min(
                  (props.page + 1) * props.rowsPerPage,
                  props.data.length
                )} of ${props.data.length}`}</Typography>
              </Box>
              <Box>
                <Box display='flex' alignItems='center'>
                  {!props.disableSearch ? (
                    <Box mr={1}>
                      <Tooltip title='Search' placement='bottom'>
                        <IconButton
                          color={'default'}
                          onClick={props.onClickSearchPanel}
                        >
                          <Search size='16px' />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : null}
                  <Box mr={1}>
                    <ExportComponent
                      showNotification={props.showNotification}
                      toolbarClasses={toolbarClasses}
                      columns={props.columns}
                      multiSelect={false}
                      data={props.data}
                      dataType={props.dataType}
                      user={props.user}
                      userProfile={props.userProfile}
                    />
                  </Box>
                  {/* <Box mr={1}>
                    <Tooltip title='Print' placement='bottom'>
                      <IconButton color={'default'}>
                        <Printer size='16px' />
                      </IconButton>
                    </Tooltip>
                  </Box> */}
                  <Box mr={1}>
                    <ViewColumnComponent
                      columns={props.columns}
                      onChange={props.onCheckBoxViewColumnChange}
                      toolbarClasses={toolbarClasses}
                    />
                  </Box>
                  {!props.viewOnly ? (
                    <Box ml={1}>
                      {/* <CngPrimaryButton
                      onClick={() =>
                        history.push(pathMap.TCAL_VS_MANAGE_VESSELS_ADD)
                      }
                    >
                      <PlusCircle style={{ marginRight: 5 }} />
                      {translatedTextsObject.addVessels}
                    </CngPrimaryButton> */}
                      <CngPrimaryButton onClick={props.onAddButtonClick}>
                        <PlusCircle style={{ marginRight: 5 }} />
                        {props.addButtonText}
                      </CngPrimaryButton>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </CngGridItem>
        </Grid>
      )}
    </Toolbar>
  )
}
