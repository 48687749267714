const TmpComponentKeys = {

	//------------COMPONENT------------------
	TMP_COMPONENT: "tmpComponent",
	TMP_COMPONENT_TITLE: "tmpComponentTitle",
	TMP_COMPONENT_CREATE: "tmpComponentCreate",
	TMP_COMPONENT_EDIT: "tmpComponentEdit",
	TMP_COMPONENT_VIEW: "tmpComponentView",
	TMP_COMPONENT_NO: "tmpComponentNumber",
	TMP_COMPONENT_NAME: "tmpComponentName",
	TMP_COMPONENT_CUSTOM_TARIFF: "tmpComponentCustomTariff",
	TMP_COMPONENT_QTY: "tmpComponentQty",
	TMP_COMPONENT_UOM: "tmpComponentUom",
	TMP_COMPONENT_MATERIAL_TYPE: "tmpComponentMateriaType",
	TMP_COMPONENT_ORI_CTRY: "tmpComponentOriCountry",
	TMP_COMPONENT_LTSD: "tmpComponentltsd",
	TMP_COMPONENT_LTSD_START: "tmpComponentltsdStartDate",
	TMP_COMPONENT_LTSD_END: "tmpComponentltsdEndDate",
	TMP_COMPONENT_STANDARD_PRICE: "tmpComponentStandardPrice",
	TMP_COMPONENT_SUPPLIER: "tmpComponentSupplier",
	TMP_COMPONENT_CREATE_SUPPLIER: "tmpComponentCreateSupplier"

}

export default TmpComponentKeys