import {
  Box,
  StepIcon,

  makeStyles
} from '@material-ui/core'
import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const ICON_SIZE = 36
const useStyles = makeStyles(theme => ({
  icon: {
    height: ICON_SIZE, width: ICON_SIZE,
    textAlign: 'center',
    color: theme.palette.background.stepperNormalFill,
    borderRadius: '20em'
  },
  iconActive: { color: theme.palette.primary.main },
  iconText: {
    fontSize: ICON_SIZE / theme.typography.fontSize * 3.5,
    transform: 'translateY(-1px)'
  },
  iconComplete: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& svg': { marginTop: '.5em' }
  }
}))

function CngStepIcon(props) {
  const classes = useStyles()

  const calcProps = useMemo(() => {
    let ret = {...props}
    if (props.completed)
      ret.icon = <Box className={clsx(
        'MuiSvgIcon-root MuiStepIcon-root',
        classes.icon, classes.iconComplete
      )}>
        <FontAwesomeIcon icon={['fal', 'check']} fixedWidth size='xs' />
      </Box>
    return ret
  }, [props])

  return <StepIcon
    classes={{
      root: classes.icon,
      active: classes.iconActive,
      text: classes.iconText
    }}
    {...calcProps}
  />
}

export default CngStepIcon
