import { Box, Card, Chip, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import React from 'react'
import PortViewField from 'src/components/field/PortViewField'
import Utils from 'src/views/common/utils/Utils'
const {
    CngGridItem
  } = components

const AuditTrailDualColumnPort = (props) => {
    const {
        label,
        object,
        field,
        added,
        removed,
        ports
    } = props
    const NO_VALUE = 'No values'
    let oldValue
    let newValue

    if(field===null || field === undefined) return <div/>

    if(added) {
        oldValue = NO_VALUE
        newValue = field
    } else if (removed) {
        oldValue = field
        newValue = NO_VALUE
    } else {
        const values = Utils.getAuditTrailDiffs(object, [field])
        if(Utils.noChanges(values)) {
            return <div/>
        }
        newValue = values.valueNew[0] ? values.valueNew[0] : NO_VALUE
        oldValue = values.valueOld[0] ? values.valueOld[0] : NO_VALUE
    }
    return (
        <Grid container xs={12} sm={12} spacing={0}>
                    <CngGridItem xs={6} sm={6}>
                        {
                            oldValue===NO_VALUE && 
                                        <LabelValueVertical label={label}
                                                value={oldValue} 
                                                valueStyle={'greyLabel paddingLeft16'} />
                        }
                        {
                            oldValue!==NO_VALUE && (
                                <Box className="view-card-content">
                                    <Box className="view-card-content-label"> {label} </Box>
                                    <Box ml={2}>
                                        <PortViewField ports={ports} portCode={oldValue} />
                                    </Box>
                                </Box>    
                            )
                        }
                         </CngGridItem>
                    <CngGridItem xs={6} sm={6}>
                        {
                            newValue===NO_VALUE && 
                                        <LabelValueVertical label={label}
                                                value={newValue} 
                                                valueStyle={'greyLabel paddingLeft16'} />
                        } 
                        {
                            newValue!==NO_VALUE && (
                                <Box className="view-card-content">
                                    <Box className="view-card-content-label"> {label} </Box>
                                    <Box ml={2}>
                                        <PortViewField ports={ports} portCode={newValue} />
                                    </Box>
                                </Box>    
                            )
                        }
                    </CngGridItem>
        </Grid>            
    )
}

export default AuditTrailDualColumnPort
