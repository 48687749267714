const SequenceKeys = {
  TITLE: "title",
  NAME: "name",
  PATTERN: "pattern",
  LAST_KEY: "lastKey",
  MIN_VALUE: "minValue",
  MAX_VALUE: "maxValue",
  INCREMENT_BY: "incrementBy",
  CYCLE: "cycle",
  MODULE_NAME: "moduleName",
}

export default SequenceKeys
