const BatchDataConfigKeys = {
  TITLE: "title",
  CONFIG_CODE: "configCode",
  CONFIG_VALUE1: "configValue1",
  CONFIG_VALU2: "configValu2",
  CONFIG_VALU3: "configValu3",
  CONFIG_DESCRIPTION: "configDescription"
}

export default BatchDataConfigKeys
