import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'

import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'

export default function CngTableHeader(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()

  return (
    <TableHead>
      <TableRow>
      {!props.viewOnly ? (
        <TableCell padding='checkbox'>
          <Checkbox
            color="primary"
            indeterminate={
              props.data.filter((item) => item.checked === true).length >
                0 &&
              props.data.filter((item) => item.checked === true).length <
                props.data.length
            }
            checked={
              props.data.filter((item) => item.checked === true)
                .length === props.data.length
            }
            onChange={props.onHeaderCheck}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
      ) : null}
        <TableCell className={'vs-table-cell-padding'}>
          <div className={'rs-grey'}>{translatedTextsObject.seqNo.toUpperCase()}</div>
        </TableCell>
        {props.columns.map((column, index) =>
          column.show ? (
            <TableCell
              key={column.title + index}
              className={'vs-table-cell-padding'}
            >
              <TableSortLabel
                active={column.active}
                direction={column.order}
                onClick={props.onSortClick(column.field)}
              >
                <div className={'rs-grey'}>{column.title}</div>
              </TableSortLabel>
            </TableCell>
          ) : null
        )}
      {!props.viewOnly ? (
        <TableCell className={'vs-table-cell-padding'}>
          <div className={'rs-grey'}>{translatedTextsObject.action}</div>
        </TableCell>
      ): null}
      </TableRow>
    </TableHead>
  )
}
