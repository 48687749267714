import React from 'react'
import { Container, Typography } from '@material-ui/core'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

// FontAwesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)

function ErrorDeletingDataLabel({
    style = {},
    ...props
}) {

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const errorDeletingDataText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.ERROR_DELETING_DATA);
    const contactAdminText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.CONTACT_ADMIN);

    return (
        <Container
            style={{
                display: 'flex',
                alignItems: 'center',
                ...style
            }}
            {...props}
        >
            <FontAwesomeIcon
                icon={['far', 'exclamation-circle']}
                style={{ width: '2em', height: '2em', color: 'red' }}
            />
            <Container>
                <Typography>{errorDeletingDataText}</Typography>
                <Typography>{contactAdminText}</Typography>
            </Container>
        </Container>
    )
}

export default ErrorDeletingDataLabel
