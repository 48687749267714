const GeneratedReportKeys = {
    GENERATED_REPORT_ID: "Generated Report Id",
    TITLE: "Generated Report",
    PARTY: "Party",
    REPORT_NAME: "Report Name",
    SCHEDULE_NAME: "Schedule Name",
    REPORT: "Report",
    CREATED_DATE: "Created Date",
    ACTION: "Action",
    USERS: "Users"
    // ACCESS_LEVEL_MASTER_CODE: "accessLevelMasterCode",
    // DATE_RANGE_MAX_LIMIT: "dateRangeMaxLimit",
    // RESULT_MAX_LIMIT: "resultMaxLimit"
}

export default GeneratedReportKeys