export const BOOKING_STATUS_PENDING_APPROVAL = '5101'
export const BOOKING_STATUS_APPROVED = '5102'
export const BOOKING_STATUS_REJECTED = '5103'
export const BOOKING_STATUS_CANCELLED = '5104'
export const BOOKING_STATUS_AMENDED = '5105'
export const BOOKING_STATUS_CLOSED = '5106'
export const BOOKING_STATUS_DRAFT = '5107'

export const MOVE_TYPE_PORT_TO_PORT = 'PTP'
export const MOVE_TYPE_PORT_TO_DOOR = 'PTD'
export const MOVE_TYPE_DOOR_TO_DOOR = 'DTD'
export const MOVE_TYPE_DOOR_TO_PORT = 'DTP'

export const FULL_CONTAINER_LOAD = 'FCL'
export const CONTAINER_ISO_TYPE_22G1 = '22G1'

export const SCHEDULE_WAYPOINT_START = '1'
export const SCHEDULE_WAYPOINT_STOPOVER = '2'
export const SCHEDULE_WAYPOINT_FINAL = '3'

export const TRANSPORT_MODE_SEA = 'SEA'
export const TRANSPORT_MODE_AIR = 'AIR'
export const TRANSPORT_MODE_RAIL = 'RAIL'
export const TRANSPORT_MODE_TRUCK = 'TRUCK'
export const TRANSPORT_MODE_FEEDER = 'FEEDER'
export const TRANSPORT_MODE_LAND = 'LAND'

export const CAL_DG_IMO = 'CAL_DG_IMO'
export const CAL_DG_TEMP_UOM = 'CAL_DG_TEMP_UOM'
export const CAL_DG_MARINE = 'CAL_DG_MARINE'
export const CAL_DG_PSN_INH_HZD_ZN = 'CAL_DG_PSN_INH_HZD_ZN'
export const CAL_DG_AGGRE = 'CAL_DG_AGGRE'
export const CAL_DG_MPA_CLASS = 'CAL_DG_MPA_CLASS'
export const CAL_DG_PKG_TYPE = 'CAL_DG_PKG_TYPE'
export const CAL_CONT_STATUS = 'CAL_CONT_STATUS'
export const CAL_BK_MOVE_TYPE = 'CAL_BK_MOVE_TYPE'
export const CAL_CONT_CAR_STS = 'CAL_CONT_CAR_STS'
export const CAL_BL_TYPE = 'CAL_BL_TYPE'
export const CAL_FORM_TYPE = 'CAL_FORM_TYPE'
export const CAL_CONT_STOWAGE = 'CAL_CONT_STOWAGE'
export const CAL_CONT_HANDLING = 'CAL_CONT_HANDLING'
export const CAL_CARGO_LENGTH = 'CAL_CARGO_LENGTH'
export const CAL_CARRIER_CLAUSE = 'CAL_CARRIER_CLAUSE'
export const CAL_REEFER_TEMP_UOM = 'CAL_REEFER_TEMP_UOM'
export const CAL_REEFER_VENT_SETTING = 'CAL_REEFER_VENT_SETTING'

export const REEFER_TEMP_CEL = 'CEL'
export const REEFER_TEMP_FAH = 'FAH'

export const SI_STATUS_PENDING_SUBMISSION = '5201'
export const SI_STATUS_PENDING_CARRIER_CONFIRMATION = '5202'
export const SI_STATUS_SI_APPROVED = '5203'
export const SI_STATUS_PENDING_SHIPPER_CONFIRMATION = '5209'
export const SI_STATUS_SI_CONFIRMED = '5210'
export const SI_STATUS_PENDING_SI_APPROVED = '5211'
export const SI_STATUS_PENDING_SI_RESUBMISSION = '5212'
export const SI_STATUS_SI_CANCELLED = '5213'
export const SI_STATUS_BL_ISSUED = '5301'
export const SI_STATUS_BL_SURRENDERED = '5302'
export const SI_STATUS_BOOKING_CANCELLED = '5208'

export const PERMIT_STATUS_PENDING_SUBMISSION = '5401'
export const PERMIT_STATUS_CREATED = '5402'
export const PERMIT_STATUS_SUBMITTED = '5403'
export const PERMIT_STATUS_APPROVED = '5404'
export const PERMIT_STATUS_REJECTED = '5405'
export const PERMIT_STATUS_AMENDMENT_CREATED = '5406'
export const PERMIT_STATUS_AMENDMENT_SUBMITTED = '5407'

export const EVGM_STATUS_NEW_VGM_CREATED = 'NEW'
export const EVGM_STATUS_PENDING_ACCEPTANCE = 'SMT'
export const EVGM_STATUS_PENDING_UPDATE = 'UPDATE'
export const EVGM_STATUS_CANCELLATION_RECEIVED = 'CSM'
export const EVGM_STATUS_PENDING_CANCELLATION = 'CNL'
export const EVGM_STATUS_ACCEPTED = 'ACT'
export const EVGM_STATUS_REJECTED = 'RJT'
export const EVGM_STATUS_DRAFT = 'DRAFT'

export const MODULE_FRB = 'CLOGS_FRB'
export const MODULE_SI = 'CLOGS_SI'
export const MODULE_EDI = 'CLOGS_EDI'
