const TmpSupplierKeys = {

	//------------SUPPLIER------------------
	TMP_SUPPLIER: "tmpSupplier",
	TMP_SUPPLIER_TITLE: "tmpSupplierTitle",
	TMP_SUPPLIER_CREATE: "tmpSupplierCreate",
	TMP_SUPPLIER_EDIT: "tmpSupplierEdit",
	TMP_SUPPLIER_VIEW: "tmpSupplierView",
	TMP_SUPPLIER_NAME: "tmpSupplierName"

}

export default TmpSupplierKeys