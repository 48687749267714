const EcoCalculatorKeys = {
    MODULE_TITLE: "module-title",
    SUB_MODULE_TITLE: "sub-module-title",
    MODULE_ID: "moduleId",
    MENU_ID: "menuId",
    NAME: "name",
    CODE: "code",
    PARENT_ID: "parentId",
    ACTIVE: "active"
  }
  
  export default EcoCalculatorKeys