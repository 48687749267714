const FreightBookingValidationKeys = {
  VALUE_TO_SMALL: 'valueTooSmallMsg',
  NUMBER_ERROR: 'numberErrorMsg',
  NUMERIC_ERROR: 'numericErrorMsg',
  INVALID_STRING: 'invalidStrMsg',
  INVALID_ADDRESS: 'invalidAddressMsg',
  INVALID_BLANK_SPACE: 'invalidBlankSpaceMsg',
  INVALID_HSCODE: 'invalidHSCode',
  VALUE_TOO_BIG: 'valueTooBigMsg',
  INTEGER_ONLY: 'IntegerOnlyMsg',
  INVALID_PHONE: 'invalidPhoneMsg',

  POR_POD_SAME: 'PorPoDSameMsg',
  POL_POD_SAME: 'PolPodSameMsg',
  CARGO_READY_DATE_BEFORE_TODAY: 'CargoReadyDateBeforeTodayMsg',
  PoR_ETD_BEFORE_TODAY: 'PoRETDBeforeTodayMsg',
  POL_ETA_BEFORE_TODAY: 'POLETABeforeTodayMsg',
  CARGO_DELIVERY_DATE_BEFORE_CARGO_READY_DATE:
    'CargoDeliveryDateBeforeCargoReadyDateMsg',
  POL_ETD_BEFORE_POL_ETA: 'POLETDBeforePOLETAMsg',
  POD_ETA_BEFORE_CARGO_READY_DATE: 'PODETABeforeCargoReadyDateMsg',
  POD_ETA_BEFORE_POL_ETD: 'PODETABeforePOLETDMsg',
  POD_ETD_BEFORE_CARGO_READY_DATE: 'PODETDBeforeCargoReadyDateMsg',
  POD_ETD_BEFORE_POL_ETD: 'PODETDBeforePOLETDMsg',
  POD_ETD_BEFORE_POD_ETA: 'PODETDBeforePODETAMsg',
  POD_ETA_AFTER_CARGO_DELIVERY_DATE_MSG: 'PODETAAfterCargoDeliveryDateMsg',
  FRB_DATE_TYPE_ERROR: 'frbDateTypeError',
  FRB_DATE_TIME_TYPE_ERROR: 'frbDateTimeTypeError',
  FOUR_DIGIT_ONLY: 'fourDigitOnly',
  POD_ETA_LATER_THAN_CARGO_DELIVERY_DATE_MSG:
    'PODETAlaterThanCargoDeliveryDateMsg',
  POL_ETD_EARLIER_THAN_CARGO_READY_DATE_MSG:
    'POLETDEarlierThanCargoReadyDateMsg',
  INVALID_EMAIL_LIST: 'invalidEmailListMsg',
  EXCEED_EMAIL_LIST_LENGTH: 'exceedEmailListLengthMsg',
  EXCEED_EMAIL_MAX_LENGTH: 'exceedEmailMaxLength'
}
export default FreightBookingValidationKeys
