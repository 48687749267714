import React from 'react'
import {
  Box,
  Grid
} from '@material-ui/core'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import { faBiohazard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Hazardous(props) {
  const translatedTextsObject = FreightBookingTranslationText()
  const skipText = props.skipText

  return <Grid container xs direction='row' justify='flex-end' alignItems='center'
    className={'ng-button-filled-secondary2'}>
    <FontAwesomeIcon icon={faBiohazard} color="blue" />
    {!skipText && (<Box>&nbsp;{translatedTextsObject.hazardous}</Box>)}

  </Grid>
}

export default Hazardous
