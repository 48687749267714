import React from 'react'
import Box from '@material-ui/core/Box'
import { components } from 'cng-web-lib'

const { button: { CngIconButton } } = components

const QuickScroll = () => {

    const scrollToTop = () => {
        const boxComp = document.getElementById('top-level-box')
        if (boxComp) {
            boxComp.scrollIntoView({ behavior: 'smooth' })
        }
    }
    return (
        <Box>
            <CngIconButton
                style={{ position: 'fixed', right: '5%', bottom: '15%', zIndex: '10' }}
                icon={['fa-solid', 'fa-up']}
                type='primary'
                onClick={scrollToTop}
                size='medium'
                draggable="true"
            />
        </Box>
    )
}
export default QuickScroll