import { Box, Chip } from '@material-ui/core'

import React from 'react'
import CodeMaintainanceApiUrls from 'src/apiUrls/CodeMaintainanceApiUrls.js'
import { components, constants } from 'cng-web-lib'
import makeStyles from '@material-ui/styles/makeStyles'

const {
  form: {
      adapter: {
          useFormAdapter:{ useField, useFormikContext }
      },
    field: { CngLookupAutocompleteField }
  }
} = components


const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {
    EQUAL
  }
} = constants

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

function DGPkgUOMAutocompleteField({
  name,
  label,
  disabled,
  ...otherCngLookupAutocompleteFieldProps
}) {

  const [field] = useField(name)
  return (
    <CngLookupAutocompleteField
      name={name}
      label={label}
      lookupProps={{
        // label: 'containerIsoType',
        url: CodeMaintainanceApiUrls.MAINTAINANCE_CODE,
        //  value: 'isoCode'
        label: 'descriptionEn',
        value: 'code',
        customData: { codeMType: "CodeMasterCode" },
        filters: [
          {
            field: 'codeType',
            operator: EQUAL,
            value: "CAL_DG_PKG_TYPE"
          },
          {
            field: 'active',
            operator: EQUAL,
            value: true
          },

        ],
      }}
      renderOption={(option) => {
        return (
          <Box display='flex'>
            <Chip label={<b>{option.value}</b>} size='small' />
            <Box ml={0.5}>{option.label}</Box>
          </Box>
        )
      }}
      textFieldProps={{
        InputProps: {
          customStartAdornment: (autocompleteStartAdornment, options) => {
            const { value } = field
            const matched = options
              .map((option) => option.data)
              .find((container) => container?.iso_code === value)

            if (!matched && value) {
              return (
                <Box>
                  <div>
                    <Chip label={<b>{value}</b>} size='small' />
                  </div>
                </Box>
              )
            }

            return autocompleteStartAdornment

          }
        }
      }}
      disabled={disabled}
      {...otherCngLookupAutocompleteFieldProps}
    />
  )
}

export default DGPkgUOMAutocompleteField
