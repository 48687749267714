const SCOAdminKeys = {
 
 CODE_MASTER_TITLE: "codeMasterTitle",
 CODE_MASTER_CODE: "codeMasterCode",
 CODE_MASTER_CREATE: "codeMasterCodeCreate",
 CODE_MASTER_CODETYPE: "codeMasterCodeType",
 CODE_MASTER_STATUS : "codeMasterStatus",
 CODE_MASTER_DESCRIPTION :"codeMasterDescription",
 CODE_MASTER_NAME:"codeMasterName",
 CODE_MASTER_EDIT:"codeMasterEdit",
 CODE_MASTER_VIEW:"codeMasterView",
 CODE_MASTER_SEQEUNCE:"codeMasterSequence",
 CODE_MASTER_ALPHA3: "codeMasterAlpha3",
 COUNTRY_CODE_TITLE: "countryCodeTitle",
 COUNTRY_CODE: "countryCode",
 COUNTRY_NAME :"countryName",
 COUNTRY_ALPHA3 : "countryAlpha3",
 COUNTRY_STATUS:"countryStatus",
 COUNTRY_VIEW:"countryView",
 TIMEZONE_TITLE: "timezoneTitle",
 TIMEZONE_NAME : "timezoneName",
 TIMEZONE_VALUE: "timezoneValue",
 TIMEZONE_OFFSET_STRING: "timezoneOffsetString",
 TIMEZONE_OFFSET_SECONDS: "timezoneOffsetSeconds",
 TIMEZONE_STATUS:"timezoneStatus",
 COUNTRY_STATE_CODE_TITLE: "countryStateCodeTitle",
 COUNTRY_STATE_CODE: "countryStateCode",
 COUNTRY_STATE_NAME:"countryStateName",
 COUNTRY_STATE_STATUS:"countryStateStatus",
 STATE_CODE_TABLE:"stateCodeTable",
 PRINCIPAL_MAPPING: "inventoryPrincipalMapping",
 PRINCIPAL_MAPPING_DETIALS: "inventoryPrincipalMappingDetails"


}

export default SCOAdminKeys