import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: { CngPrimaryButton }
} = components

function SubmitButton({
  children,
  disabled,
  isSubmitting,
  ...otherButtonProps
}) {
  return (
    <CngPrimaryButton
      type='submit'
      disabled={isSubmitting || disabled}
      {...otherButtonProps}
    >
      {children}
      {isSubmitting && (
        <Box mx={0.5}>
          <CircularProgress color='primary' size={10} />
        </Box>
      )}
    </CngPrimaryButton>
  )
}

export default SubmitButton
