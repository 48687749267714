const CecPostcodeConfigKeys = {

	//------------UOM Conversion------------------
	CEC_POSTCODE_CONFIG: "cecPostcodeConfig",
	CEC_POSTCODE_CONFIG_TITLE: "cecPostcodeConfigTitle",
	CEC_POSTCODE_CONFIG_CREATE: "cecPostcodeConfigCreate",
	CEC_POSTCODE_CONFIG_EDIT: "cecPostcodeConfigEdit",
	CEC_POSTCODE_CONFIG_VIEW: "cecPostcodeConfigView",
	CEC_POSTCODE_CONFIG_COUNTRY: "cecPostcodeConfigCountry",
	CEC_POSTCODE_CONFIG_REGEX: "cecPostcodeConfigRegex"

}

export default CecPostcodeConfigKeys