import { Box, Card, Chip, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import Utils from 'src/views/common/utils/Utils'
import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
const { CngGridItem } = components

const AuditTrailDualColumn = (props) => {
  const {
    label,
    object,
    field,
    uom,
    added,
    removed,
    checkbox,
    timestamp,
    match,
    codeType,
    codeMasterList,
    array
  } = props
  const NO_VALUE = 'No values'

  if (object === null || object === undefined) return <div />

  let oldValue
  let newValue
  if (added) {
    oldValue = NO_VALUE
    newValue = Utils.isNotEmpty(object[field]) ? object[field] : NO_VALUE
  } else if (removed) {
    oldValue = Utils.isNotEmpty(object[field]) ? object[field] : NO_VALUE
    newValue = NO_VALUE
  } else {
    const values = Utils.getAuditTrailDiffs(object, [field])
    // if (Utils.noChanges(values)) {
    //   return <div />
    // }
    newValue = Utils.isNotEmpty(values.valueNew[0])
      ? values.valueNew[0]
      : NO_VALUE
    oldValue = Utils.isNotEmpty(values.valueOld[0])
      ? values.valueOld[0]
      : NO_VALUE
  }

  if (Utils.objectsEqual(newValue, oldValue)) return <div />

  oldValue = convertTimestamp(oldValue)
  newValue = convertTimestamp(newValue)

  oldValue = concatUom(oldValue)
  newValue = concatUom(newValue)

  oldValue = convertBoolean(oldValue)
  newValue = convertBoolean(newValue)

  oldValue = Utils.matchCodeMaster(oldValue, codeType, codeMasterList)
  newValue = Utils.matchCodeMaster(newValue, codeType, codeMasterList)

  if (array) {
    oldValue = convertArray(oldValue)
    newValue = convertArray(newValue)
  }

  oldValue = Utils.matchField(oldValue, match)
  newValue = Utils.matchField(newValue, match)

  function concatUom(value) {
    if (uom && value && value != NO_VALUE) {
      return value + uom
    }
    return value
  }

  function convertArray(value) {
    if (Utils.isNull(value) || value.length === 0) {
      return ''
    } else {
      return value.toString()
    }
  }
  function convertTimestamp(value) {
    if (timestamp && value && value != NO_VALUE) {
      // let date = new Date(value)
      return Utils.formatDate(value, Utils.FORMAT_DATE_TIME)
    }
    return value
  }
  function convertBoolean(value) {
    if (value === true) {
      return checkbox ? 'Checked' : 'Yes'
    } else if (value === false) {
      return checkbox ? 'Unchecked' : 'No'
    }
    return value
  }

  return (
    <Grid container xs={12} sm={12} spacing={0}>
      <CngGridItem xs={6} sm={6}>
        <LabelValueVertical
          label={label}
          value={oldValue}
          valueStyle={
            oldValue === NO_VALUE ? 'greyLabel paddingLeft16' : undefined
          }
        />
      </CngGridItem>
      <CngGridItem xs={6} sm={6}>
        <LabelValueVertical
          label={label}
          value={newValue}
          valueStyle={
            newValue === NO_VALUE ? 'greyLabel paddingLeft16' : undefined
          }
        />
      </CngGridItem>
    </Grid>
  )
}

export default AuditTrailDualColumn
