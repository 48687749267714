import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import EditIcon from '@material-ui/icons/Edit'
const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function EditButton(buttonProps) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <CngPrimaryButton {...buttonProps}>
        <EditIcon />
        {buttonProps.otherText != null
          ? buttonProps.otherText
          : translatedTextsObject.edit}
      </CngPrimaryButton>
    </div>
  )
}

export default EditButton
