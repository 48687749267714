import React from 'react'
import { Container } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

/* 
Just a component that simulate the newer version Material-UI's Alert:
https://mui.com/components/alert/
*/
function InfoAlert({
    title,
    ...props
}) {

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const defaultTitle = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.INFO)

    return (
        <Container
            disableGutters={true}
            style={{
                backgroundColor: 'rgb(229, 246, 253)',
                display: 'flex',
                padding: '1em',
                marginBottom: '1em',
                borderRadius: '0.5em'
            }}
        >
            <InfoOutlinedIcon style={{ color: '#03a9f4' }} />
            <Container style={{ color: 'rgb(1, 67, 97)' }}>
                <div><strong>{title || defaultTitle}</strong></div>
                <div>{props.children}</div>
            </Container>
        </Container>
    )
}

export default InfoAlert
