import React, { useEffect, useState } from 'react'

import { Avatar, Grid } from '@material-ui/core'
import CarrierApi from 'src/views/common/api/CarrierApi'
import useFetchCarrier from 'src/views/common/serviceHook/useFetchCarrier'
import { useServices } from 'cng-web-lib'
import { faAnchor } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CarrierViewField = (props) => {
  const { fetchRecords } = useServices()
  const [carrier, setCarrire] = useState([])
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    CarrierApi.fetchCarrier(fetchRecords, onLoadCarrierSuccess)
  }, [props])

  // useFetchCarrier(onLoadCarrierSuccess)

  function onLoadCarrierSuccess(data) {
    let carrier = data.find(
      (carrier) => carrier?.partyId == props.carrierPartyId
    )

    console.log(carrier)

    if (carrier === undefined) {
      console.log('null')
      // carrier.code = "test"
    }
    setCarrire(carrier)
    setShouldRender(true)
  }

  if (!shouldRender) {
    return null
  }

  // alert(props.portCode)
  console.log(
    'CarrierViewField return ' +
      props.carrierPartyId +
      ' > ' +
      JSON.stringify(carrier)
  )

  return (
    <div className={'rs-content'}>
      <Grid container sm={12} xs={12}>
        <Grid md={3} sm={12} xs={12}>
          <Avatar
            alt={carrier == undefined ? ' ' : carrier.name}
            src={`${process.env.PUBLIC_URL}/static/images/carriers/${
              carrier == undefined ? ' ' : carrier.code
            }.svg`}
            // className={classes.small}
            classes={{
              root: 'cng-summary-card--avatar circle',
              colorDefault: 'default-bg',
              img: 'img-src'
            }}
            variant='rounded'
          >
            {/* <img
                                className={'view-field-img'}
                                src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                            ></img> */}
            <FontAwesomeIcon icon={faAnchor} />
          </Avatar>
        </Grid>
        <Grid md={9} sm={12} xs={12} style={{ paddingLeft: '12px' }}>
          {carrier == undefined ? ' ' : carrier.code}
        </Grid>
      </Grid>
    </div>
  )
}

export default CarrierViewField
