import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import { faRoute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const {
  button: { CngPrimaryButton, CngSecondaryButton },
} = components

function TrackContainerButton(buttonProps) {

  const isPrimary = buttonProps.isPrimary
  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      { (isPrimary) ?
        (<CngPrimaryButton {...buttonProps}><FontAwesomeIcon icon={faRoute} color="blue"/>&nbsp;{translatedTextsObject.trackContainers}</CngPrimaryButton>)
        :
        (<CngSecondaryButton {...buttonProps}><FontAwesomeIcon icon={faRoute} color="blue"/>&nbsp;{translatedTextsObject.trackContainers}</CngSecondaryButton>)}
    </div>
  )

}

export default TrackContainerButton
