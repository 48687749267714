import {
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { FadedButton } from 'src/components/button'

import { components } from 'cng-web-lib'
const {
  button: { CngPrimaryButton },
  CngDialog
} = components

const useStyles = makeStyles(theme => ({
  action: { minWidth: '5em' },
  dense: {
    '& .MuiDialogTitle-root': {
      padding: theme.spacing(1, 2),
      paddingRight: theme.spacing()
    }
  }
}))

function BasicDialog({
  title, titleDivider,
  onClose, onConfirm,
  children, customized,
  customActions, confirm, cancel,
  ContentProps,
  ...props
}) {
  const classes = useStyles()
  const hasActions = () => (confirm || cancel || customActions)

  const renderContent = () => {
    if (customized)
      return children
    return <DialogContent {...ContentProps}>
      {children}
    </DialogContent>
  }

  return (<CngDialog
    fullWidth
    scroll='paper'
    className={classes.dense}
    dialogTitle={<Grid container wrap='nowrap' justify='space-between' alignItems='center'>
      <Typography variant='h5'><b>{title}</b></Typography>
      <Tooltip title='Close'>
        <IconButton size='small'
          onClick={onClose}
        >
          <FontAwesomeIcon icon={['fal', 'times']} />
        </IconButton>
      </Tooltip>
    </Grid>}
    customDialogContent={<>
      {titleDivider && <Divider />}
      {renderContent()}
    </>}
    dialogAction={hasActions && (
      <Grid container spacing={1}>
        <Grid item xs>{customActions}</Grid>
        {cancel && <Grid item>
          <FadedButton
            color='primary'
            onClick={onClose}
            className={classes.action}
          >
            {typeof cancel === 'string' ? cancel : 'Cancel'}
          </FadedButton>
        </Grid>}
        {confirm && <Grid item>
          <CngPrimaryButton
            onClick={onConfirm || onClose}
            className={classes.action}
          >
            {typeof confirm === 'string' ? confirm : 'Confirm'}
          </CngPrimaryButton>
        </Grid>}
      </Grid>
    )}
    {...props}
  />)
}

export default BasicDialog
