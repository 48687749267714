const SearchScheduleKeys = {
  TITLE: 'title',
  PORT_FROM: 'portFrom',
  PORT_TO: 'portTo',
  CARGO_DEPART_DATE: 'cargoDepartDate',
  CARGO_ARRIVAL_DATE: 'cargoArrivalDate',
  PREVIOUSLY_SEARCH: 'previouslySearch',
  VERSION: 'version',
  NAME: 'name',
  DESCRIPTION: 'description',
  PREFERRED: 'preferred',
  SEARCH: 'search',
  NORESULT: 'noResult',
  NORESULTFOUND: 'noResultFound',
  SENDUSREQUEST: 'sendUsRequest',
  CARRIER: 'carrier',
  VESSEL: 'vessel',
  SERVICE: 'service',
  TRANSITTIME: 'transitTime',
  CYCUTOFF: 'cyCutOff',
  TERMINAL: 'Terminal',
  DEPARTPOL: 'departPol',
  ARRIVEPOD: 'arrivePod',
  BOOK: 'book',
  DAYS: 'days',
  DIRECT: 'direct',
  TRANSPORTVIA: 'transportVia',
  DEPARTURE: 'departure',
  ARRIVE: 'arrive',
  VOYAGERDETAILS: 'voyagerDetails',
  FASTEST: 'fastest',
  EARLIEST: 'earliest',
  FILTERS: 'filters',
  CLEAR_ALL: 'clearAll',
  ALL_CARRIER: 'allCarrier',
  PREFERRED_CARRIER: 'preferredCarrier',
  PREFERRED_PORT_PAIR: 'preferredPortPair',
  BLACKLISTED_CARRIERS: 'blacklistedCarriers',
  DIRECT_TRANSHIPMENT: 'directTranshipment',
  DIRECT_ONLY: 'directOnly',
  NORESULTFOUNDAFTERFILTER: 'noResultFoundAfterFilter',
  ORSENDUSYOUR: 'orSendUsYour',
  REQUEST: 'request',
  RESETFILTERS: 'resetFilters',
  REQUESTCARRIERCONNECTION: 'requestCarrierConnection',
  SELECTCARRIER: 'selectCarrier',
  ENTERCARRIERINFORMATION: 'enterCarrierInformation',
  GETNOTIFIED: 'getNotified',
  NOTIFYME: 'notifyMe',
  EMAILADDRESS: 'emailAddress',
  CARRIERINFO1: 'carrierInfo1',
  CARRIERINFO2: 'carrierInfo2',
  CARRIERINFO3: 'carrierInfo3',
  BOOKINGNUMBER: 'bookingNumber',
  ANNUALSHIPMENT: 'annualShippment',
  CONTRACTNUMBER: 'contractNumber',
  CUSTOMERNUMBER: 'customerNumber',
  BLNUMBER: 'blNumber',
  GOVID: 'govID',
  COMMENT4CARRIER: 'comment4Carrier',
  DETAILOFSEARCH: 'detailOfSearch',
  DIALOGCONTENT: 'dialogContent',
  DIALOGOK: 'dialogOK',
  DIALOGCANCEL: 'dialogCancel',
  DIALOGTITLE: 'dialogTitle',
  CONNECTCARRIER: 'connectCarrier',
  SEARCHCOMPLETED: 'searchCompleted',
  REFRESHTHERESULT: 'refreshTheResult',
  CANCEL: 'cancel',
  REFRESH: 'refresh',
  REFRESHNOTE: 'refreshNote',
  EXPORTTEXT: 'exportText',
  CONTINUETEXT: 'continueText',
  SENDREQUEST: 'sendRequest',
  VESSELSCHEDULE: 'vesselschedule',
  WEEKS: 'weeks',
  PLUS: 'plus',
  MINUS: 'minus',
  VOYAGESTATUS: 'voyageStatus',
  CFCTONPERTEU: 'cfcTonPerTeu',
  CO2EMISSIONTOTAL: 'cO2EmissionTotal',
  CFCTONSUNIT: 'tons',
  ENERGYCONSUMPTION: 'energyConsumption',
  CFCMEGAJOULEUNIT: 'megajoule',
}

export default SearchScheduleKeys
