import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined'
import Button from '@material-ui/core/Button'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  secondStyle: {
    backgroundColor: '#5e81f419',
    borderRadius: '8px',
    borderColor: '#5e81f419',
    boxShadow:
      '0 0 1px 0 rgba(94, 129, 244, 0.1), 0 2px 2px -2px rgba(94, 129, 244, 0.25)',
    color: '#FF808B',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434'
    },
    '&:focus': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434'
    },
    '&:active': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434'
    },
    '&:linkButton': {
      padding: '6px 13px',

      '&:hover': {
        textDecoration: 'none'
      }
    }
  }
}))

function DeclineButton(buttonProps) {
  const classes = useStyles()

  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <Button
        startIcon={<BlockOutlinedIcon />}
        variant='contained'
        className={classes.secondStyle}
        {...buttonProps}
      >
        {buttonProps.otherText != null
          ? buttonProps.otherText
          : translatedTextsObject.decline}
      </Button>
    </div>
  )
}

export default DeclineButton
