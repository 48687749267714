import { Button } from '@material-ui/core'
import { LogOut } from 'react-feather'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: { CngSecondaryButton }
} = components

function CancelButtonWithIcon(buttonProps) {
  return (
    <Button startIcon={<LogOut />} {...buttonProps}>
      Export
    </Button>
  )
  //return <CngSecondaryButton startIcon={<NotInterestedIcon />} {...buttonProps}>{translatedTextsObject.cancel}</CngSecondaryButton>
}

export default CancelButtonWithIcon
