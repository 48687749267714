import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileContract } from '@fortawesome/free-solid-svg-icons'

const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function CreatePermitButton(buttonProps) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <CngSecondaryButton
        startIcon={<FontAwesomeIcon icon={faFileContract} />}
        {...buttonProps}
      >
        {translatedTextsObject.createPermit}
      </CngSecondaryButton>
    </div>
  )
}

export default CreatePermitButton
