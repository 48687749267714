import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: { CngPrimaryButton }
} = components

function ApproveButton(buttonProps) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <CngPrimaryButton {...buttonProps}>
        <CheckCircleOutlineRoundedIcon />
        {buttonProps.otherText != null
          ? buttonProps.otherText
          : translatedTextsObject.approve}
      </CngPrimaryButton>
    </div>
  )
}

export default ApproveButton
