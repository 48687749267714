const TcalAsUserGroupMasterKeys = {
  TITLE: "User Group Master",
  CREATED_BY: "Created By",
  CREATED_DATE: "Created Date",
  UPDATED_BY: "Updated By",
  UPDATED_DATE: "Updated Date",
  USER_GROUP_MASTER_ID: "User Group MasterId",
  ORG_CODE: "Org Code",
  CUSTOMER_GROUP_CODE: "Customer Group Code",
  CUSTOMER_GROUP_NAME: "Customer Group Name",
  COMPANY_TYPE: "Company Type",
  SUBSCRIPTION_SCHEME: "Subscription Scheme",
  SUBSCRIPTION_TYPE: "Subscription Type",
  DATA_RANGE: "Data Range (Months)"
}

export default TcalAsUserGroupMasterKeys
