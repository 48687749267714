const ContainerTypeKeys = {
  TITLE: "title",
  ACTIVE: "active",
  CONTAINER_ISO_TYPE: "containerIsoType",
  CONT_HEIGHT: "contHeight",
  CONT_HEIGHT_DESC: "contHeightDesc",
  CONT_HEIGHT_VALUE: "contHeightValue",
  CONT_SIZE: "contSize",
  CONT_TYPE: "contType",
  CONT_TYPE_DESC: "contTypeDesc",
  HAULIO_CONT_SIZE_TYPE: "haulioContSizeType",
  ISO_CODE: "isoCode",
  CONTAINER_CODE: "containerCode"
}

export default ContainerTypeKeys
