import Box from '@material-ui/core/Box'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import { Typography } from '@material-ui/core'
import { components } from 'cng-web-lib'

const {
  CngDialog,
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function WarningDialog({
  isDialogOpen,
  closeDialog,
  okDialog,
  content,
  okMsg,

}) {
  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={2} mt={1}>
            <Box mt={0.5}>
              <Typography variant='body2'>{content}</Typography>
            </Box>
            <Box display='flex' flexDirection='row-reverse' mt={2}>
              <Box>
                <CngPrimaryButton onClick={okDialog}>
                  {okMsg}
                </CngPrimaryButton>
              </Box>
              <Box flexGrow={1}></Box>
            </Box>
          </Box>
        </Paper>
      }
      dialogTitle={
        <>
          <b>Warning</b>
          <CloseDialogIconButton onClick={closeDialog} />
        </>
      }
      open={isDialogOpen}
      fullWidth
      maxWidth='sm'
    />
  )
}

export default WarningDialog
