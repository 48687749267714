import {
    Box,
    Card,
    Grid
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import React, { useState } from 'react'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton'
const AuditTrialRemarks = (props) => {
    const [display, setDisplay] = useState('')
    const onClick = () => {
        setDisplay('none')
    }
    const tranObj = CalistaUiComponentTranslationText()
    return (
        <Card className="view-card view-card-content" style={{backgroundColor: '#F4BE5E10', display:display}}>
            <Grid container direction="row"
                            justify="space-between"
                            alignItems="center">
                <Grid item>
                    <FontAwesomeIcon icon={faExclamationTriangle} color="#F4BE5E"/>  &nbsp; {tranObj.auditTrailRemarks}
                </Grid>
                <Grid item>
                <IconButton
                        aria-label='close'
                        onClick={onClick}
                        size='small'
                        >
                        <CloseIcon />
                        </IconButton>
                </Grid>
            </Grid>
        </Card>

    )
}
export default AuditTrialRemarks;