const SCOInvReportKeys = {
    TITLE: "Generate Report",
    REPORT_FIELD_PRINCIPAL: "Principal",
    REPORT_FIELD_DUTY_STATUS: "Duty Status",
    REPORT_FIELD_WAREHOUSE: "Warehouse",
    REPORT_FILE_NAME_FILE_TYPE: "File Name/ File Type",
    REPORT_CREATED_DATE: "Created Date",
    REPORT_STATUS: "Status",
    UPLOAD_COMPLETED: "Upload Completed",
    UPLOAD_FAILED: "Upload Failed"
}

export default SCOInvReportKeys