const CalistaPartyCompanyInfoKeys = {

	// General
	TITLE: "title",
	PARTY_NAME: "partyName",

	//Company's Contact Person Details
	COMPANY_CONTACTPERSONDETAILS_HEADER: "companyContactPersonDetailsHeader",
	COMPANY_CONTACTPERSONDETAILS_CONTACTEMAIL: "contactEmail",
	COMPANY_CONTACTPERSONDETAILS_CONTACTPHONE: "contactPhone", 

	//Company's Specialisations
	COMPANY_SPECIALISATIONS_HEADER: "companySpecialisationsHeader",
	COMPANY_SPECIALISATIONS_INDUSTRIES: "specialisedIndustries",
	COMPANY_SPECIALISATIONS_SERVICES: "sepcialisedServices",

	//Company's Business Interests
	COMPANY_BUSINESSINTERESTS_HEADER: "companyBusinessInterestsHeader",
	COMPANY_BUSINESSINTERESTS_INDUSTRIES: "interestedIndustries",
	COMPANY_BUSINESSINTERESTS_SERVICES: "interestedServices",
	COMPANY_BUSINESSINTERESTS_COUNTRIES: "interestedCountries",

	//More About The Company
	COMPANY_MOREABOUT_HEADER: "companyMoreAboutHeader",
	COMPANY_MOREABOUT_INTRO: "introduction",
	COMPANY_MOREABOUT_VISION: "vision",
	COMPANY_MOREABOUT_COUNTRIES: "presenceInCountries",

	//Company Media
	COMPANY_MEDIA_HEADER: "companyMediaHeader",
	COMPANY_MEDIA_CURRENT: "companyMediaCurrent",

	//Management Team
	COMPANY_MANAGEMENT_HEADER: "managementTeamHeader",
	COMPANY_MANAGEMENT_ADD: "addManagementUser",
	COMPANY_MANAGEMENT_FULLNAME: "fullName",
	COMPANY_MANAGEMENT_ROLE: "role",
	COMPANY_MANAGEMENT_PROFILELINK: "profileLink",
	COMPANY_MANAGEMENT_PROFILELINKPLACEHOLDER: "profileLinkPlaceholder",
	COMPANY_MANAGEMENT_CURRENTPROFILEPHOTO: "currentProfilePhoto",
	COMPANY_MANAGEMENT_NONE: "none",
	COMPANY_MANAGEMENT_UPLOAD: "noteForFileUploadProfilePic",
	
	//Awards and Accreditations
	COMPANY_AWARDS_HEADER: "awardsHeader",
	COMPANY_AWARDS_ADD: "addAward",
	COMPANY_AWARDS_TITLE: "awardTitle",
	COMPANY_AWARDS_DESCRIPTION: "awardDescription",
	COMPANY_AWARDS_CURRENTSUPPDOC: "currentSuppDoc",
	COMPANY_AWARDS_NONE: "none",
	COMPANY_AWARDS_UPLOAD: "noteForFileUploadSuppDoc",

	//Company Microsite
	COMPANY_MICROSITE_HEADER: "micrositeHeader",
	COMPANY_MICROSITE_TEXT_PART1: "micrositeTextPart1",
	COMPANY_MICROSITE_TEXT_PART2: "micrositeTextPart2",
	COMPANY_MICROSITE_TEXT_PART3: "micrositeTextPart3",
	COMPANY_MICROSITE_TEXT_LOOKLIKE: "micrositeLookLikeText",
	COMPANY_MICROSITE_TEXT_NOTACTIVATED: "micrositeNotActivatedText",
	COMPANY_MICROSITE_UNIQUEPATH: "uniquePath",
	COMPANY_MICROSITE_UNIQUEPATH_PLACEHOLDER: "uniquePathPlaceholder",
	COMPANY_MICROSITE_SEE_DEMO: "seeDemo",
	COMPANY_MICROSITE_GETCREATIONGUIDE: "getCreationGuide",
	COMPANY_MICROSITE_AGREETOTEXT: "agreeToText",
	COMPANY_MICROSITE_GETSTC: "getsTC",
	MICROSITE_PATH_FORMAT_MESSAGE: "micrositePathFormatErrorMsg",
	MICROSITE_PATH_EXISTS: "micrositePathExists",
	MICROSITE_VIEW: "viewMicrosite",
	REMOVE: "remove",
	CREATE: "create",
	UPDATE: "update",

	//Messages
	INVALID_EMAIL: "invalidEmailMsg",
	FORM_ERROR: "formError",
	BACKEND_SUCCESS_MSG: "backendSuccessMsg", 
	BACKEND_ERROR_MSG: "backendErrorMsg",
	MAXLENGTH: "maxLength",
	CHARACTERS: "characters", 
	EXCEED_CHARLIMIT_MSG: "exceedCharLimitMsg",
	OF_MSG: "ofMsg"
}

export default CalistaPartyCompanyInfoKeys
