const PingKeys = {

    // General
    MODULE_TITLE: "module-title",

    // Sub module titles
    SUB_MODULE_TITLE: {
        PING_ADMIN: "ping-admin-title",
        PING_SYS_CONFIG: "ping-system-config-title",
        PING_PARTY_CONFIG: "ping-party-config-title",
        PING_EVENT_SOURCE: "ping-event-source-title",
        PING_EVENT_CODE_MASTER: "ping-event-code-master-title",
        PING_EVENT_CODE_MAPPING: "ping-event-code-mapping-title",
        PING_EVENT_CODE_LINK: "ping-event-code-link-title",
        PING_SHIPMENT_EVENT: "ping-shipment-event-title",
        PING_SHIPMENT_EVENT_DETAIL: "ping-shipment-event-detail-title",
        PING_SEARCH_LOG: "ping-search-log-title",
        PING_VESSEL_VOYAGE_INFO: "ping-vessel-voyage-info-title",
        PING_EVENTHUB_API_LOGS: "ping-eventhub-api-logs-title",
        PING_PORTNET_API_LOGS: "ping-portnet-api-logs-title",

        PING_SEARCH: "ping-search-title",
        PING_SEARCH_FAVOURITE_LIST: "ping-search-favourite-list-title",
        PING_SEARCH_HISTORY_LIST: "ping-search-history-list-title",
        PING_SEARCH_BOOKING_LIST: "ping-search-booking-list-title",
        PING_MY_MILESTONES: "ping-my-milestones-title"
    },

    TAB_NAMES: {
        // Event Hub API Logs
        CURRENT_SUBSCRIPTIONS: "tabs.current-subscriptions",
        PULL_API_LOG: "tabs.pull-api-log",
        SUBSCRIBE_API_LOG: "tabs.subscribe-api-log",
        BATCH_LOG: "tabs.batch-log",
        PUSH_API_LOG: "tabs.push-api-log",

        // Portnet API Logs
        VESSEL_API_LOG: "tabs.vessel-api-log"
    },

    FIELDS: {
        // General
        ACTIVE: "fields.active",
        API_KEY: "fields.api-key",
        CODE: "fields.code",
        CONTAINER_NUM: "fields.containerNum",
        CREATED_DATE: "fields.created-date",
        DATE: "fields.date",
        DESCRIPTION: "fields.description",
        DISPLAY_NAME: "fields.display-name",
        GROUP: "fields.group",
        NAME: "fields.name",
        PARENT: "fields.parent",
        PARTY: "fields.party",
        PARTY_NAME: "fields.party-name",
        POL: "fields.pol",
        POD: "fields.pod",
        PRIORITY: "fields.priority",
        REMARKS: "fields.remarks",
        RESULT: "fields.result",
        SOURCE: "fields.source",
        STATUS: "fields.status",
        SYSTEM: "fields.system",
        TYPE: "fields.type",
        UPDATED_DATE: "fields.updated-date",
        UPLOADED_DATE: "fields.uploaded-date",
        USER_ID: "fields.user-id",
        USER_LOGIN_ID: "fields.user-login-id",
        VALUE: "fields.value",
        VESSEL_NAME: "fields.vessel-name",
        VOYAGE_NUM: "fields.voyage-num",
        BEE_ID: "fields.bee-id",
        TEMP: "fields.temp",
        HUMIDITY: "fields.humidity",
        AMBIENCE: "fields.ambience",
        DISABLE_PORTNET_CALL: "fields.diable-portnet-call",
        FLEXIALERT_INTERVAL: "fields.flexialert-interval",
        FLEXIALERT_BOOKING_ACTIVE_DAY: "fields.flexialert-booking-active-day",
        INSTRUCTIONS: "fields.instructions",
        UNIT: "fields.unit",
        MAX_TEMP: "fields.maxTemp",
        MIN_TEMP: "fields.minTemp",
        CUTOFF_EVENT_TYPE: "fields.cutoffEventType",
        CUTOFF_TYPE: "fields.cutoffType",

        // Descriptions
        DESCRIPTIONS: "fields.descriptions",
        DESCRIPTION_AR: "fields.description_ar",
        DESCRIPTION_EN: "fields.description_en",
        DESCRIPTION_ES: "fields.description_es",
        DESCRPITION_FR: "fields.description_fr",
        DESCRIPTION_HI: "fields.description_hi",
        DESCRIPTION_NL: "fields.description_nl",
        DESCRIPTION_PT: "fields.description_pt",
        DESCRIPTION_RU: "fields.description_ru",
        DESCRIPTION_ZH: "fields.description_zh",

        // Event related
        EVENT_ID: "fields.event-id",
        EVENT_CODE: "fields.event-code",
        EVENT_NAME: "fields.event-name",
        EVENT_DATE: "fields.event-date",
        EVENT_DATETIME: "fields.event-datetime",
        EVENT_GUID: "fields.event-guid",
        EVENT_TYPE: "fields.event-type",
        EVENT_LOCODE: "fields.event-locode",
        EVENT_LOCODE_FROM: "fields.event-locode-from",
        EVENT_TIMEZONE: "fields.event-timezone",

        // Event Mapping related
        SOURCE_EVENT_CODE: "fields.source-event-code",
        SOURCE_EVENT_NAME: "fields.source-event-name",
        MAPPED_EVENT_CODE: "fields.mapped-event-code",
        EVENT_SOURCE_CODE: "fields.event-source-code",

        // Shipment Event
        REFERENCE_TYPE: "fields.reference-type",
        REFERENCE_ID: "fields.reference-id",
        REFERENCE_VALUE: "fields.reference-value",
        REFERENCE_NUM: "fields.reference-num",

        FREIGHT_BOOKING: "fields.freight-booking",
        CONTAINER_NUMBER: "fields.container-number",

        // Party Config
        PARTY_SUMMARY: "fields.party-summary",
        CONT_LIFECYCLE_START_BUFFER: "fields.cont-lifecycle-start-buffer",
        CONT_LIFECYCLE_END_BUFFER: "fields.cont-lifecycle-end-buffer",
        CONT_MILESOTNE_RESULT_COUNT: "fields.cont-milestone-result-count",
        EVENT_HUB_USAGE: "fields.event-hub-usage",

        // Event Code Linking related
        PLANNED_EVENT_CODE: "fields.planned-event-code",
        ESTIMATED_EVENT_CODE: "fields.estimated-event-code",
        ACTUAL_EVENT_CODE: "fields.actual-event-code",

        // Vessel Search related
        IN_VOYAGE_NUM: "fields.in-voyage-num",
        OUT_VOYAGE_NUM: "fields.out-voyage-num",
        PREV_IN_VOYAGE: "fields.prev-in-voyage",
        PREV_OUT_VOYAGE: "fields.prev-out-voyage",
        CURR_IN_VOYAGE: "fields.curr-in-voyage",
        CURR_OUT_VOYAGE: "fields.curr-out-voyage",
        NEXT_IN_VOYAGE: "fields.next-in-voyage",
        NEXT_OUT_VOYAGE: "fields.next-out-voyage",

        // Tab Titles
        EVENT_INFO_TAB_TITLE: "fields.event-info-tab-title",
        EVENT_DETAILS_TAB_TITLE: "fields.event-details-tab-title",

        // Search Log
        ELAPSED_TIME: "fields.elapsed-time",
          //search card column
        SN: "fields.sn",

        // API Logs
        BOOKING_ID: "fields.booking-id",
        SI_ID: "fields.si-id",
        DEBUG_LOG: "fields.debug-log",
        SUMMARY: "fields.summary",
        DETAILS: "fields.details",
        REQUEST_SUMMARY: "fields.request-summary",
        REQUEST_HEADER: "fields.request-header",
        REQUEST_BODY: "fields.request-body",
        RESPONSE_SUMMARY: "fields.response-summary",
        RESPONSE_STATUS: "fields.response-status",
        RESPONSE_HEADER: "fields.response-header",
        RESPONSE_BODY: "fields.response-body"
    },

    // My Milestone specific labels
    PING_MY_MILESTONES: {

        // Page titles
        PAGE_TITLE: {
            CREATE_NEW_MILESTONE: "ping-my-milestones.page-title.create-new-milestone",
            EDIT_MILESTONE: "ping-my-milestones.page-title.edit-milestone",
            VIEW_MILESTONE: "ping-my-milestones.page-title.view-milestone",
            UPLOAD_MILESTONES: "ping-my-milestones.page-title.upload-milestones"
        },

        // Card titles
        EVENT_DETAILS: "ping-my-milestones.event-details",
        PARTICULARS: "ping-my-milestones.particulars",
        ADDITIONAL_DETAILS: "ping-my-milestones.additional-details",

        // Labels for the filters
        FILTERS: {
            ALL: "ping-my-milestones.filters.all",

            // Date Filter
            PAST_15_DAYS: "ping-my-milestones.filters.past-15-days",
            PAST_30_DAYS: "ping-my-milestones.filters.past-30-days",
            PAST_60_DAYS: "ping-my-milestones.filters.past-60-days",

            // Reference Type Filter
            FREIGHT_BOOKING: "ping-my-milestones.filters.freight-booking",
            CONTAINER: "ping-my-milestones.filters.container",

            // Filter Errors
            PLEASE_SELECT_REFERENCE_TYPE: "ping-my-milestones.filters.please-select-reference-type",
            PLEASE_SELECT_EVENT_CODE: "ping-my-milestones.filters.please-select-event-code"
        },

        // Labels for displaying the upload status.
        UPLOAD_STATUS: {
            UPLOADING: "ping-my-milestones.upload-status.uploading",
            UPLOAD_FAILED: "ping-my-milestones.upload-status.upload-failed",
            PROCESSING: "ping-my-milestones.upload-status.processing",
            SUCCESS: "ping-my-milestones.upload-status.success",
            FILE_ERROR: "ping-my-milestones.upload-status.file-error",
            TIMEOUT: "ping-my-milestones.upload-status.timeout"
        },

        // Misc Labels
        MILESTONE_LIST: "ping-my-milestones.milestone-list",
        MILESTONE_UPLOAD_LIST: "ping-my-milestones.milestone-upload-list",
        UPLOAD_MILESTONE: "ping-my-milestones.upload-milestone",
        CREATE_MILESTONE: "ping-my-milestones.create-milestone",
        BACK_TO_MILESTONES: "ping-my-milestones.back-to-milestones",
        MILESTONE_UPDATED: "ping-my-milestones.milestone-updated",
        MILESTONE_DELETED: "ping-my-milestones.milestone-deleted",
        MILESTONE_LIST_SEARCH_PLACEHOLDER: "ping-my-milestones.milestone-list-search-placeholder",
        UPLOAD_LIST_SEARCH_PLACEHOLDER: "ping-my-milestones.upload-list-search-placeholder",

        SORT_BY: "ping-my-milestones.sort-by",
        EVENT_DATE_DESC: "ping-my-milestones.event-date-desc",
        EVENT_DATE_ASC: "ping-my-milestones.event-date-asc",
        EVENT_NAME_ASC: "ping-my-milestones.event-name-asc",
        EVENT_NAME_DESC: "ping-my-milestones.event-name-desc",
        UPLOAD_DATE_DESC: "ping-my-milestones.upload-date-desc",
        UPLOAD_DATE_ASC: "ping-my-milestones.upload-date-asc",

        NO_MORE_DATA: "ping-my-milestones.no-more-data",
        ERROR_LOADING_MORE_DATA: "ping-my-milestones.error-loading-more-data",

        // Dialog Contents
        DELETE_MILESTONE: "ping-my-milestones.delete-milestone",
        DELETE_MILESTONE_CONFIRM: "ping-my-milestones.delete-milestone-confirm",
        DELETING: "ping-my-milestones.deleting",

        UPLOAD_MILESTONES: "ping-my-milestones.upload-milestones",
        UPLOAD_MILESTONE_HELPER_TEXT: "ping-my-milestones.upload-milestone-helper-text"
    }
}

export default PingKeys
