import { Box, Card, Chip, Grid, Avatar} from '@material-ui/core'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import React from 'react'
import PortViewField from 'src/components/field/PortViewField'
import { useLocation } from 'react-router-dom'
import Utils from 'src/views/common/utils/Utils'
const {
    CngGridItem
  } = components

const AuditTrailDualColumnCarrier = (props) => {
    const {
        label,
        object,
        fields,
        delimiter='/',
        added,
        removed,
        carriers,
        curCarrierPartyId
    } = props
    const NO_VALUE = 'No values'
    let oldValue
    let newValue

    if(object===null || object === undefined) return <div/>

    let value=[]
    for(let i=0; i< fields.length; i++) {
        value.push(object[fields[i]])
    }
    
    if(added) {
        oldValue = NO_VALUE
        newValue = value
    } else if (removed) {
        oldValue = value
        newValue = NO_VALUE
    } else {
        const values = Utils.getAuditTrailDiffs(object, fields)
        if(Utils.noChanges(values)) {
            return <div/>
        }   
        oldValue = values.valueOld
        newValue = values.valueNew
    }

    if(Utils.objectsEqual(newValue , oldValue)) return <div/>

    if(Utils.isEmpty(oldValue[0]) && Utils.isEmpty(newValue[0])) { // carrier party id no change
        oldValue[0] = newValue[0] = curCarrierPartyId
    }

    const CarrierField = (props) => {

        let carrier = carriers.find((c) => c?.partyId == props.carrierPartyId)

        return(
        <div className={'rs-content'}>
            <div>
                <Avatar
                    alt={(carrier == undefined ? " " : carrier.name)}
                    src={`${process.env.PUBLIC_URL}/static/images/carriers/${(carrier == undefined ? " " : carrier.code)}.svg`}
                    // className={classes.small}
                    className={'view-field-img'}
                >
                    <img
                        className={'view-field-img'}
                        src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                    ></img>
                </Avatar>
            </div>
            <div>
                {(carrier == undefined ? " " : carrier.name)}
            </div>
        </div>
        )
    }

    return (
        <Grid container xs={12} sm={12} spacing={0}>
                    <CngGridItem xs={6} sm={6}>
                        {
                            oldValue===NO_VALUE && 
                                        <LabelValueVertical label={label}
                                                value={oldValue} 
                                                valueStyle={'greyLabel paddingLeft16'} />
                        }
                        {
                            oldValue!==NO_VALUE && (
                                <Box className="view-card-content">
                                    <Box className="view-card-content-label"> {label} </Box>
                                    <Box ml={2} className={'rs-content'}>
                                        <CarrierField carrierPartyId={oldValue[0]} /> &nbsp;&nbsp;/&nbsp;&nbsp;{oldValue[1]}
                                    </Box>
                                </Box>    
                            )
                        }
                         </CngGridItem>
                    <CngGridItem xs={6} sm={6}>
                        {
                            newValue===NO_VALUE && 
                                        <LabelValueVertical label={label}
                                                value={newValue} 
                                                valueStyle={'greyLabel paddingLeft16'} />
                        } 
                        {
                            newValue!==NO_VALUE && (
                                <Box className="view-card-content">
                                    <Box className="view-card-content-label"> {label} </Box>
                                    <Box ml={2} className={'rs-content'}>
                                        <CarrierField carrierPartyId={newValue[0]} /> &nbsp;&nbsp;/&nbsp;&nbsp; {newValue[1]}
                                    </Box>
                                </Box>    
                            )
                        }
                    </CngGridItem>
        </Grid>            
    )
}

export default AuditTrailDualColumnCarrier
