const BatchBookingRequestKeys = {
  TITLE: "title",
  ID: "id",
  PARTY: "party",
  SENDER_CODE: "senderCode",
  SENDER_QUALIFIER: "senderQualifier",
  RECEIVER_QUALIFIER: "receiverQualifier",
  MESSAGE_REFERENCE_NO: "messageReferenceNo",
  MESSAGE_TYPE: "messageType",
  CARRIER_NAME: "carrierName",
  MESSAGE_FUNCTION: "messageFunction",
  DOCUMENT_MSG_NO: "documentMsgNo",
  DOCUMENT_ISSUED_ON_DATE: "documentIssuedOnDate",
  DOCUMENT_ISSUED_ON_TIME: "documentIssuedOnTime",
  ACKNOWLEDGEMENT_INDICATOR: "acknowledgementIndicator",
  BOOKING_REFERENCE_NO: "bookingReferenceNo",
  ORDER_NUMBER: "orderNumber",
  SHIPPER_REMARK: "shipperRemark",
  BOOKING_SHIPMENT_TYPE: "bookingShipmentType",
  STAGE_CODE: "stageCode",
  CONSIGNOR_REFERENCE_NO: "consignorReferenceNo",
  VOYAGE_NO: "voyageNo",
  VESSEL_NAME: "vesselName",
  PORT_OF_LOADING_PORT_CODE: "portOfLoadingPortCode",
  PORT_OF_LOEADING_PORT_CODE_LIST: "portOfLoeadingPortCodeList",
  PORT_OF_LOADING_DATE_CODE: "portOfLoadingDateCode",
  PORT_OF_LOADING_DATE: "portOfLoadingDate",
  PORT_OF_LOADING_TIME: "portOfLoadingTime",
  PORT_OF_DISCHARGE_PORT_CODE: "portOfDischargePortCode",
  PORT_OF_DISCHARGE_PORT_CODE_LIST: "portOfDischargePortCodeList",
  PLACE_OF_RECEIPT_PORT_NAME: "placeOfReceiptPortName",
  PLACE_OF_RECEIPT_DATE_CODE: "placeOfReceiptDateCode",
  PLACE_OF_RECEIPT_DATE: "placeOfReceiptDate",
  PLACE_OF_DELIVERY_PORT_NAME: "placeOfDeliveryPortName",
  PLACE_OF_DELIVERY_DATE_CODE: "placeOfDeliveryDateCode",
  PLACE_OF_DELIVEY_DATE: "placeOfDeliveyDate",
  CONSIGNOR_NAME: "consignorName",
  CONSIGNEE_NAME: "consigneeName",
  CONSIGNEE_ADDRESS: "consigneeAddress",
  CONTAINER_TYPES_AND_SIZES: "containerTypesAndSizes",
  SOC_OR_COC_INDICATOR: "socOrCocIndicator",
  FULL_OR_EMPTY_INDICATOR: "fullOrEmptyIndicator",
  TOTAL_QUANTITY: "totalQuantity",
  BOOKING_ID: "bookingId",
  FILE_TYPE: "fileType",
  SOURCE: "source",
  RECORD_STATUS: "recordStatus",
  CONTACT_PERSON_NAME:"contactPersonName",
  EMAIL_ADDRESS:"emailAddress",
  PROCESSED_DATE: "processedDate"
}

export default BatchBookingRequestKeys
