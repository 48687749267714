import {
  Card,
  CardContent,

  makeStyles,
  withStyles
} from '@material-ui/core'
import { TreeItem as MuiTreeItem } from '@material-ui/lab'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import Header from './Header'

const StyledTreeItem = withStyles(theme => ({
  root: {
    position: 'relative',
    '&:last-child > .MuiTreeItem-content > .MuiTreeItem-label:before': {
      bottom: '50%'
    }
  },
  expanded: {
    '&:not(:last-child) > .MuiTreeItem-group:before': {
      display: 'block',
      content: "''",
      position: 'absolute',
      borderLeft: `1px dotted ${theme.palette.text.primary}`,
      width: 1,
      left: theme.spacing(-2.5),
      top: theme.spacing(-.25),
      bottom: 3
    }
  },
  label: {
    paddingLeft: 0,
    position: 'relative',
    borderRadius: 8,
    margin: theme.spacing(.25, 0),
    '&:before': {
      display: 'block',
      content: "''",
      position: 'absolute',
      borderLeft: `1px dotted ${theme.palette.text.primary}`,
      width: 1,
      left: -15,
      top: theme.spacing(-.5),
      bottom: 1
    }
  },
  group: {
    marginLeft: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
    position: 'relative'
  },
  iconContainer: {
    borderBottom: `1px dotted ${theme.palette.text.primary}`,
    marginRight: 0,
    position: 'relative'
  }
}))(MuiTreeItem)

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none'
  },
  content: {
    borderRadius: theme.spacing(0,0,1,1),
    boxShadow: 'none',
    background: 'rgba(236, 245, 255, 0.5)',
    marginTop: -2,
    marginLeft: -21,
    '& > .MuiCardContent-root': {
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(2),
      fontSize: '.9em'
    }
  }
}))

function TreeItem({node, parentNode, ...props}) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)

  const renderContent = data => {
    if (!data.content)
      return null
    if (data.content instanceof Function)
      return data.content(data)
    return (
      <Card className={classes.content}>
        <CardContent>
          {data.content}
        </CardContent>
      </Card>
    )
  }

  return (<StyledTreeItem nodeId={node.id}
    label={
      <Header onClick={() => props.onClick(node, parentNode, setLoading)}
        active={node.id === props.active}
        expanded={props.expanded.includes(node.id)}
        loading={loading}
        hasChildren={node.hasChildren}
        isContentNode={Boolean(node.content)}
      >
        {node.renderHeader instanceof Function
          ? node.renderHeader(node)
          : (props.renderHeader instanceof Function
            ? props.renderHeader(node)
            : node[props.labelKey])
        }
      </Header>
    }
    className={(props.hideInactiveSiblings && node.hide) ? classes.hidden : null}
  >
    {renderContent(node)}
    {Array.isArray(node.children)
      ? node.children.map((child) => <TreeItem {...props}
        key={child.id}
        node={child} parentNode={node} />)
      : null
    }
  </StyledTreeItem>)
}

export default TreeItem
