const AccessLevelCompanyKeys = {
  TITLE: "title",
  PARTY_ID: "partyId",
  AccessLevelCompanyRule: {
    TITLE: "accessLevelCompanyRule.title",
    COMPANY_RULE_ID: "accessLevelCompanyRule.companyRuleId",
    RULE_ID: "accessLevelCompanyRule.ruleId"
  }
}

export default AccessLevelCompanyKeys
