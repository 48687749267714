const PartyConfigKeys = {
    MODULE_TITLE: "module-title",
    PARTY_CONFIG_LIST: "party-config-list",
    PARTY_CONFIG_DETAIL: "party-config-detail",
    PARTY_CONFIG_DETAIL_EDIT: "party-config-detail-edit",
    HEADER: {
        SUMMARY: "header.summary",
        PARTY_CONFIG: "header.party-config"
    },
    TABS: {
        SAVED_ADDRESS: "tabs.saved-address",
        WEBHOOK: "tabs.webhook"
    },
    FIELDS: {
        PARTY_NAME: "fields.party-name",
        CODE: "fields.code",
        FIRST_NAME: "fields.first-name",
        LAST_NAME: "fields.last-name",
        PHONE: "fields.phone",
        FAX: "fields.fax",
        EMAIL: "fields.email",
        COMPANY_NAME: "fields.company-name",
        TAX_NO: "fields.tax-no",
        ADDRESS: "fields.address",
        CITY: "fields.city",
        STATE: "fields.state",
        COUNTRY: "fields.country",
        POSTAL_CODE: "fields.postal-code",
        ADDRESS_TYPE: "fields.address-type",
        STATE_OTHER: "fields.state-other",
        PARTY_TYPE: "fields.party-type",
        WEBHOOK_TYPE: "fields.webhook-type",
        WEBHOOK_URL: "fields.webhook-url",
        CREATED_AT: "fields.created-at",
        CREATED_BY: "fields.created-by",
        UPDATED_AT: "fields.updated-at",
        UPDATED_BY: "fields.updated-by"
    }

}

export default PartyConfigKeys