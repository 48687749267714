import { Box, Chip } from '@material-ui/core'
import React from 'react'
import DeclarantMaintenanceApiUrls from 'src/apiUrls/DeclarantMaintenanceApiUrls.js'
import { components, constants } from 'cng-web-lib'
import makeStyles from '@material-ui/styles/makeStyles'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext },
    },
    field: { CngLookupAutocompleteField },
  },
} = components

const {
  filter: { EQUAL },
} = constants

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}))

function DeclarantAutocompleteField({
  name,
  label,
  disabled,
  ...otherCngLookupAutocompleteFieldProps
}) {
  const [field] = useField(name)
  const { value } = field
  return (
    <Box  alignItems='center'>
      <CngLookupAutocompleteField
        name={name}
        label={label}
        value={value}
        lookupProps={{
          label: 'declarantCrNo',
          url: DeclarantMaintenanceApiUrls.GET_DECLARANT_MAINTENANCE_BY_PARTYID,
          value: 'declarantId',
          filters: []
        }}
        renderOption={(option) => (
          <Box display='flex'>
            <Chip label={<b>{option.value}</b>} size='small' />
            <Box ml={0.5}>{option.label}</Box>
          </Box>
        )}
        disabled={disabled}
        {...otherCngLookupAutocompleteFieldProps}
      />
    </Box>
  )
}

export default DeclarantAutocompleteField
