import React from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

function BackButton({
    label = false,
    ...props
}) {

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const defaultLabel = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.BACK);

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Button
            variant='contained'
            classes={{ root: 'ng-button-filled-secondary' }}
            {...props}
        >
            <span>{label || defaultLabel}</span>
        </Button>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default BackButton
