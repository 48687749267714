import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CngAlertLabel({ ...props }) {
  const useStyles = makeStyles({
    myAlert: {
      backgroundColor: '#ECECF2'
    }
  })
  const classes = useStyles()
  return (
    <Box>
      <Alert
        severity='info'
        icon={<FontAwesomeIcon icon={['fal', 'info-circle']} color='#8996AF' />}
        className={classes.myAlert}
      >
        {props.alertNote}
      </Alert>
    </Box>
  )
}

export default CngAlertLabel
