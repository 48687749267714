import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { components } from 'cng-web-lib'
import Utils from 'src/shared/Utils'

const {
  button: { CngButton }
} = components

function SearchButton(buttonProps) {
  return (
    <CngButton
      color='primary'
      startIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
      {...buttonProps}
    >
      {buttonProps.label}
    </CngButton>
  )
}

export default SearchButton
