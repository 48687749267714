const CalistaAdvisoryKeys = {

    // General
    TITLE: 'calistaIntelligentAdvisory',
    MODULE_TITLE: "module-title",
    ADMIN_TITLE: "admin-title",

    // Sub module titles
    SUB_MODULE_TITLE: {
        WIZARD: 'tradeAdvisoryWizard',
        CALISTA_ADVISORY_PARTY_CONFIG: "calista-advisory-party-config",
        CALISTA_ADVISORY_SYSTEM_CONFIG: "calista-advisory-system-config",
        CALISTA_ADVISORY_COUNTRY: "calista-advisory-country",
        CALISTA_ADVISORY_FTA_MAPPING: "calista-advisory-fta-mapping",
        CIA_ADMIN_MASS_RECOMMEND: "cia-admin-mass-recommend"
    },

    TABS: {
        CIA_CONFIG: "tabs.cia-config",
        PRODUCT_LIST: "tabs.product-list"
    },

    FIELDS: {
        // Generic fields
        COUNTRY: "fields.country",
        CREATED_BY: "fields.created-by",
        CREATED_DATE: "fields.created-date",
        HS_CODE: "fields.hs-code",
        PARTY: "fields.party",
        PARTY_NAME: "fields.party-name",
        STATUS: "fields.status",
        TYPE: "fields.type",

        // Party Config
        CONTRACT_SETTINGS: "fields.contract-settings",
        CONTRACT_ACTIVE: "fields.contract-active",
        CONTRACT_START_DATE: "fields.contract-start-date",
        CONTRACT_END_DATE: "fields.contract-end-date",
        PARTY_SUMMARY: "fields.party-summary",
        TAS_IS_TAS_PARTY: "fields.tas-party",
        TAS_IS_SEARCH_RESTRICTED: "fields.search-restricted",
        TAS_SUBSCRIPTION_TYPE_CODE: "fields.subscription-type-code",
        TAS_RESTRICTED_COUNTRY_CODES: "fields.restricted-country-code",
        TAS_OUTOF_RESTRICTED_COUNTRY_SEARCH_ALLOWED: "fields.out-of-restricted-country-search-allowed",
        TAS_TRANSACTION_LIMIT_TYPE: "fields.transaction-limit-type",
        TAS_TRANSACTION_LIMIT_VALUE: "fields.transaction-limit-value",
        TAS_HS_CODE_SETTINGS: "fields.hs-code-settings",
        TAS_HS_CODE_API_ENABLED: "fields.hs-code-api-enabled",
        TAS_HS_CODE_TRANSACTION_LIMIT_TYPE: "fields.hs-code-transaction-limit-type",
        TAS_HS_CLASSIFICATION_LIMIT_VALUE: "fields.hs-classification-limit-value",
        TAS_COMPLIANCE_EADVISORY_SETTINGS: "fields.compliance-eadvisory-settings",
        TAS_COMPLIANCE_API_ENABLED: "fields.compliance-api-enabled",
        TAS_COMPLIANCE_SHOW_ROO_DETAILS: "fields.compliance-show-roo-details",
        TAS_COMPLIANCE_TRANSACTION_LIMIT_TYPE: "fields.compliance-transaction-limit-type",
        TAS_COMPLIANCE_DETAIL_LIMIT_VALUE: "fields.compliance-detail-limit-value",
        TAS_LANDED_COST_SETTINGS: "fields.landed-cost-settings",
        TAS_LANDED_COST_API_ENABLED: "fields.landed-cost-api-enabled",
        TAS_LANDED_COST_TRANSACTION_LIMIT_TYPE: "fields.landed-cost-transaction-limit-type",
        TAS_LANDED_C0ST_LIMIT_VALUE: "fields.landed-cost-limit-value",
        TAS_TRANS_LIMIT_FROM_DATE: "fields.trans-limit-from-date",
        TAS_TRANS_LIMIT_TO_DATE: "fields.trans-limit-to-date",
        TAS_COMPLIANCE_RESTRICTED: "fields.compliance-restricted",
        TAS_CIA_SUBSCRIPTION_TYPE_CODE: "fields.cia-subscription-type-code",
        TAS_CIA_SUBSCRIPTION_STATUS_CODE: "fields.cia-subscription-status-code",
        TAS_TRANS_LIMIT_ACTIVE: "fields.trans-limit-active",
        TAS_TRANS_LIMIT_USAGE: "fields.trans-limit-usage",
        SOURCE_COUNTRY_LIST_RULE_TYPE: "fields.source-country-list-rule-type",
        SOURCE_COUNTRY_LIST: "fields.source-country-list",
        DEST_COUNTRY_LIST_RULE_TYPE: "fields.dest-country-list-rule-type",
        DEST_COUNTRY_LIST: "fields.dest-country-list",
        HS_CODE_LIST_RULE_TYPE: "fields.hs-code-list-rule-type",
        HNG_DATA_FRESHNESS: "fields.hng-data-freshness",

        //System Config
        CONFIG_GROUP: "fields.config-group",
        CONFIG_CODE: "fields.config-code",
        DISPLAY_NAME: "fields.display-name",
        DESCRIPTION: "fields.description",
        VALUE: "fields.value",
        REMARKS: "fields.remarks",

        //Country Config
        COUNTRY_CODE: "fields.country-code",
        COUNTRY_NAME: "fields.country-name",
        ACTIVE: "fields.active",

        //FTA Mapping
        IMPORT_COUNTRY: "fields.import-country",
        EXPORT_COUNTRY: "fields.export-country",
        FTA_TMP: "fields.fta-tmp",
        FTA_AMBERROAD: "fields.fta-amberroad",

        // CIA ADMIN Management Fields
        DATE: "fields.date",
        TRANSACTION_TYPE: "fields.transaction-type",

        // CIA Admin - Mass Recommendation Fields.
        REQUEST_INFO: "fields.request-info",
        REQUEST_ROW_DETAILS: "fields.request-row-details",
        CONFIDENCE: "fields.confidence",
        HS_DETAILS: "fields.hs-details",
        INPUT_TEXT: "fields.input-text",
        ROW_REF: "fields.row-ref",
        STATUS_MESSAGE: "fields.status-message",
        COMPLETED_COUNT: "fields.completed-count",
        TOTAL_COUNT: "fields.total-count",
        HNG_TRACKING_REF: "fields.hng-tracking-ref",
        HNG_SYNC_STATUS: "fields.hng-sync-status"
    },

    ERRORS: {
        COUNTRY_ALPHA_2: "errors.country-alpha-2",
        FTA_MAX_LENGTH_255: "errors.fta-max-length-255"
    },

    CIA_ADMIN_MANAGEMENT: {
        // Labels for the filters
        FILTERS: {
            ALL: "cia-admin-management.filters.all",

            // Date Filter
            PAST_WEEK: "cia-admin-management.filters.past-week",
            PAST_30_DAYS: "cia-admin-management.filters.past-30-days",
            PAST_60_DAYS: "cia-admin-management.filters.past-60-days",
            CUSTOM_RANGE: "cia-admin-management.filters.custom-range",

            // Module Type Filter
            LANDED_COST: "cia-admin-management.filters.landed-cost",
            E_ADVISORY: "cia-admin-management.filters.e-advisory",
            HS_CODE_RECOMMENDATION: "cia-admin-management.filters.hs-code-recommendation",

            // Filter Errors
            PLEASE_SELECT_TRANSACTION_TYPE: "cia-admin-management.filters.please-select-transaction-type"

        }
    }
}

export default CalistaAdvisoryKeys