const TmpDeniedPartySearchKeys = {
    TMPDPS: "tmpDps",
    TITLE_PARTYSEARCH: "sectionTitle.deniedPartySearch",
    BREAD_PARTYSEARCH: "breadCrumbs.deniedPartySearch",
    FIELD_TYPE:"fieldLabels.type",
    FIELD_SEARCHNAME: "fieldLabels.searchName",
    FIELD_SEARCH: "fieldLabels.search",
    FIELD_PREVSEARCH: "fieldLabels.prevSearch",
    FIELD_RESULTS: "fieldLabels.results",
    FIELD_DENIEDPARTY: "fieldLabels.deniedParty",
    FIELD_SEARCH_MATCHES: "fieldLabels.searchMatches",
    FIELD_NO_RESULT: "fieldLabels.searchNoResult",
    PARTY_INFO: "partyInformation.partyInfo",
    PARTY_CITY: "partyInformation.city",
    PARTY_COUNTRY: "partyInformation.country",
    PARTY_NATIONALITY: "partyInformation.nationality",
    PARTY_BDATE: "partyInformation.birthDate",
    PARTY_SUPPINFO: "partyInformation.suppInfo",
    SOURCE_INFO: "sourceInformation.sourceInfo",
    SOURCE_SOURCE: "sourceInformation.source",
    SOURCE_SOURCELINK: "sourceInformation.sourceLink",
    SOURCE_RESTRICTSOURCE: "sourceInformation.restrictSource",
    SOURCE_EFFDATE: "sourceInformation.effectiveDate",
    SOURCE_EXPDATE: "sourceInformation.expirationDate",
    BTN_DOWNLOAD: "buttonLabel.downloadProf",
    BTN_BACK: "buttonLabel.backToSearch",
    BTN_SEARCH: "buttonLabel.search",
    BTN_COLLAPSE: "buttonLabel.collapse",
    BTN_EXPAND: "buttonLabel.expand",
    BTN_SEARCHAGAIN: "buttonLabel.searchAgain",
    TYPE_ALL: "typeLabel.all",
    TYPE_ENTITY: "typeLabel.entity",
    TYPE_INDIVIDUAL: "typeLabel.individual",
    TYPE_VESSEL: "typeLabel.vessel",
    VALIDATE_SEARCH_NAME: "validate.searchName",
    VALIDATE_SEARCH_TYPE: "validate.searchType",
    PAGINATION_SEARCH_NEW: "pagination.searchNew",
    PAGINATION_SEARCH_OLD: "pagination.searchOld",
    PAGINATION_NAME_ASC: "pagination.nameAsc",
    PAGINATION_NAME_DESC: "pagination.nameDesc",
    ERROR_SEARCH_FAIL: "error.searchFail",
    DIALOG_SEARCHAGAIN_TITLE: "dialog.searchAgain.dialogTitle",
    DIALOG_SEARCHAGAIN_CONTENT: "dialog.searchAgain.dialogContent",
    DIALOG_SEARCHAGAIN_ACTION_YES: "dialog.searchAgain.dialogActionYes",
    DIALOG_SEARCHAGAIN_ACTION_NO: "dialog.searchAgain.dialogActionNo"
}

export default TmpDeniedPartySearchKeys