const RiceStockKeys = {
  UPLOAD_IMPORTER: {
    TITLE: 'uploadImporter.title'
  },
  UPLOAD_PO: {
    TITLE: 'uploadPO.title'
  },
  UPLOAD_STOCK_DETAIL: {
    TITLE: 'uploadStockDetail.title'
  },
  POWER_BI_DASHBOARD: {
    IMPORTER_PROFILE: {
      TITLE: 'powerBIDashboard.importerProfile.title'
    },
    IMPORT_STATUS_BY_IMPORTER: {
      TITLE: 'powerBIDashboard.importStatusByImporter.title'
    },
    IMPORT_STATUS: { TITLE: 'powerBIDashboard.importStatus.title' },
    STOCKPILE: { TITLE: 'powerBIDashboard.stockpile.title' }
  },
  UPLOAD_RICESTOCKPILE_CONFIG: {
    TITLE: 'uploadConfig.title'
  },
  IMPORTER: {
    TITLE: 'importer.title',
    CRITERIA_QTY: 'importer.criteriaQty',
    IMPORT_QTY: 'importer.importQty',
    IMPORTER_ID: 'importer.importerId',
    MIQ: 'importer.miq',
    NAME: 'importer.name',
    PACKING_PLANT_AVAIL: 'importer.packingPlantAvail',
    PSQ: 'importer.psq',
    RICE_TYPE: 'importer.riceType',
    TYPE: 'importer.type'
  }
}

export default RiceStockKeys
