const PartyMappingKeys = {
	TITLE: "title",
	ID: "id",
	CARRIER_NAME: "carrierName",
	CREATED_BY: "createdBy",
	CREATED_DATE: "createdDate",
	EDI_ID: "ediId",
	ALIAS: "alias",
	PARTY_ID: "partyId",
	UPDATED_BY: "updatedBy",
	UPDATED_DATE: "updatedDate",
	OTHER_PARTY_ID: "otherPartyId",
	JSON_PROPERTIES: "jsonProperties",
	SHIPPER_PARTY_ID: "shipperPartyId",
	EDI_FILE_FORMAT: "ediFileFormat",
	SHIPPER_EDI_ID: "shipperEdiId",
	SHIPPER_ALIAS: "shipperAlias",
	MODULE_CODE: "moduleCode",
	PLATFORM: "platform",
	SHIPPER_PLATFORM: "shipperPlatform"
}

export default PartyMappingKeys
