import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ArchiveIcon from '@material-ui/icons/Archive';
const {
  button: { CngPrimaryButton, CngSecondaryButton },
} = components

function ArchiveButton(buttonProps) {

  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <CngSecondaryButton startIcon={<ArchiveIcon />} {...buttonProps}>{translatedTextsObject.archive}</CngSecondaryButton>
    </div>
  )

}

export default ArchiveButton
