const CompanyForShipperRequestKeys = {
  TITLE: "title",
  PARTY_ID: "partyId",
  CompanyShipperRequestType: {
    TITLE: "companyShipperRequestType.title",
    SHP_REQ_TYPE_ID: "companyShipperRequestType.shpReqTypeId"
  }
}

export default CompanyForShipperRequestKeys
