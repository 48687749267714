import {
  Card,
  CardContent,

  makeStyles,
  withStyles
} from '@material-ui/core'
import { TreeItem as MuiTreeItem, TreeView } from '@material-ui/lab'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import Header from './Header'
import TreeItem from './TreeItem'

const useStyles = makeStyles(theme => ({
  tree: {
    '& > li > .MuiTreeItem': {
      '&-content > .MuiTreeItem': {
        '&-label:before, &-container, &-iconContainer': { display: 'none' }
      },
      '&-group': {
        paddingLeft: 0,
        '&:before': { display: 'none !important' }
      }

    }
  },
  hidden: {
    display: 'none'
  }
}))

const removeChildrenFromExpanded = (node, newExpanded) => {
  if (node.children) {
    let children = node.children.map(n => n.id)
    newExpanded = newExpanded.filter(n => !children.includes(n))
    node.children.forEach(n => n.hide = false)
  }

  return [node, newExpanded]
}

function CngTreeView(props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(props.expanded || [])
  const [active, setActive] = useState(props.active || -1)

  useEffect(() => {
    setExpanded(props.expanded || [])
  }, [props.expanded])

  useEffect(() => setActive(props.active || -1), [props.active])

  const toggleOn = (node, parentNode, newExpanded) => {
    if (!props.showExpandedSiblings) {
      let siblings = (parentNode ? parentNode.children : props.data).map(n => n.id)
      newExpanded = newExpanded.filter(n => !siblings.includes(n))
      if (props.autoCascade) {
        while (node.children && node.children.length === 1) {
          newExpanded.push(node.id)
          parentNode = node
          node = node.children[0]
        }
      }
    }

    if (!newExpanded.includes(node.id))
      newExpanded.push(node.id)

    removeChildrenFromExpanded(node, newExpanded)

    return [node, parentNode, newExpanded]
  }

  const handleClick = (node, parentNode, setLoadingFn) => {
    let newExpanded = [...expanded]
    if (expanded.includes(node.id)) // to toggle OFF
      // use filter since sometimes things are added twice
      newExpanded = newExpanded.filter(n => n !== node.id)
    else
      [node, parentNode, newExpanded] = toggleOn(node, parentNode, newExpanded)

    if (props.hideInactiveSiblings && node.hasChildren) {
      (parentNode ? parentNode.children : props.data).filter(n => n.id !== node.id).forEach(n => {
        n.hide = newExpanded.includes(node.id)
      })

    }
    props.onToggle(node, active === node.id, newExpanded.includes(node.id), newExpanded, setLoadingFn)
    setExpanded(newExpanded)
    setActive(node.id)
  }

  return (<TreeView className={classes.tree}
    expanded={expanded}
  >
    {props.data.map(item => <TreeItem {...props}
      node={item} key={item.id}
      active={active} expanded={expanded}
      onClick={handleClick}
    />)}
  </TreeView>)
}

CngTreeView.defaultProps = {
  labelKey: 'name',
  onToggle: (node, isActive, isExpanded) => ([node, isActive, isExpanded])
}

CngTreeView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    children: PropTypes.array
  })).isRequired,
  labelKey: PropTypes.string,
  renderHeader: PropTypes.func,
  onToggle: PropTypes.func,

  showExpandedSiblings: PropTypes.bool,
  autoCascade: PropTypes.bool
}

export default CngTreeView
