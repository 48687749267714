import { Controller } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { MenuItem, TextField, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useServices, constants, components } from 'cng-web-lib'
const { NotificationType } = constants

const {
  form : {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField }
  }
} = components

function getErrorMessage(fieldMeta) {
  const { error } = fieldMeta
  return error
}

function hasError(fieldMeta) {
  const { invalid } = fieldMeta
  return invalid
}

function CngSelectField({
  name,
  label,
  multiple,
  disabled,
  items = [{ text: 'Loading...', value: '' }],
  fetch,
  ...textFieldProps
}) {
  const [itemsToBeUsed, setItemsToBeUsed] = useState(items)
  const { control } = useFormikContext()
  const { fetchRecords } = useServices()
  const defaultFetch = {
    url: undefined,
    body: {},
    textAccessor: 'id',
    valueAccessor: 'id',
    operation: fetchRecords,
    showNotification: undefined
  }

  if (fetch) {
    fetch = { ...defaultFetch, ...fetch }
  }

  useEffect(() => {
    if (fetch) {
      fetch.operation.execute(
        fetch.url,
        { data: fetch.body },
        (data) => {
          setItemsToBeUsed(
            data.content.map((datum) => {
              return {
                text: datum[fetch.textAccessor],
                value: datum[fetch.valueAccessor]
              }
            })
          )
        },
        () => {
          setItemsToBeUsed([{ text: 'Empty', value: '' }])
          fetch.showNotification(
            NotificationType.ERROR,
            'Fetch items has failed.'
          )
        }
      )

      return () => {
        fetch.operation.abort()
      }
    }
  }, [])

  useEffect(() => {
    setItemsToBeUsed(items)
  }, [items])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...field }, fieldState }) => (
        <HookFormDropdown
          field={field}
          fieldState={fieldState}
          name={name}
          label={label}
          multiple={multiple}
          disabled={disabled}
          inputRef={ref}
          items={itemsToBeUsed}
          {...textFieldProps}
        />
      )}
    />
  )
}

function HookFormDropdown({
  field,
  fieldState,
  label,
  multiple,
  disabled,
  items,
  onChange,
  onBlur,
  ...textFieldProps
}) {
  function onChangeWrapper(e) {
    field.onChange(e)
    if (onChange) {
      onChange(e)
    }
  }

  function onBlurWrapper(e) {
    field.onBlur(e)
    if (onBlur) {
      onBlur(e)
    }
  }

  return (
    <CngTextField
      id={field.name}
      label={label}
      disabled={disabled}
      helperText={getErrorMessage(fieldState)}
      error={hasError(fieldState)}
      select
      SelectProps={{
        classes: {
          root: 'select-props'
        },
        MenuProps: {
          MenuListProps: {
            classes: {
              root: 'menu-list'
            }
          }
        },
        multiple: multiple
      }}
      {...field}
      onChange={onChangeWrapper}
      onBlur={onBlurWrapper}
      {...textFieldProps}
    >
      {items.length > 0
        ? items.map((item, i) => makeMenuItem(item, i))
        : makeMenuItem({ text: 'No options', value: '', disabled: true })}
    </CngTextField>
  )
}

function makeMenuItem(item, key) {
  return (
    <MenuItem key={item.key || key} value={item.value} disabled={item.disabled}>
      {item.text}
    </MenuItem>
  )
}

export default CngSelectField

CngSelectField.defaultProps = {
  items: [{ text: 'Loading...', value: '' }]
}
CngSelectField.propTypes = {
  /**
   * Property name
   */
  name: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * Select options
   */
  items: PropTypes.array,
  /**
   * Fetch operation structure, E.g
   * **fetch={{ url: undefined,**
   * **body: {},**
   * **textAccessor: 'id',**
   * **valueAccessor: 'id',**
   * **operation: fetchRecords,**
   * **showNotification: undefined }}**
   */
  fetch: PropTypes.object
}
