import React from 'react'
import { Container } from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

/* 
Just a component that simulate the newer version Material-UI's Alert:
https://mui.com/components/alert/
*/
function WarningAlert({
    title,
    ...props
}) {

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const defaultTitle = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.WARNING)

    return (
        <Container
            disableGutters={true}
            style={{
                backgroundColor: 'rgb(255, 244, 229)',
                display: 'flex',
                padding: '1em',
                marginBottom: '1em',
                borderRadius: '0.5em'
            }}
        >
            <ReportProblemOutlinedIcon style={{ color: '#ff9800' }} />
            <Container style={{ color: 'rgb(102, 60, 0)' }}>
                <div><strong>{title || defaultTitle}</strong></div>
                <div>{props.children}</div>
            </Container>
        </Container>
    )
}

export default WarningAlert
