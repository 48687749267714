const CecAccountKeys = {
	CEC_ACCOUNT_TITLE: "cecAccountTitle",
	ACCOUNT_NUMBER: "accountNumber",
	DISPLAY_NAME: "displayName",
	STATUS: "status",
	SECRET_KEY: "secretKey",
	ADDRESS: "address",
	STREET: "street",
	CITY: "city",
	STATE: "state",
	STATE_OTHER: "stateOther",
	POSTCODE: "postCode",
	COUNTRY: "country",
	TYPE: "type",
	INTERNAL: "internal",
	PARTY_ID: "partyId",
	PARTY_NAME: "partyName",
	PERSON: "person",
	SALUTATION: "salutation",
	FIRST_NAME: "firstName",
	LAST_NAME: "lastName",
	EMAIL: "email",
	PHONE: "phone",
	DESIGNATION: "designation",
	IS_PRIMARY: "isPrimary",
	PERSON_TITLE: "personTitle",
	CEC_ACCOUNT_VIEW: "cecAccountView",
	CEC_ACCOUNT_EDIT: "cecAccountEdit"

}

export default CecAccountKeys