import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import RefreshIcon from '@material-ui/icons/Refresh'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';



const {
  button: { CngPrimaryButton, CngSecondaryButton },
} = components

const useStyles = makeStyles((theme) => ({

  secondStyle: {
    backgroundColor: "#5e81f419",
    borderRadius: "8px",
    borderColor: "#5e81f419",
    boxShadow: "0 2px 2px -2px rgba(94, 129, 244, 0.25)",
    color: "#FF808B",
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434',
    },
    '&:focus': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434',
    },
    '&:active': {
      backgroundColor: '#5e81f434',
      borderColor: '#5e81f434',
    },
    '&:linkButton': {
      padding: "6px 13px",

      '&:hover': {
        textDecoration: "none"
      }
    }
  }
}
))

function ResetButton(buttonProps) {

  const classes = useStyles();

  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div >
      <Button
        variant='contained'
        className={classes.secondStyle}
        startIcon={<RefreshIcon />}
        {...buttonProps}
      >
        {translatedTextsObject.reset}
      </Button>

    </div>
  )

}

export default ResetButton
