import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import React from 'react'
import SearchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls.js'
import { components } from 'cng-web-lib'
import makeStyles from '@material-ui/styles/makeStyles'

const {
  form: {
      adapter: {
          useFormAdapter:{ useField, useFormikContext }
      },
    field: { CngLookupAutocompleteField }
  }
} = components

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

function CarrierAutocompleteField({
  name,
  label,
  disabled,
  ...otherCngLookupAutocompleteFieldProps
}) {
  const classes = useStyles()
  const [field] = useField(name)
  return (
    <CngLookupAutocompleteField
      name={name}
      label={label}
      lookupProps={{
        label: 'name',
        url: SearchScheduleApiUrls.CARRIER_ENROLL_GET,
        value: 'partyId'
      }}
      renderOption={(option) => {
        const {
          data: { name, code }
        } = option
        return (
          <Box display='flex'>
            <Avatar
              alt={name}
              src={`${process.env.PUBLIC_URL}/static/images/carriers/${code}.svg`}
              className={classes.small}
              classes={{
                img: 'vs-carrier-image'
              }}
            >
              <img
                className={'search-button'}
                src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
              ></img>
            </Avatar>
            <Box ml={0.5}>{option.label}</Box>
          </Box>
        )
      }}
      textFieldProps={{
        InputProps: {
          customStartAdornment: (autocompleteStartAdornment, options) => {
            const { value } = field
            const matched = options
              .map((option) => option.data)
              .find((enrolledCarrier) => enrolledCarrier?.partyId === value)

            if (!matched) {
              return autocompleteStartAdornment
            }

            const { name, code } = matched
            return (
              <Avatar
                alt={name}
                src={`${process.env.PUBLIC_URL}/static/images/carriers/${code}.svg`}
                className={classes.small}
                classes={{
                  img: 'vs-carrier-image'
                }}
              >
                <img
                  className={'search-button'}
                  src={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                ></img>
              </Avatar>
            )
          }
        }
      }}
      disabled={disabled}
      {...otherCngLookupAutocompleteFieldProps}
    />
  )
}

export default CarrierAutocompleteField
