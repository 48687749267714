const NotificationSubscriptionKeys = {
  TITLE: "title",
  NTF_SUB_ID: "ntfSubId",
  EVENT_ID: "eventId",
  PARTY_ID: "partyId",
  ACTION_FOR: "actionFor",
  ALL_USER: "allUser",
  NTF_TYPE: "ntfType",
  RULE_PATH: "rulePath",
  ROLES: "roles",
  EMAILS: "emails",
  MOBILE_NO: "mobileNo",
  USERS: "users",
  TEMPLATE_CODE: "templateCode",
  EVENT_NAME: "eventName",
  NtfSubRule: {
    TITLE: "ntfSubRule.title",
    NTF_SUB_RULE_ID: "ntfSubRule.ntfSubRuleId",
    NTF_SUB_RULE_NAME: "ntfSubRule.ntfSubRuleName",
    RULE_FILTER: "ntfSubRule.ruleFilter",
    RULE_VALUE: "ntfSubRule.ruleValue"
  }
}

export default NotificationSubscriptionKeys
