const TmpCodeMasterKeys = {

	//------------CODE MASTER------------------
	TMP_CODE_MASTER: "tmpCodeMaster",
	TMP_CODE_MASTER_TITLE: "tmpCodeMasterTitle",
	TMP_CODE_MASTER_CREATE: "tmpCodeMasterCreate",
	TMP_CODE_MASTER_EDIT: "tmpCodeMasterEdit",
	TMP_CODE_MASTER_VIEW: "tmpCodeMasterView",
	TMP_CODE_MASTER_CODE_TYPE: "tmpCodeMasterCodeType",
	TMP_CODE_MASTER_CODE: "tmpCodeMasterCode",
	TMP_CODE_MASTER_NAME: "tmpCodeMasterName",
	TMP_CODE_MASTER_DESCRIPTION: "tmpCodeMasterDescription",
	TMP_CODE_MASTER_STATUS: "tmpCodeMasterStatus"

}

export default TmpCodeMasterKeys