import React from 'react'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'
import GreenSubmitButton from './GreenSubmitButton'

function GreenUpdateButton({
    children,
    ...props
}) {

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const updateText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.UPDATE);

    return (
        <GreenSubmitButton {...props}>
            {children || updateText}
        </GreenSubmitButton>
    )
}

export default GreenUpdateButton
