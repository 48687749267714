const EcommerceUploadKeys = {
	ECOMMERCE: "ecommerce",
	UPLOAD_FILE_TITLE: "uploadFileTitle",
	UPLOADED_FILE_TITLE: "uploadedFileTitle",
	
	UPLOAD_TYPE: "uploadType",
	SELECT_TEMPLATE: "selectTemplate",
	DOWNLOAD_TEMPLATE: "downloadTemplate",
	DESCRIPTION: "description",
	
	//Uploaded files table header
	FILENAME: "filename",
	TEMPLATE_TYPE: "templateType",
	UPLOAD_DATE: "uploadDate",
	NO_OF_ENTRIES: "noOfEntries",
	SYSTEM_RESPONSE: "systemResponse",
	STATUS: "status",
	ACTION: "action",

	DELETE: "delete",
	UPLOAD: "upload",
	DOWNLOAD: "download",
	UPLOADING: "uploading",
	COMPLETED: "completed",
	FAILED: "failed",

	DOWNLOAD_SUCCESS_MESSAGE: "downloadSuccessMessage",
	DOC_MESSAGE: "docMessage",
	ERROR_OCCUR_MESSAGE: "errorOccurMessage",
	UPLOAD_ERROR_MESSAGE: "uploadErrorMessage",
	SAVED_SUCCESS_MESSAGE: "savedSuccessMessage",
	SEARCH_MESSAGE: "searchMessage",

	CREATE_DO_TEMP_LABEL: "createDOTemplateLabel",
    CREATE_DE_TEMP_LABEL: "createDETemplateLabel",
    UPDATE_DO_TEMP_LABEL: "updateDOTemplateLabel",
    UPDATE_DE_TEMP_LABEL: "updateDETemplateLabel",
    CREATE_DO_TEMP_DESC: "createDOTemplateDesc",
    CREATE_DE_TEMP_DESC: "createDETemplateDesc",
    UPDATE_DO_TEMP_DESC: "updateDOTemplateDesc",
    UPDATE_DE_TEMP_DESC: "updateDETemplateDesc",


}

export default EcommerceUploadKeys