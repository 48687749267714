import React from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'cng-web-lib'
import { makeStyles } from '@material-ui/core/styles';

import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeLight)

const useStyles = makeStyles((theme) => ({

	secondStyle: {
		borderColor: '#ECECF2',
		color: 'red',
		textTransform: 'none',

		'&:hover': {
			backgroundColor: '#8996AF10',
			borderColor: '#ECECF2',
		}
	}
}))

function DeleteButton2({
	label = false,
	...props
}) {

	const classes = useStyles();

	const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
	const defaultLabel = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.DELETE);

	//* return JSX -------------------------------------------------------------
	return (

		//* Start JSX ----------------------------------------------------------
		<Button
			variant='outlined'
			color='secondary'
			className={classes.secondStyle}
			{...props}
		>
			<FontAwesomeIcon icon={['fal', 'trash']} />
			<span style={{ marginLeft: '0.5em' }}>{label || defaultLabel}</span>
		</Button>
		//* End JSX ------------------------------------------------------------
	);

	//* End of function --------------------------------------------------------
}

export default DeleteButton2
