const AccessLevelMasterKeys = {
  TITLE: "title",
  MODULE_ID: "moduleId",
  MENU_ID: "menuId",
  NAME: "name",
  CODE: "code",
  PARENT_ID: "parentId",
  ACTIVE: "active"
}

export default AccessLevelMasterKeys
