const UserForUserGroupKeys = {
  TITLE: "title",
  PARTY_ID: "partyId",
  LOGIN_ID: "loginId",
  UserSubscribedUserGroup: {
    TITLE: "userSubscribedUserGroup.title",
    USER_GROUP_MASTER_ID: "userSubscribedUserGroup.userGroupMasterId",
    ORG_CODE: "userSubscribedUserGroup.orgCode",
    USER_GROUP_CODE: "userSubscribedUserGroup.userGroupCode",
    USER_GROUP_NAME: "userSubscribedUserGroup.userGroupName",
    COMPANY_TYPE: "userSubscribedUserGroup.companyType"
  }
}

export default UserForUserGroupKeys
