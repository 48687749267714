const ReportManagementKeys = {
    TITLE: "title",
    REPORT_ID: "reportId",
    REPORT_NAME: "reportName",
    DESCRIPTION: "description",
    SHEET_NAME: "sheetName",
    QUERY: "query",
    SELECT_CRITERIA: "selectCriteria",
    SEQNO: "Sequence No",
    TYPE: "type",
    ACCESS_LEVEL_MASTER_CODE: "accessLevelMasterCode",
    DATE_RANGE_MAX_LIMIT: "dateRangeMaxLimit",
    RESULT_MAX_LIMIT: "resultMaxLimit",
    FILE_DOWNLOAD_OPTION : "File Download Option",
    ReportParameterList: {
        TITLE: "reportParameterList.title",
        DOWNLOAD_TITLE: "reportParameterList.downloadTitle",
        PARAMETER_ID: "reportParameterList.parameterId",
        PARAMETER_NAME: "reportParameterList.parameterName",
        PARAMETER_DISPLAY_NAME_EN: "reportParameterList.parameterDisplayNameEn",
        PARAMETER_DISPLAY_NAME_AR: "reportParameterList.parameterDisplayNameAr",
        PARAMETER_DISPLAY_NAME_ES: "reportParameterList.parameterDisplayNameEs",
        PARAMETER_DISPLAY_NAME_FR: "reportParameterList.parameterDisplayNameFr",
        PARAMETER_DISPLAY_NAME_ZH: "reportParameterList.parameterDisplayNameZh",
        PARAMETER_DISPLAY_NAME_PT: "reportParameterList.parameterDisplayNamePt",
        PARAMETER_DISPLAY_NAME_RU: "reportParameterList.parameterDisplayNameRu",
        PARAMETER_DISPLAY_NAME_HI: "reportParameterList.parameterDisplayNameHi",
        PARAMETER_DISPLAY_NAME_NL: "reportParameterList.parameterDisplayNameNl",
        PARAMETER_VALUE: "reportParameterList.parameterValue",
        FIELD_TYPE: "reportParameterList.fieldType",
        REGEX_VALIDATION: "reportParameterList.regexValidation"
    }
}

export default ReportManagementKeys
