const PortMappingKeys = {
  TITLE: "title",
  PARTY_ID: "partyId",
  CALISTA_PORT_CODE: "calistaPortCode",
  PARTY_PORT_CODE: "partyPortCode",
  PARTY_PORT_DESC: "partyPortDesc",
  CREATED_DATE: "createdDate"
}

export default PortMappingKeys
