import {
  Avatar,
  Box,
  Grid,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import { components } from 'cng-web-lib'
const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: {
      CngLookupAutocompleteField,
      CngTextField
    }
  }
} = components

const useStyles = makeStyles(theme => ({
  flags: {
    height: '1.2em', width: '1.2em',
    display: 'inline-block'
  },
  label: {
    overflowWrap: 'anywhere'
  }
}))

function CountryAutocompleteField({
  url, labelKey, valueKey,
  accessor, lookupProps,
  ...props
}) {
  const classes = useStyles()
  const { formState, getValues, setValue, register, watch } = useFormContext()
  const watchSelection = watch(props.name)
  const flag = React.useMemo(() => {
    // for non function use
  }, [watch])

  return (<CngLookupAutocompleteField
    {...props}
    renderOption={item => (<>
      <Box ml={-1.75} mr={.75}>
        <Avatar className={classes.flags}
          src={`${process.env.PUBLIC_URL}/static/images/countries/${item.value}.svg`}
          alt={`${item.label} flag`}
        >{item.value}</Avatar>
      </Box>
      <Box className={classes.label}>{item.label || item.value}</Box>
    </>)}
    lookupProps={{
      label: labelKey,
      value: valueKey,
      dataAccessor: accessor,
      url,
      ...lookupProps
    }}
    onChange={(e, val) => {
      setValue(props.name, val ? val.value : '')
      setValue(props.name+'_label', val ? val.label : '')
    }}
    textFieldProps={{
      InputProps: {
        customStartAdornment: (startAdornment) =>
          getValues(props.name) &&
            <Avatar className={classes.flags}
              src={`${process.env.PUBLIC_URL}/static/images/countries/${getValues(props.name)}.svg`}
              alt={`${getValues(props.name)} flag`}
            />
      }
    }}
  />)
}

CountryAutocompleteField.defaultProps = {
  // codeType: 'TAS_COUNTRY',
  valueKey: 'code',
  labelKey: 'name'
}

CountryAutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default CountryAutocompleteField
