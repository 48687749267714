import {
  Tab,
  Tabs,

  makeStyles
} from '@material-ui/core'
import React, { useState, useMemo, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  tabs: {
    borderRadius: 8,
    background: '#FBFBFB',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: theme.spacing(1, 4),
    minHeight: 'unset'
  },
  tabsActive: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.common.white
  },
  dNone: { display: 'none' }
}))

function CngTabBar(props) {
  const classes = useStyles()

  const [activeTab, setActiveTab] = useState(props.value || 0)

  useEffect(
    () => setActiveTab(props.value || 0),
    [props.value]
  )

  const handleTabChange = (e, i) => {
    setActiveTab(i)
    props.onActiveTabChanged(i)
  }

  const sentProps = useMemo(() => {
    let ret = {...props}
    delete ret.tabs
    delete ret.onActiveTabChanged
    delete ret.value

    return ret
  }, [props])

  return (<Tabs
    value={activeTab}
    onChange={handleTabChange}
    centered
    classes={{
      indicator: classes.dNone
    }}
    className={props.className}
    {...sentProps}
  >
    {props.tabs.map((tab, i) => (
      <Tab key={i}
        label={typeof tab === 'string' ? tab : tab.label}
        classes={{
          root: classes.tabs,
          selected: clsx(classes.tabsActive, tab.selectedClass)
        }}
        disabled={tab.disabled}
      />
    ))}
  </Tabs>)
}

CngTabBar.defaultProps = {
  onActiveTabChanged: activeTabIndex => activeTabIndex,
  value: 0,
  textColor: 'primary'
}

export default CngTabBar
