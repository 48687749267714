import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { components } from 'cng-web-lib'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import Utils from 'src/shared/Utils'

const {
  button: { CngButton }
} = components

function GoBackButton({ label = false, ...buttonProps }) {
  // Locale
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()
  const defaultGoBackLabel = CommonFtaTranslatedTextsObject.btnGoBack

  return (
    <CngButton
      color='secondary'
      startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
      {...buttonProps}
    >
      {label || defaultGoBackLabel}
    </CngButton>
  )
}

export default GoBackButton
