const CalistaUiComponentKeys = {
  MANDATORY_FIELDS: 'mandatoryFields',
  MANDATORY_FIELDS_WITHOUT_ASTERISK: 'mandatoryFieldsWithoutAsterisk',
  SHOW_OPTIONAL_FIELDS: 'showOptionalFields',
  HIDE_OPTIONAL_FIELDS: 'hideOptionalFields',
  OR_CLONE_PREVIOUS: 'orClonePrevious',
  UPLOAD_FILE_FEATURE: 'uploadFileFeature',
  LOADING: 'loading',
  ERROR_RETRIEVING_DATA: 'error-retrieving-data',
  ERROR_DELETING_DATA: 'error-deleting-data',
  CONTACT_ADMIN: 'contact-admin',

  SAVE_DRAFT: 'saveDraft',
  SAVE_CHANGES: 'saveChanges',
  SAVE_CHANGES_TO_DRAFTS: 'saveChangesToDrafts',
  CONFIRM_REVIEW: 'confirmReview',
  CONFIRM_NEXT: 'confirmNext',
  EDIT: 'edit',
  BACK: 'back',
  GO_BACK: 'goBack',
  SUBMIT: 'submit',
  SUBMIT_APPLY_CHANGES: 'submitApplyChanges',
  SUBMIT_WITHOUT_CHANGE: 'submitWithoutChange',
  SUBMIT_FOR_CONFIRMATION: 'submitForConfirmation',
  APPROVE_DISCHARD_CHANGES: 'apporveDischardChanges',
  SUBMIT_FOR_APPROVAL: 'submitForApproval',
  CONFIRM: 'confirm',
  CONFIRM_APPLY_CHANGES: 'confirmApplyChanges',
  CONFIRM_WITHOUT_CHANGE: 'confirmWithoutChange',

  SUBMIT_BOOKING: 'submitBooking',
  SUBMIT_AMENDMENTS: 'submitAmendments',
  VIEW_AMENDMENTS: 'viewAmendments',
  DISCARD: 'discard',

  OK: 'oK',
  CANCEL: 'cancel',
  COPY: 'copy',
  DELETE: 'delete',
  ARCHIVE: 'archive',
  ARCHIVED: 'archived',
  MANAGE: 'manage',
  CREATE_PERMIT: 'Create permit',

  BACK_TO_MANAGE_BOOKING: 'backToManageBooking',
  DECLINE: 'decline',
  AMEND: 'amend',
  APPROVE: 'approve',
  REJECT: 'reject',
  PRINT_DOCUMENT: 'Print Document',
  PRINT: 'print',

  VIEW_SI: 'viewSI',
  CREATE_SI: 'createSI',
  CREATE_DIRECT_SI: 'createDirectSI',
  TRACK_CONTAINERS: 'trackContainers',

  OR: 'or',
  PREV_VALUES: 'prevValues',
  NEW_VALUES: 'newValues',

  ADDED: 'added',
  REMOVED: 'removed',
  UPDATED: 'updated',
  AUDIT_TRAIL_REMARKS: 'auditTrailRemarks',
  SEQ_NO: 'seqNo',
  ACTION: 'action',
  ITEMS_SELECTED: 'itemsSelected',
  LAST_MODIFIED: 'lastModified',
  FORM_ERROR_WARNING: 'formErrorWarning',
  ADD_NEW: 'addNew',
  DUPLICATE: 'duplicate',
  MOVE_UP: 'moveUp',
  MOVE_DOWN: 'moveDown',
  AMEND_SCHEDULE: 'amendSchedule',

  CLOSE: 'close',
  RESET: 'reset',
  CREATE: 'create',
  UPDATE: 'update',

  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  APPLY: 'apply',

  HISTORY: 'history',
  ACCOUNT_EMAIL: 'account_email',
  ACTION_ON: 'action_on',
  DATE_TIME_GMT: 'date_time_gmt',
  DATE_TIME: 'date_time',
  COMPANY: 'company',
  STATUS: 'status',

  TEMPLATE_EXCEL: 'templateExcel',
  UPLOAD_FILE: 'uploadFile',
  EXPORT_CONTACTS_EXCEL: 'exportContactsExcel',
  ADD_NEW_CONTACT: 'addNewContact',
  ADD_NEW_TEMPLATE: 'addNewTemplate',

  FILE_NAME: 'fileName',
  FILE_SIZE: 'fileSize',
  TEMPLATE_NAME: 'templateName',
  CREATION_DATE: 'creationDate',

  RESULT_NOT_FOUND: 'resultNotFound',
  NO_FILTER_RESULT_DESC_1: 'noFilterResultDesc_1',
  NO_FILTER_RESULT_DESC_2: 'noFilterResultDesc_2',
  RESET_FILTER: 'resetFilter',

  NO_TAKE_ME_BACK: 'noTakeMeBack',
  YES_DELETE: 'yesDelete',
  YES_DISCARD: 'yesDiscard',

  FILTERS: 'filters',
  DOWNLOAD: 'download',
  DOWNLOAD_ERROR_LOG: 'downloadErrorLog',
  UPLOAD: 'upload',
  CLEAR_ALL: 'clearAll',
  UPLOAD_AND_VALIDATE: 'uploadAndValidate',
  UPLOAD_MORE_FILES: 'uploadMoreFiles',
  FAILED_TO_DOWNLOAD_FILE: 'failedToDownloadFile',
  DOWNLOAD_TEMPLATE: 'downloadTemplate',
  UPLOAD_TEMPLATE: 'uploadTemplate',
  SORT_BY: 'sortBy',

  ACCEPT: 'accept',
  BACK_TO_CREATE_PERMIT: 'Back to edit',
  SUBMIT_PERMIT: 'Submit permit'
}
export default CalistaUiComponentKeys
