import { Chip, Tooltip } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import useFetchCodeMaintainance from 'src/views/common/serviceHook/useFetchCodeMaintainance'

const { CodeMaintenanceType } = constants

const PortCountryViewField = (props) => {
  const { fetchRecords } = useServices()
  const [port, setPort] = useState([])
  // const [countryCode, setCountryCode] = useState()
  const [country, setCountry] = useState([])
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.PORT,
      [
        {
          field: 'code',
          operator: 'EQUAL',
          value: props.portCode == null ? '' : props.portCode
        }
      ],
      false,
      onLoadPortSuccess
    )
  }, [props])

  // function onLoadPortSuccess(data) {
  //     console.log("onLoadPortSuccess")
  //     console.log(data)
  //     if (data.length == 0) {
  //         console.log("null")
  //     }
  //     setPort(data)
  //     setShouldRender(true)
  // }

  function onLoadPortSuccess(data) {
    console.log('onLoadPortSuccess')
    console.log(data)
    if (data.length == 0) {
      console.log('null')
    }
    setPort(data)

    if (props.countryList != null && props.countryList.length > 0) {
      // console.log(props.countryList)
      let country =
        props.countryList[
          props.countryList.findIndex(
            (country) => country.code == props.portCode.substring(0, 2)
          )
        ]
      setCountry([country])
      setShouldRender(true)
    } else {
      CodeMaintainanceApi.fetchCodeMaintainance(
        fetchRecords,
        CodeMaintenanceType.COUNTRY,
        [
          {
            field: 'code',
            operator: 'EQUAL',
            value: props.portCode.substring(0, 2)
          }
        ],
        false,
        onLoadCountrySuccess
      )
    }
  }

  function onLoadCountrySuccess(data) {
    // console.log("onLoadCountrySuccess")
    console.log('onLoadCountrySuccess >' + JSON.stringify(data))
    // if (data.length == 0) {
    //     console.log("null")
    // }
    setCountry(data)
    setShouldRender(true)
  }

  if (!shouldRender) {
    return null
  }

  // alert(props.portCode)
  // console.log('PortCountryViewField' + JSON.stringify(country))
  console.log(
    'PortCountryViewField > country.length ' +
      country.length +
      ' country > ' +
      country[0]
  )

  return (
    <Tooltip
      title={
        country.length === 0
          ? '0'
          : country[0] !== undefined
          ? country[0].descriptionEn
          : port[0].code.substring(0, 2)
      }
      placement='top'
    >
      <div
        className={'rs-content'}
        style={{
          display: props.breakPortCode ? 'inline-block' : '',
          fontSize: 13,
          fontWeight: 600
        }}
        // style={props.breakPortCode ? {display:'inline-block'} : {}}
      >
        <div>
          <div>{port.length === 0 ? props.portCode : port[0].descriptionEn}</div>
        </div>
        <div>
          <span className='pl-10'>
            <Chip
              label={<b>{port.length === 0 ? props.portCode : port[0].code}</b>}
              size='small'
            />
          </span>
        </div>
      </div>
    </Tooltip>
  )
}

export default PortCountryViewField
