const NotificationTriggerKeys = {
  TITLE: "title",
  EVENT_ID: "eventId",
  FIRST_OPTION: "firstOption",
  SECOND_OPTION: "secondOption",
  THIRD_OPTION: "thirdOption",
  FORTH_OPTION: "forthOption",
  FIFTH_OPTION: "fifthOption"
}

export default NotificationTriggerKeys
