import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import { Chip } from '@material-ui/core'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import Utils from 'src/views/common/utils/Utils'
const { CodeMaintenanceType } = constants

const CountryIconViewField = (props) => {
  const { fetchRecords } = useServices()
  const [country, setCountry] = useState([])
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    if (!Utils.isEmpty(props.countryCode)) {
      CodeMaintainanceApi.fetchCodeMaintainance(
        fetchRecords,
        CodeMaintenanceType.COUNTRY,
        [
          {
            field: 'code',
            operator: 'EQUAL',
            value: props.countryCode
          }
        ],
        false,
        onLoadCountrySuccess
      )
    }
  }, [props])

  function onLoadCountrySuccess(data) {
    // console.log('onLoadPortSuccess')
    // console.log(data)
    if (data.length == 0) {
      console.log('null')
    }
    setCountry(data)
    setShouldRender(true)
  }

  if (!shouldRender) {
    return null
  }

  // console.log('CountryViewField' + JSON.stringify(country))

  return (
    <div className={'rs-content'}>
      <div>
        <img
          src={
            process.env.PUBLIC_URL +
            '/static/images/countries/' +
            (country.length === 0 ? '' : country[0].code) +
            '.svg'
          }
          className={'view-field-img'}
        />
      </div>
      <div>
        <div style={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
          {country.length === 0 ? '' : country[0].descriptionEn}
        </div>
      </div>
      <div>
        <span className='pl-10'>
          <Chip
            label={<b>{country.length === 0 ? '' : country[0].code}</b>}
            size='small'
          />
        </span>
      </div>
    </div>
  )
}

export default CountryIconViewField
