import React from 'react'
import { Button } from '@material-ui/core'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeLight)

function ArrowUpRightButton({
    label = false,
    ...props
}) {

    

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Button
            variant='contained'
            classes={{ root: 'ng-button-filled-primary' }}
            {...props}
        >
            <FontAwesomeIcon icon={['fal', 'arrow-up-right']} />
            <span style={{ marginLeft: '1em' }}>{label || "Please use your own label."}</span>
        </Button>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default ArrowUpRightButton
