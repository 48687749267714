const SinotransRegistrationKeys = {
  TITLE: "title",
  SHIPPER_PARTY_ID: "shipperPartyId",
  USER_CODE: "userCode",
  COMPANY_NAME: "companyName",
  COUNTRY: "country",
  TITLE_NAME: "titleName",
  COMPANY_ADDRESS: "companyAddress",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  TEL: "tel",
  EMAIL: "email",
  POSTCODE: "postcode",
  COMPANY_ROLE: "companyRole",
  USCC: "uscc",
  INDUSTRY: "industry",
  PRODUCT_INTEREST: "productInterest",
  STATUS: "status",
  REMARK: "remark"
}

export default SinotransRegistrationKeys
