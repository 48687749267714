import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: { CngSecondaryButton }
} = components

function CancelButton(buttonProps) {
  return <CngSecondaryButton {...buttonProps}>Cancel</CngSecondaryButton>
}


export default CancelButton
