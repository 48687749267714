import React, { useEffect, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import Utils from 'src/views/common/utils/Utils'

const { CodeMaintenanceType } = constants

const CodeMasterViewField = (props) => {
  // alert('shipmentType ' + JSON.stringify(props.code))
  const { fetchRecords } = useServices()
  const [codeMaster, setCodeMaster] = useState([])
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      props.codeType,
      false,
      onLoadCodeMasterSuccess
    )
  }, [props])

  // useFetchCodeMaster(props.codeType, false, onLoadCodeMasterSuccess)

  function onLoadCodeMasterSuccess(data) {
    //console.log('CodeMasterViewField' + JSON.stringify(data))
    let matched = data.find((m) => {
      // console.log(
      //   m.code + ' == ' + props.code + ' > ' + (m.code === props.code)
      // )
      return m.code === props.code
    })
    //console.log('matched', matched)
    setCodeMaster(matched)
    setShouldRender(true)
  }

  if (!shouldRender) {
    return null
  }

  return <div>{Utils.isEmpty(codeMaster) ? '-' : codeMaster.descriptionEn}</div>
}

export default CodeMasterViewField
