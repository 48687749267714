const NBSBillingConfigKeys = {
    TITLE: "NBS Billing Config",
    FIELDS: {
        //NBS BILLING CONFIG
        PARTY_ID: "fields.party-id",
        ACCT_ID: "fields.acct-id",
        CHARGE_CODE: "fields.charge-code",
        FIRST_POSTING: "fields.first-posting",
        LAST_POSTING: "fields.last-posting",
        ACTIVE: "fields.active",
    }
}

export default NBSBillingConfigKeys