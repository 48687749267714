const BillingAuditKeys = {
  TITLE: "title",
  AUDIT_TRAIL_ID: "auditTrailId",
  AUDIT_MODULE: "auditModule",
  AUDIT_DESCRIPTION: "auditDescription",
  USER_ID: "userId",
  AUDIT_DATE: "auditDate",
  KEY_REFERENCE_NO: "keyReferenceNo",
  FUNCTION_CODES: "functionCodes",
  AUDIT_REFERENCE_ID: "auditReferenceId",
  PARTY_ID: "partyId"
}

export default BillingAuditKeys
