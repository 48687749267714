const TmpCompanyProfileKeys = {

	//------------COMPANY PROFILE------------------
	TMP_COMPANY_PROFILE: "tmpCompanyProfile",
	TMP_COMPANY_PROFILE_TITLE: "tmpCompanyProfileTitle",
	TMP_COMPANY_PROFILE_CREATE: "tmpCompanyProfileCreate",
	TMP_COMPANY_PROFILE_EDIT: "tmpCompanyProfileEdit",
	TMP_COMPANY_PROFILE_VIEW: "tmpCompanyProfileView",
	TMP_COMPANY_PROFILE_NAME: "tmpCompanyProfileName",
	TMP_COMPANY_PROFILE_REG_NO: "tmpCompanyProfileRegisterNo",
	TMP_COMPANY_PROFILE_REF_NO: "tmpCompanyProfileReferenceNo",
	TMP_COMPANY_PROFILE_ADDRESS: "tmpCompanyProfileAddress"

}

export default TmpCompanyProfileKeys