import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))

function CloseDialogIconButton({ onClick, disabled, ...otherProps }) {
  const classes = useStyles()
  return (
    <IconButton
      aria-label='close'
      className={classes.closeButton}
      onClick={onClick}
      size='small'
      disabled={disabled}
      {...otherProps}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default CloseDialogIconButton
