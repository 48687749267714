const CompanyForUserGroupKeys = {
  TITLE: "title",
  PARTY_ID: "partyId",
  NAME: "name",
  CompanyUserGroup: {
    TITLE: "companyUserGroup.title",
    USER_GROUP_MASTER_ID: "companyUserGroup.userGroupMasterId",
    SHIPPER_REQUEST_TYPE_ID: "companyUserGroup.shipperRequestTypeId"
  }
}

export default CompanyForUserGroupKeys
