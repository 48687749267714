import {Box, Chip} from '@material-ui/core'

import React from 'react'
import SharedServiceApiUrls from 'src/apiUrls/SharedServiceApiUrls.js'
import { components, constants } from 'cng-web-lib'
import makeStyles from '@material-ui/styles/makeStyles'

const {
  form: {
      adapter: {
          useFormAdapter:{ useField, useFormikContext }
      },
    field: { CngLookupAutocompleteField }
  }
} = components

const {

  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

function ContainerTypeAutocompleteField({
  name,
  label,
  disabled,
  ...otherCngLookupAutocompleteFieldProps
}) {

  const [field] = useField(name)
  return (
    <CngLookupAutocompleteField
      name={name}
      label={label}
      lookupProps={{
        label: 'containerIsoType',
        url: SharedServiceApiUrls.GET_CONTAINER_TYPE,
        value: 'isoCode',
        filters: [{ field: 'isoCode', operator: otherCngLookupAutocompleteFieldProps.contTypeList? "IN" : "NOT IN", value: otherCngLookupAutocompleteFieldProps.contTypeList ? otherCngLookupAutocompleteFieldProps.contTypeList : []},{field: "active", operator: EQUAL, value: "true"}],
      }}
      renderOption={(option) => {
        return (
          <Box display='flex'>
            <Chip label={<b>{option.value}</b>} size='small' />
            <Box ml={0.5}>{option.label}</Box>
          </Box>
        )
      }}
      textFieldProps={{
        InputProps: {
          customStartAdornment: (autocompleteStartAdornment, options) => {
            const { value } = field
            const matched = options
              .map((option) => option.data)
              .find((container) => container?.iso_code === value)

            if (!matched && value) {
              return (
                <Box>
                    <div>
                      <Chip label={<b>{value}</b>} size='small' />
                    </div>
                </Box>
              )
            }

            return autocompleteStartAdornment
            
          }
        }
      }}
      disabled={disabled}
      {...otherCngLookupAutocompleteFieldProps}
    />
  )
}

export default ContainerTypeAutocompleteField
