const TcalVsVoyageKeys = {
  TITLE: 'title',
  VOYAGE_ID: 'voyageId',
  CREATED_BY: 'createdBy',
  CREATED_DATE: 'createdDate',
  UPDATED_BY: 'updatedBy',
  UPDATED_DATE: 'updatedDate',
  VERSION: 'version',
  REMARKS: 'remarks',
  SERVICE: 'service',
  VOYAGE_NO: 'voyageNo',
  VESSEL_ID: 'vesselId',
  PARTY_ID: 'partyId',
  SCHEDULE_DOC_ID: 'scheduleDocId',
  SCHEDULE_NO: 'scheduleNo',
  VOYAGE_STATUS: 'voyageStatus',
  INT_VOYAGE_NO: 'intVoyageNo',
  PORT_PAIR: 'portPair',
  ROUTE_ID: 'routeId',
  TRANSIT_TIME: 'transitTime',
  VoyageLeg: {
    TITLE: 'voyageLeg.title',
    VOYAGE_LEG_ID: 'voyageLeg.voyageLegId',
    CREATED_BY: 'voyageLeg.createdBy',
    CREATED_DATE: 'voyageLeg.createdDate',
    UPDATED_BY: 'voyageLeg.updatedBy',
    UPDATED_DATE: 'voyageLeg.updatedDate',
    VERSION: 'voyageLeg.version',
    ARRIVAL_TIME: 'voyageLeg.arrivalTime',
    DEPARTURE_TIME: 'voyageLeg.departureTime',
    PORT_CODE: 'voyageLeg.portCode',
    SEQ_NO: 'voyageLeg.seqNo',
    PARTY_ID: 'voyageLeg.partyId',
    TERMINAL_CODE: 'voyageLeg.terminalCode',
    TRANSPORT_MODE: 'voyageLeg.transportMode',
    SCH_VESSEL_NAME: 'voyageLeg.schVesselName',
    SCH_VOYAGE_NO: 'voyageLeg.schVoyageNo',
    SCH_SERVICE: 'voyageLeg.schService',
    TRANSIT_TIME: 'voyageLeg.transitTime',
    SOURCE: 'voyageLeg.source',
    CY_CUTOFF_TIME: 'voyageLeg.cyCutoffTime'
  },
  FEEDBACK: {
    SUBJECT: 'feedback.subject',
    BODY: 'feedback.body'
  }
}

export default TcalVsVoyageKeys
