const AccessLevelUserKeys = {
  TITLE: "title",
  USER_PROFILE_ID: "userProfileId",
  LOGIN_ID: "loginId",
  PARTY_ID: "partyId",
  AccessLevelUserRule: {
    TITLE: "accessLevelUserRule.title",
    COMPANY_CUSTOM_RULE_ID: "accessLevelUserRule.companyCustomRuleId"
  }
}

export default AccessLevelUserKeys
