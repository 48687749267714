import { Box, Card, Chip, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import React from 'react'
import PortViewField from 'src/components/field/PortViewField'
import { useLocation } from 'react-router-dom'
import Utils from 'src/views/common/utils/Utils'
const {
    CngGridItem
} = components

const AuditTrailDualColumnContainerType = (props) => {
    const {
        container,
        added,
        removed,
        containerTypes
    } = props
    const location = useLocation()


    const label = 'Type'
    const NO_VALUE = 'No values'
    let oldValue
    let newValue


    if (added) {
        oldValue = NO_VALUE
        newValue = container
    } else if (removed) {
        oldValue = container
        newValue = NO_VALUE
    } else {
        const values = Utils.getAuditTrailDiffs(container, ['contIsoType'])
        if(Utils.noChanges(values)) {
            return <div/>
        }
        newValue = values.valueNew[0] ? { contIsoType: values.valueNew[0] } : NO_VALUE
        oldValue = values.valueOld[0] ? { contIsoType: values.valueOld[0] } : NO_VALUE
    }

    const ContainerType = (props) => {
        const { type } = props
        // const containerTypes = location.state.ContainerTypes || []
        const matched = containerTypes.find((container) => container?.isoCode === type.contIsoType)

        return (
            <Box >
                <Chip label={type.contIsoType} size='small' className='white-chip' />
                &nbsp;<b>{matched ? matched.contSize : ''} {matched ? matched.contTypeDesc : ''}</b>
            </Box>
        )
    }
    return (
        <Grid container xs={12} sm={12} spacing={0}>
            <CngGridItem xs={6} sm={6}>
                {
                    oldValue === NO_VALUE &&
                    <LabelValueVertical label={label}
                        value={oldValue}
                        valueStyle={'greyLabel paddingLeft16'} />
                }
                {
                    oldValue !== NO_VALUE && (
                        <Box className="view-card-content">
                            <Box className="view-card-content-label"> {label} </Box>
                            <Box ml={2}>
                                <ContainerType type={oldValue} />
                            </Box>
                        </Box>
                    )
                }
            </CngGridItem>
            <CngGridItem xs={6} sm={6}>
                {
                    newValue === NO_VALUE &&
                    <LabelValueVertical label={label}
                        value={newValue}
                        valueStyle={'greyLabel paddingLeft16'} />
                }
                {
                    newValue !== NO_VALUE && (
                        <Box className="view-card-content">
                            <Box className="view-card-content-label"> {label} </Box>
                            <Box ml={2}>
                                <ContainerType type={newValue} />
                            </Box>
                        </Box>
                    )
                }
            </CngGridItem>
        </Grid>
    )
}

export default AuditTrailDualColumnContainerType
