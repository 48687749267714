import { TableCell, TableRow } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import { withStyles } from '@material-ui/core/styles';
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'

const {
    CngGridItem
} = components

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        fontWeight: "bolder"
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const styleOld = { color: '#FF808B' }
const styleNew = { color: '#7CE7AC' }

function addedReeferAuditSection(added, object) {
    return added && object['added'] != null &&
        object['added']['reefer'] != null &&
        object['added']['reefer']['added'] != null &&
        object['added']['reefer']['added'][0] != null;
}


function removedReeferAuditSection(removed, object) {
    return removed && object['removed'] != null &&
        object['removed']['reefer'] != null &&
        object['removed']['reefer']['removed'] != null &&
        object['removed']['reefer']['removed'][0] != null;
}

function formulateAuditMessage(object, str, field, tranObj) {
    //for booking module - get container quantity and iso type to display them along with the reefer audit details
    if (object['contQty'] != null) {
        str += object['contQty'] + ' x ';
    }

    if (object['contIsoType'] != null) {
        str += object['contIsoType'] + ' ';
    }

    //for si module - get container no to display them along with the reefer audit details
    if (object['contNo'] != null) {
        str += object['contNo'] + ' ';
    }

    if (field === 'nonActiveReefer') {
        str += tranObj.nonActiveReefer;
    }
    else if (field === 'equipmentTemp') {
        str += tranObj.equipmentTemp;
    }
    else if (field === 'tempUom') {
        str += tranObj.tempUom;
    }
    str += ': ';
    return str;
}

function checkReeferValue(reefer, field, value) {
    return reefer[field] != null && reefer[field][value] != null;
}

function generateValue(reefer, field, oldOrNewValue, str) {
    let result

    if (checkReeferValue(reefer, field, oldOrNewValue)) {
        result = str + reefer[field][oldOrNewValue];
    }
    return result;
}

function generateValueForChangesInAuditSection(object, field, str) {
    let reefer
    let oldValue, newValue, finalLabel, noChanges

    if (addedReeferUnderChangesSection(object)) {
        reefer = object['_changes_']['reefer']['added'][0]

        const values = Utils.getAuditTrailDiffs(reefer, [field])
        if (Utils.noChanges(values)) {
            noChanges = true
        }

        newValue = str + values.valueNew
        finalLabel = 'Added'
    }

    if (updatedReeferUnderChangesSection(object)) {
        reefer = object['_changes_']['reefer']['updated'][0]

        const values = Utils.getAuditTrailDiffs(reefer, [field])
        if (Utils.noChanges(values)) {
            noChanges = true
        }

        oldValue = str + values.valueOld
        //concatenating with a empty string as a possible value can be boolean true/false and this will prevent it being displayed in table below
        newValue = values.valueNew + ''
    }

    if (removedReeferUnderChangesSection(object)) {
        reefer = object['_changes_']['reefer']['removed'][0]

        const values = Utils.getAuditTrailDiffs(reefer, [field])
        if (Utils.noChanges(values)) {
            noChanges = true
        }
        oldValue = str + values.valueOld
        finalLabel = 'Removed 1'
    }

    return {oldValue: oldValue, newValue: newValue, finalLabel: finalLabel, noChanges: noChanges}
}

function addedReeferUnderChangesSection(object) {
    return object['_changes_'] != null &&
        object['_changes_']['reefer'] != null &&
        object['_changes_']['reefer']['added'] != null &&
        object['_changes_']['reefer']['added'][0] != null;
}

function updatedReeferUnderChangesSection(object) {
    return object['_changes_'] != null &&
        object['_changes_']['reefer'] != null &&
        object['_changes_']['reefer']['updated'] != null &&
        object['_changes_']['reefer']['updated'][0] != null;
}

function removedReeferUnderChangesSection(object) {
    return object['_changes_'] != null &&
        object['_changes_']['reefer'] != null &&
        object['_changes_']['reefer']['removed'] != null &&
        object['_changes_']['reefer']['removed'][0] != null;
}

const AuditTrailRowOfChangeReefer = (props) => {
    const {
        label,
        group,
        object,
        field,
        added,
        removed,
    } = props

    const tranObj = FreightBookingTranslationText()

    let oldValue
    let newValue

    if (object === null || object === undefined) return <div />

    let str = ''

    let finalLabel = label

    str = formulateAuditMessage(object, str, field, tranObj);

    if (addedReeferAuditSection(added, object)) {
        let reefer = object['added']['reefer']['added'][0]
        newValue = generateValue(reefer, field, 'new_value', str);
    }
    else if (removedReeferAuditSection(removed, object)) {
        let reefer = object['removed']['reefer']['removed'][0]
        oldValue = generateValue(reefer, field, 'old_value', str);
    }
    else {
        const result = generateValueForChangesInAuditSection(object, field, str)

        if (result.noChanges) return <div />
        
        oldValue = result.oldValue
        newValue = result.newValue
        finalLabel = result.finalLabel
    }

    if (Utils.objectsEqual(newValue, oldValue)) return <div />


    return (
        <StyledTableRow>
            <StyledTableCell>
                {
                    group && (
                        <>
                            <b>{group}</b>
                            <span class='blackDot'></span>
                            {finalLabel}:	&nbsp;
                        </>
                    )
                }

                {
                    !group && (
                        <>
                            <b>{finalLabel}</b>:	&nbsp;
                        </>
                    )
                }

                {oldValue &&
                    <span style={styleOld}>{oldValue}</span>
                }
                {
                    oldValue && newValue && ' > '
                }
                {
                    newValue &&
                    <span style={styleNew}>{newValue}</span>
                }

            </StyledTableCell>
        </StyledTableRow>
    )
}

export default AuditTrailRowOfChangeReefer

