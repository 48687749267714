const NBSFileKeys = {
    TITLE: "NBS File",
    FIELDS: {
        //NBS BILLING CONFIG
        CHARGE_CODE: "fields.charge-code",
        PARTY_ID: "fields.party-id",
        POSTING_DATE: "fields.posting-date",
        TIMESTAMP_GEN: "fields.timestamp-gen",
        STATUS: "fields.status",
        SUBMITTED_FILE_NAME: "fields.submitted-file-name",
        SUBMITTED_FILE: "fields.submitted-file",
        UPDATED_DATE: "fields.updated-date",
        RETURN_FILE_NAME: "fields.return-file-name",
        RETURN_FILE: "fields.return-file",
    }
}

export default NBSFileKeys