const AccessLevelRuleKeys = {
  TITLE: "title",
  NAME: "name",
  ACTIVE: "active",
  AccessLevelRulePermission: {
    TITLE: "accessLevelRulePermission.title",
    ACCESS_LEVEL_MASTER_ID: "accessLevelRulePermission.accessLevelMasterId",
    MODULE_CODE: "accessLevelRulePermission.moduleCode",
    MENU_CODE: "accessLevelRulePermission.menuCode",
    ACCESS_LEVEL_MASTER_CHILD_LIST: "accessLevelRulePermission.accessLevelMasterChildList",
  }
}

export default AccessLevelRuleKeys
