const SCORateMgmt = {
    MODULE_TITLE: "moduleTitle",
    CREATE_CONTRACT_TITLE: 'createContractTitle',
    VIEW_CONTRACT_TITLE: 'viewContractTitle',
    EDIT_CONTRACT_TITLE: 'editContractTitle',
    CONTRACT_TITLE: "contractTitle",
    CONTRACT_DETAILS_TITLE: "contractDetailsTitle",

    //Sub Title in Create/Edit/View Page
    CONTRACT_INFO_SUBTITLE: "contractInfoSubTitle",
    CONTRACT_DOC_SUBTITLE: "contractDocSubTitle",
    CONTRACT_RATECARD_SUBTITLE: "contractRateCardSubTitle",
    CONTRACT_COMMENT_SUBTITLE: "contractCommentSubTitle",
    CONTRACT_HISTORY_SUBTITLE: "contractHistorySubTitle",

    CONTRACT_STATUS_TITLE: "contractStatusTitle",
    
    //Contract Details in Create/Edit/View Page
    CONTRACT_NO: "contractNo",
    ISSUING_COMPANY: "issuingCompany",
    CUSTOMER_COMPANY: "customerCompany",
    VALID_FROM: "validFrom",
    VALID_TO: "validTo",
    TIMEZONE: "timezone",
    STATUS: "status",
    CONTRACT_DOC_INSTRUCTIONS: "documentInstruction",
    CONTRACT_DOC_ATTACH: "attachDocument",
    DISCARD_CONT_TITLE: "discardContTitle",
    DISCARD_CONT: "discardContChanges",
    UUID: "uuid",
    SERVICE_CODE: "serviceCode",
    SERVICE_NAME: "serviceName",
    SERVICE_DESC: "serviceDescription",
    SERVICE_TNC: "serviceTnc",
    CURRENCY: "currency",
    TAX_PERCENTAGE: "taxPercentage",
    PRICE_INC_TAX: "priceIncTax",
    CATEGORY: "category",

    RATE_COMPONENT: "rateComponent",
    RATE_COMPONENT_CODE: "rateComponentCode",
    RATE_COMPONENT_NAME: "rateComponentName",
    RATE_COMPONENT_DESC: "rateComponentDescription",

    FROM_LOC: "fromLoc",
    TO_LOC: "toLoc",
    FROM_COUNTRY: "fromCountry",
    TO_COUNTRY:"toCountry",
    TRANSPORT_MODE:"transportMode",

    CALCULATION_BY: "calculationBy",
    UOM: "uom",
    CALCULATION_METHOD: "calculationMethod",
    DIFF_TAX: "diffTax",
    DIFF_TAX_CODE: "diffTaxCode",
    DIFF_TAX_PERCENT: "diffTaxPercent",
    PRICE_INC_DIFF_TAX: "priceIncDiffTax",
    
    RATE: "rate",
    START_RANGE: "startRange",
    UNIT_PRICE: "unitPrice",

    //CONTRACT LIST
    CONTRACT_LIST_SEARCH_PLACEHOLDER: "contract-list-search-placeholder",

    BTN : {
        CREATE_CONT : 'btn.create-contract',
        APPLY_FILTER : 'btn.apply-filter',
        CLEAR_ALL : 'btn.clear-all'
    },

    // Labels for Tab
    TAB: {
        CONTRACT: 'tab.contract',
        CONTRACT_REC: 'tab.contract-rec',
        PUBLIC_RATE: 'tab.public-rate',
        PREFERENTIAL_RATE: 'tab.preferential-rate'
    },

    // Labels for titles
    FIELD: {
        FILTER: 'field.filter',
        DATE: 'field.date',
        STATUS: 'field.status',
        CUSTOMER: 'field.customer',
        ISSUER: 'field.issuer',
        SORT: 'field.sort'
    },

    // Labels for the filters
    FILTERS: {
        ALL: "filters.all",

        // Date Filter
        PAST_15_DAYS: "filters.past-15-days",
        PAST_30_DAYS: "filters.past-30-days",
        PAST_60_DAYS: "filters.past-60-days",

        // Status
        ST_DRAFT: "filters.st-draft",
        ST_ISSUED: "filters.st-issued",
        ST_ACTIVE: "filters.st-active",
        ST_REVOKED: "filters.st-revoked",
        ST_CANCELLED: "filters.st-cancelled",
        ST_REVOCATION: "filters.st-revocation",

        // Filter Errors
        PLEASE_SELECT_STATUS: "filters.please-status"
    },

    // Labels for sorting
    SORT: {
        UPDATED_DATE_DESC: "sort.updated-date-desc",
        UPDATED_DATE_ASC: "sort.updated-date-asc",
        VALID_FROM_DESC: "sort.valid-from-desc",
        VALID_FROM_ASC: "sort.valid-from-asc",
        VALID_UNTIL_DESC: "sort.valid-until-desc",
        VALID_UNTIL_ASC: "sort.valid-until-asc",
        ISSUER_ASC: "sort.issuer-asc",
        ISSUER_DESC: "sort.issuer-desc",
        CUSTOMER_ASC: "sort.customer-asc",
        CUSTOMER_DESC: "sort.customer-desc",
        REASON_ASC: "sort.reason-asc",
        REASON_DESC: "sort.reason-desc",
        REMARKS_ASC: "sort.remarks-asc",
        REMARKS_DESC: "sort.remarks-desc",
        //public rate
        SERVICE_CODE_ASC: "sort.service-code-asc",
        SERVICE_CODE_DESC: "sort.service-code-desc",
        SERVICE_NAME_ASC: "sort.service-name-asc",
        SERVICE_NAME_DESC: "sort.service-name-desc",
    }

}
export default SCORateMgmt