import { Box, Chip, Grid } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'

import React from 'react'
import SharedServiceApiUrls from 'src/apiUrls/SharedServiceApiUrls.js'
import TcalVsVesselApiUrls from 'src/apiUrls/TcalVsVesselApiUrls'
import makeStyles from '@material-ui/styles/makeStyles'
import { withStyles } from '@material-ui/core/styles'

const {
  form: {
      adapter: {
          useFormAdapter:{ useField, useFormikContext }
      },
    field: { CngLookupAutocompleteField }
  }
} = components

const {
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

const AsteriskRedCngLookupAutocompleteField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngLookupAutocompleteField)

function VesselAutocompleteField({
  name,
  label,
  disabled,
  onChangeAutoComplete,
  ...otherCngLookupAutocompleteFieldProps
}) {
  const [field] = useField(name)
  return (
    <AsteriskRedCngLookupAutocompleteField
      name={name}
      label={label}
      onChange={onChangeAutoComplete}
      lookupProps={{
        label: 'name',
        url: TcalVsVesselApiUrls.GET,
        value: 'id',
        filters: [
          {
            field: 'status',
            operator: EQUAL,
            value: true
          }
        ]
      }}
      renderOption={(option) => {
        return (
          // <Box display='flex'>
          //   <Box ml={0.5}>{option.data.name}</Box>
          //   <Box ml={0.5}>({option.data.imoNo})</Box>
          // </Box>
          <Grid container spacing={1}>
            <Grid item xs={9} sm={9}>
              <Box ml={0.5}>{option.data.name}</Box>
            </Grid>
            <Grid item xs={3} sm={3}>
              <span>
                <Chip label={<b>{option.data.imoNo}</b>} size='small' />
              </span>
            </Grid>
          </Grid>
        )
      }}
      // textFieldProps={{
      //   label : 'name'
      // }}
      disabled={disabled}
      {...otherCngLookupAutocompleteFieldProps}
    />
  )
}

export default VesselAutocompleteField
