import React from 'react'
import { Container, CircularProgress, Typography } from '@material-ui/core'

import { useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'

function LoadingSpinnerLabel({
    ...props
}) {

    const { translate } = useTranslation(Namespace.CALISTA_UI_COMPONENT)
    const loadingText = translate(Namespace.CALISTA_UI_COMPONENT, CalistaUiComponentKeys.LOADING);

    return (
        <Container {...props}>
            <CircularProgress />
            <Typography style={{ display: 'inline', marginLeft: '1em' }}>
                {loadingText}
            </Typography>
        </Container>
    )
}

export default LoadingSpinnerLabel
