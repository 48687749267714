const ClogsConfigKeys = {
  TITLE: "title",
  CONFIG_FOR: "configFor",
  CONFIG_VALUE1: "configValue1",
  CONFIG_VALUE2: "configValue2",
  CONFIG_VALUE3: "configValue3",
  CONFIG_DESCRIPTION: "configDescription",
  MODULE_NAME: "Module Name",
  CLOGS_ADMIN: "CLOGS Admin"
}

export default ClogsConfigKeys
