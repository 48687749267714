const TcalVsManageScheduleKeys = {
  TITLE: 'title',
  MANAGE_SCHEDULE_TITLE: 'manageScheduleTitle',
  ADD_SCHEDULE_TITLE: 'addScheduleTitle',
  VIEW_SCHEDULE_TITLE: 'viewScheduleTitle',
  AMEND_SCHEDULE_TITLE: 'amendScheduleTitle',
  VOYAGE_LIST: 'voyageList',
  SUBMITTED_FILE_LIST: 'submittedFileList',
  VIEW_UPLOAD_SCHEDULE_TITLE: 'viewUploadScheduleTitle',

  FILLED_UP_FORMS: 'filledUpForms',
  SUBMITTED_FILES: 'submittedFiles',
  DOWNLOAD_TEMPLATE: 'downloadTemplate',

  DOCUMENTS: 'documents',
  SEQNO: 'seqNo',
  VESSEL_NAME: 'vesselName',
  VOYAGE_NO: 'voyageNo',
  INT_VOYAGE_NO: 'intVoyageNo',
  REMARKS: 'remarks',
  SERVICE: 'service',
  SCHEDULE_NO: 'scheduleNo',
  USER: 'user',
  LAST_MODIFIED: 'lastModified',
  ACTION: 'action',
  SEARCH_DESC: 'searchDesc',

  ADD_SCHEDULE_STEP1: 'addScheduleStep1',
  ADD_SCHEDULE_STEP2: 'addScheduleStep2',
  ADD_SCHEDULE_STEP3: 'addScheduleStep3',
  AMEND_SCHEDULE_STEP1: 'amendScheduleStep1',
  AMEND_SCHEDULE_STEP2: 'amendScheduleStep2',
  AMEND_SCHEDULE_STEP3: 'amendScheduleStep3',
  VESSEL_VOYAGE_INFO: 'vesselVoyageInfo',
  VOYAGE_STATUS: 'voyageStatus',
  VOYAGE_NUMBER: 'voyageNumber',
  INTERNATIONAL_VOYAGE_NO: 'internationalVoyageNo',
  IMO_NUMBER: 'imoNumber',
  ADDITIONAL_VESSEL_VOYAGE_DETAILS: 'additionalVesselVoyageDetails',
  ADD_REMARKS: 'addRemarks',
  SUBMIT_SCHEDULE: 'submitSchedule',
  ERROR_SUBMITTING_SCHEDULE: 'errorSubmittingSchedule',
  ADD_FILE_UPLOAD_SCHEDULE_STEP_1: 'addFileUploadScheduleStep1',

  DIALOG_DISCARD_SCHEDULE_CONTENT: 'dialogDiscardScheduleContent',
  DIALOG_DISCARD_AMEND_SCHEDULE_CONTENT: 'dialogDiscardAmendScheduleContent',
  DIALOG_DISCARD_SCHEDULE_TITLE: 'dialogDiscardScheduleTitle',

  SCHEDULE_INFORMATION: 'scheduleInformation',
  ADD_SCHEDULE_STEP2_NOTE: 'addScheduleStep2Note',
  WAY_POINT: 'wayPoint',
  STARTING_POINT: 'startingPoint',
  STOPOVER_POINT: 'stopOverPoint',
  FINAL_POINT: 'finalPoint',
  TRANSPORTATION_MODE: 'transportationMode',
  TRANSPORT_MODE: 'transportMode',
  PORT: 'port',
  DEPART_DATE: 'departDate',
  DEPART_TIME: 'departTime',
  ARRIVAL_DATE: 'arrivalDate',
  ARRIVAL_TIME: 'arrivalTime',
  CYCUTOFF_DATE: 'cyCutoffDate',
  CYCUTOFF_TIME: 'cyCutoffTime',
  TRANSIT_TIME: 'transitTime',
  TERMINAL: 'terminal',
  ADDITIONAL_WAYPOINT_DETAILS: 'additionalWaypointDetails',
  TRANSPORTATION_MODE_TOOTIPS: 'tansportationModeTooltips',

  DELETE_RECORDS: 'deleteRecords',
  NO_TAKE_ME_BACK: 'noTakeMeBack',
  YES_DELETE: 'yesDelete',
  D1: 'D1',
  D2: 'D2',
  D3: 'D3',
  ITEMS: 'items',
  DELETE_SUCESS: 'deleteSuccess',
  ADD_SUCCESS: 'addSuccess',

  SUBMIT_CHANGES: 'submitChanges',
  SCHEDULE_SUBMITTED: 'scheduleSubmitted',

  VESSEL_SERVICE: 'vesselService',
  CY_CUTOFF: 'cyCutOff',
  DEPARTS: 'departs',
  ARRIVES: 'arrives',
  TRANSPORT_VIA: 'transportVia',
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
  VESSEL_VOYAGE_DETAILS: 'vesselVoyageDetails',
  DAYS: 'days',
  TRANSPORT: 'transport',
  SAVE_SCHEDULE_SUCCESS : 'saveScheduleSuccess',
  AMEND_SCHEDULE_SUCCESS: 'amendScheduleSuccess',
  SEARCH_DESC_SCHEDULE_INFO : 'searchDescScheduleInfo',
  BACK_TO_MANAGE_SCHEDULE: 'backToManageSchedule',
  BACK_TO_SEARCH_SCHEDULE: 'backToSearchSchedule',

  VESSEL_SERVICE: 'vesselService',
  CY_CUTOFF: 'cyCutOff',
  DEPARTS: 'departs',
  ARRIVES: 'arrives',
  TRANSPORT_VIA: 'transportVia',
  ARRIVAL: 'arrival',
  DEPARTURE: 'departure',
  VESSEL_VOYAGE_DETAILS: 'vesselVoyageDetails',
  DAYS: 'days',
  TRANSPORT: 'transport',

  SEARCH_DESC_SUB_FILE: 'searchDescSubFile',
  NOFILESYET: 'noFilesYet',
  UPLOADESC: 'uploadDesc',
  UPLOADDETAILS: 'uploadDetails',
  UPLOADFILELIMITDETAILS: 'uploadFileLimitDetails',
  OPEN: 'open',
  CLOSED: 'closed',
  SUSPENDED: 'suspended'
}

export default TcalVsManageScheduleKeys
