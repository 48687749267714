const ConnectorConfigKeys = {
    // Connector App Listing
    "CONNECTOR_CONFIGURATION": "Connector Configuration",
    "CREATE_CONNECTOR": "Create Connector",
    "EDIT_CONNECTOR": "Edit Connector Details",
    "VIEW_CONNECTOR": "Connector Details",
    "CONNECTOR_NAME": "Connector Name",
    "CONNECTOR_CODE": "Connector Code",
    "CONNECTOR_SERVICE_URL": "Connector Service URL",
    "CONNECTOR_APP_TYPE" : "Application Type",
    //Connector Details
    "CONNECTOR_DETAILS" : "Connector Details",
    //Connector Endpoints
    "CONNECTOR_ENDPOINTS" : "Connector Endpoints",
    "CONNECTOR_ENDPOINT_NAME" : "Endpoint Name",
    "CONNECTOR_ENDPOINT_CODE" : "Endpoint Code",
    "CONNECTOR_ENDPOINT_URL" : "Endpoint URL",
    //Connector Credentials
    "CONNECTOR_CREDENTIALS" : "Connector Credentials",
    "CREDENTIAL_NAME" : "Name",
    "IS_ACTIVE" : "Active",
    "KEY" : "Key",
    "ACCOUNT_NUMBER" : "Account Number",
    "PASSWORD" : "Password",
    "EXISTING_PASSWORD" : "Existing Password",
    "METER_NUMBER" : "Meter Number",
    "SECRET" : "Secret",
    //Connector Mappings
    "CREATE_MAPPING": "Create Mapping",
    "EDIT_MAPPING": "Edit Mapping Details",
    "VIEW_MAPPING": "Mapping Details",
    "CONNECTOR_MAPPINGS" : "Connector Mappings",
    "CONNECTOR_MAPPING_NAME" : "Name",
    "CONNECTOR_MAPPING_INPUT_SOURCE" : "Input Source",
    "CONNECTOR_MAPPING_OUTPUT_SOURCE" : "Output Source",
    //Connector Mapping Items
    "CONNECTOR_MAPPING_ITEMS" : "Connector Mapping Items",
    "CONNECTOR_MAPPING_ITEM_INPUT" : "Input",
    "CONNECTOR_MAPPING_ITEM_OUTPUT" : "Output"

}
export default ConnectorConfigKeys