const BookingSyncLogKeys = {
  TITLE: "title",
  SOURCE: "source",
  DESTINATION: "destination",
  DOCKEY: "dockey",
  MIGRATION_TIME: "migrationTime",
  RETRY: "retry",
  START_TIME: "startTime",
  END_TIME: "endTime",
  PROCESS_STATUS: "processStatus",
  ERROR_LOG: "errorLog",
  CONTENT: "content",
  PROCESS_RESPONSE: "processResponse"
}

export default BookingSyncLogKeys
