const ForwardHubKeys = {
  TITLE_PROD_MGT: "title_prod_mgt",
  USER_ID: "userId",
  PRODUCT_CODE: "productCode",
  PRODUCT_DESC: "productDesc",
  PRODUCT_CATEGORY_CODE: "productCategoryCode",
  PRODUCT_CATEGORY_DESC: "productCategoryDesc",
  DG_CLASS_CODE: "dgClassCode",
  HANDLING_INSTR_CODE: "handlingInstrCode",
  STATUS_CODE: "statusCode",
  HS_CODE: "hsCode",
  ORG_CODE: "orgCode",
  CUSTOMER_GROUP_CODE: "customerGroupCode",
  CUSTOMER_GROUP: "customerGroup",
  PROD_SHORT_NAME: "prodShortName",
  CARGO_DESC: "cargoDesc",
  SAFETY_STOCK_LEVEL: "safetyStockLevel",
  TITLE_INBOUND: "title_inbound",
  TITLE_INBOUND_LATE: "title_inbound_late",
  TITLE_INBOUND_REINSTATE: "title_inbound_reinstate",
  TITLE_STOCK_SEARCH: "title_stock_search",
  TITLE_STOCK_UPLOAD: "title_stock_upload",
  TITLE_DASHBOARD_ENHANCED: "title_dashboard_enhanced",
  TITLE_DASHBOARD_BASIC: "title_dashboard_basic",
  SEARCH_PROD_CODE: "searchProdCode",
  TITLE_COMMON_CONFIG: "common_config",

  DASH_STATUS_TITLE: "Dashboard Load Status",
  DASH_STATUS_LOAD_STATUS_ID: "ID",
  DASH_STATUS_CREATED_BY: "Created By",
  DASH_STATUS_CREATED_DATE: "Created Date",
  DASH_STATUS_UPDATED_BY: "Updated By",
  DASH_STATUS_UPDATED_DATE: "Updated Date",
  DASH_STATUS_LOAD_TASK: "Load Task",
  DASH_STATUS_LOAD_STATUS: "Load Status",
  DASH_STATUS_LAST_RUN: "Last Run",
  DASH_STATUS_LAST_COMPLETE: "Last Complete",
  DASH_STATUS_ORG_CODE: "Org Code",
  DASH_STATUS_CUSTOMER_GROUP_CODE: "Customer Group Code",

    DASH_USER_STATUS_TITLE: "Dashboard User Load Status",
    DASH_USER_STATUS_LOAD_STATUS_ID: "ID",
    DASH_USER_STATUS_CREATED_BY: "Created By",
    DASH_USER_STATUS_CREATED_DATE: "Created Date",
    DASH_USER_STATUS_UPDATED_BY: "Updated By",
    DASH_USER_STATUS_UPDATED_DATE: "Updated Date",
    DASH_USER_STATUS_LOAD_TASK: "Load Task",
    DASH_USER_STATUS_LOAD_STATUS: "Load Status",
    DASH_USER_STATUS_LAST_RUN: "Last Run",
    DASH_USER_STATUS_LAST_COMPLETE: "Last Complete",
    DASH_USER_STATUS_ORG_CODE: "Org Code",
    DASH_USER_STATUS_USER_ID: "User ID",
    DASH_USER_STATUS_CUSTOMER_GROUP_CODE: "Customer Group Code"

}

export default ForwardHubKeys


