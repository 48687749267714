const HelpdeskKeys = {
    TITLE: "title",
    VIEW_BOOKING: "viewBooking",
    BOOKING_DETAILS: "bookingDetails",
    BACK_TO_VIEW_BOOKING: "backToViewBooking",
    VIEW_SI:"viewSi",
    SI_DETAILS: "siDetails",
    BACK_TO_VIEW_SI: "backToViewSI",
    SEARCH_SI:"searchSi"
}
export default HelpdeskKeys