import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
const {
  button: { CngPrimaryButton, CngSecondaryButton },
} = components

function ViewSIButton(buttonProps) {

  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <CngSecondaryButton  {...buttonProps}><VisibilityOutlinedIcon />{translatedTextsObject.viewSI}</CngSecondaryButton>
    </div>
  )

}

export default ViewSIButton
