import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'

import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import { withStyles } from '@material-ui/core/styles'

const { CodeMaintenanceType } = constants
const {
  form: {
    field: { CngSelectField }
  }
} = components

const AsteriskRedCngSelectField = withStyles({
  root: {
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red'
    }
  }
})(CngSelectField)

const TransportationModeSelect = (props) => {
  const {
    name,
    label,
    onChange,
    extraOption,
    index,
    fieldName,
    error,
    helperText
  } = props

  const { fetchRecords } = useServices()
  const [transportMode, setTransportMode] = useState([])
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      'CAL_TRANSPORT_MODE',
      true,
      onLoadCodeMasterSuccess
    )
  }, [props])

  function onLoadCodeMasterSuccess(data) {
    // console.log('TransportationModeSelect' + JSON.stringify(data))
    // console.log('TransportationModeSelect' + JSON.stringify(extraOption))

    // extraOption
    if (extraOption) {
      data = [...extraOption, ...data]
      console.log('TransportationModeSelect' + JSON.stringify(data))
    }

    setTransportMode(data)
    setShouldRender(true)
  }

  if (!shouldRender) {
    return null
  }

  return (
    <AsteriskRedCngSelectField
      name={name}
      label={label}
      //no need value and on change if using formik's useField
      // value={value}
      //   onChange={(e) => {
      //     if(onChange !== undefined){
      //       onChange(e.target.value)
      //     }
      //   }}
      items={transportMode}
      onChange={(e) => onChange(e.target.value, index, fieldName)}
      error={error}
      helperText={helperText}
      required
    />
  )
}

export default TransportationModeSelect
