const ReportKeys = {
    MODULE_TITLE: "module-title",
    HEADER: {
        SUMMARY: "header.filter-criteria",
    },
    FIELDS: {
        DELIVERY_EVENT_START_DATE: "fields.delivery-event-start-date",
        DELIVERY_EVENT_END_DATE: "fields.delivery-event-end-date",
        CREATION_START_DATE: "fields.creation-start-date",
        CREATION_END_DATE: "fields.creation-end-date",
        DO_REF_NO: "fields.do-ref-no",
        DO_SYS_NO: "fields.do-sys-no",
        TRACKING_NO: "fields.tracking-no",
        LAST_MILE_PROVIDER: "fields.last-mile-provider",
        DELIVERY_EVENT: "fields.delivery-event",
        DO_STATUS: "fields.do-status",
        ADV_MODE: "fields.adv-mode"
    }
}

export default ReportKeys