import {
  StepLabel,

  makeStyles
} from '@material-ui/core'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import CngStepIcon from './icon'

const useStyles = makeStyles(theme => ({
  bold: { fontWeight: 'bold !important' }
}))

function CngStep(props) {
  const classes = useStyles()

  return (<StepLabel {...props}
    classes={{
      active: classes.bold
    }}
    StepIconComponent={CngStepIcon}
  >
    {props.label || props.children}
  </StepLabel>)
}

export default CngStep
