import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons'
import { makeStyles } from '@material-ui/core/styles'
import { components } from 'cng-web-lib'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import Utils from 'src/shared/Utils'

const negativeButtonStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.error,
    borderColor: theme.palette.background.ctaBtnActiveBorder,
    borderRadius: '8px',
    textTransform: 'none !important',

    '&:hover': {
      backgroundColor: Utils.hexToRgba(
        theme.palette.background.ctaBtnNormalText,
        0.1
      ),
      borderColor: theme.palette.background.ctaBtnActiveBorder
    },

    '& svg + .text': {
      marginLeft: '8px',
      marginInlineStart: '8px'
    }
  }
}))

const {
  button: { CngButton }
} = components

function ClearFieldsButton(buttonProps) {
  const negativeButtonCss = negativeButtonStyles()

  // Locale
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  return (
    <CngButton
      variant='outlined'
      startIcon={<FontAwesomeIcon icon={faArrowsRotate} />}
      className={negativeButtonCss.root}
      {...buttonProps}
    >
      {CommonFtaTranslatedTextsObject.btnClearFields}
    </CngButton>
  )
}

export default ClearFieldsButton
