const AccessLevelCompanyCustomRuleKeys = {
  TITLE: "title",
  NAME: "name",
  CompanyCustomRulePermission: {
    TITLE: "companyCustomRulePermission.title",
    MODULE_CODE: "companyCustomRulePermission.moduleCode",
    MENU_CODE: "companyCustomRulePermission.menuCode",
    ACCESS_LEVEL_MASTER_ID: "companyCustomRulePermission.accessLevelMasterId",
    ACCESS_LEVEL_MASTER_CHILD_LIST: "companyCustomRulePermission.accessLevelMasterChildList"
  }
}

export default AccessLevelCompanyCustomRuleKeys
