import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculatorSimple } from '@fortawesome/pro-light-svg-icons'
import { components } from 'cng-web-lib'
import CommonFtaTranslationText from 'src/views/common/CommonFtaTranslationText'
import Utils from 'src/shared/Utils'

const {
  button: { CngButton }
} = components

function CalculateButton(buttonProps) {
  // Locale
  const CommonFtaTranslatedTextsObject = CommonFtaTranslationText()

  return (
    <CngButton
      color='primary'
      startIcon={<FontAwesomeIcon icon={faCalculatorSimple} />}
      {...buttonProps}
    >
      {CommonFtaTranslatedTextsObject.btnCalculate}
    </CngButton>
  )
}

export default CalculateButton
