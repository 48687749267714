const ShipmentProviderKeys = {
  'MODULE_TITLE': 'Shipment Provider',
  'SHIPMENT_PROVIDER_LIST': 'Shipment Provider List',
  'SHIPMENT_PROVIDER_DETAILS': 'Shipment Provider Details',
  'CREATE_SHIPMENT_PROVIDER': 'Create Shipment Provider',
  'EDIT_SHIPMENT_PROVIDER': 'Edit Shipment Provider Details',
  'SHIPMENT_PROVIDER_NAME': 'Name',
  'SHIPMENT_PROVIDER_CODE': 'Code',
  'PACKAGE_TYPES': 'Package Types',
  'IS_ACTIVE': 'Active',
  'IS_MANAGED_BY_GETS': 'Managed By GeTS', //Default Party Information
  'SHIPPER_DETAILS_TITLE': 'Shipper Details',
  'CONSIGNEE_DETAILS_TITLE': 'Consignee Details',
  'CONNECTOR_CONFIGURATIONS': 'Connector Configurations',
  'CONTACT_NAME': 'Name',
  'CONTACT_PHONE': 'Phone',
  'FAX': 'Fax',
  'EMAIL': 'Email',
  'COMPANY_NAME': 'Company Name',
  'TAX_NO': 'Tax no',
  'ADDRESS': 'Address',
  'CITY': 'City',
  'STATE': 'State',
  'COUNTRY': 'Country',
  'POSTAL_CODE': 'Postal/ZIP Code',
  'ADDRESS_TYPE': 'Address Type',
  'PARTY_NAME': 'Party Name',
  'ALLOWED_PARTIES': 'Allowed Parties',
  'CONNECTOR_NAME': 'Connector Name',
  'ENDPOINT_NAME': 'Endpoint Name',
  'CREDENTIAL_NAME': 'Credential Name',
  'COURIER_CONNECTOR_CONFIGURATIONS': 'Courier Connector Configurations',
  'CUSTOMER_CONNECTOR_CONFIGURATIONS': 'Customer Connector Configurations',
  'FULFILMENT_CONNECTOR_CONFIGURATIONS': 'Fulfilment Connector Configurations',
  'CONNECTOR_MAPPING': 'Connector Mapping',
  'VARIABLE_NAME': 'Variable Name',
  'VARIABLE_VALUE': 'Value',
  'VARIABLES': 'Variables'
}
export default ShipmentProviderKeys