import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import {
    Chip
} from '@material-ui/core'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import Utils from 'src/views/common/utils/Utils'
const {
    CodeMaintenanceType
} = constants

const PortViewField = (props) => {

    const { fetchRecords } = useServices()
    const [port, setPort] = useState([])
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {

        if (!Utils.isEmpty(props.portCode)) {
            CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.PORT,
                [{
                    field: 'code',
                    operator: 'equal',
                    value: props.portCode
                }], false, onLoadPortSuccess)
        }
    }, [props])

    // useFetchCodeMaintainance(CodeMaintenanceType.PORT,
    //     [{
    //         field: 'code',
    //         operator: 'EQUAL',
    //         value: props.portCode == null ? '' : props.portCode
    //     }], false, onLoadPortSuccess)

    function onLoadPortSuccess(data) {
        //console.log("onLoadPortSuccess")
        //console.log(data)
        if (data.length == 0) {
            console.log("null")
        }
        setPort(data)
        setShouldRender(true)
    }

    if (!shouldRender) {
        return null;
    }



    // alert(props.portCode)
    //console.log('PortViewField' + JSON.stringify(port))

    return (

        <div className={'rs-content'}>
            <div>
                <img
                    src={
                        process.env.PUBLIC_URL +
                        '/static/images/countries/' +
                        (port.length === 0 ? "" : port[0].countryCode) +
                        '.svg'
                    }
                    className={'view-field-img'}
                />
            </div>
            <div>
                <div style={{ wordBreak: 'break-word', whiteSpace: "pre-line" }}>{(port.length === 0 ? "" : port[0].descriptionEn)}</div>
            </div>
            <div>
                <span className='pl-10'>
                    <Chip label={<b>{(port.length === 0 ? "" : port[0].code)}</b>} size='small' />
                </span>
            </div>
        </div>
    )
}

export default PortViewField