const CommonCityMappingKeys = {
      COMMON_CITY_MAPPING_TITLE: 'commonCityMapperTitle',
      COUNTRY_CODE: 'countryCode',
      COMMON_CITY_NAME: 'commonCityName',
      LOC_CODE: 'locCode',
      PARTY: 'party',
      COUNTRY: 'country',
      REQUIRED_FIELD: 'requiredField',
      CANCEL: 'cancel',
      BACK: 'back',
      ADD_COMMON_CITY_TITLE: 'addCommonCityTitle',
      EDIT_COMMON_CITY_TITLE: 'editCommonCityTitle',
      VIEW_COMMON_CITY_TITLE: 'viewCommonCityTitle'
}

export default CommonCityMappingKeys