import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import { faRetweetAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const {
  button: { CngPrimaryButton }
} = components

function RevokeButton(buttonProps) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <CngPrimaryButton {...buttonProps}>
        <FontAwesomeIcon icon={faRetweetAlt} />
        &nbsp;
        {buttonProps.otherText != null
          ? buttonProps.otherText
          : translatedTextsObject.back}
      </CngPrimaryButton>
    </div>
  )
}

export default RevokeButton
