const ShippingInstructionRequestKeys = {
  TITLE: "title",
  ID: "id",
  DOCUMENT_NAME: "Docname",
  MESSAGE_ID: "Msgid",
  SENDERID: "Senderid",
  RECIPIENTID: "Recipientid",
  SENTDATE: "Sentdate",
  SENTTIME: "Senttime",
  MESSAGE_FUNCTION: "Msgfunction",
  ORDER_TYPE: "Ordertype",
  NBR_OF_CONSTS_TO_STUFF: "Nborfcontstostuff",
  NBR_OF_PRELOAD_CONTS_TO_EXPORT: "Nbrofpreloadcontstoexport",
  SHIPPER_REFERNCE: "Shipperref",
  BOOKING_REFERNCE: "Bookingref",
  SHIPPER_PARTYCODE: "Shipper Partycode",
  SHIPPER_PARTYNAME: "Shipper Partyname",
  SHIPPER_ADDRESS_LINE: "Shipper Addrline",
  SHIPPER_CONTACT_PERSON_NAME: "Shipper ContactPersonName",
  SHIPPER_EMAIL_ADDRESS: "Shipper EmailAddress",
  CARRIER_PARTYCODE: "Carrier Partycode",
  CARRIER_PARTYNAME: "Carrier Partyname",
  CONSIGNEE_PARTYNAME: "Consignee Partyname",
  CONSIGNEE_ADDRESS_LINE: "Consignee Addrline",
  NOTIFYPARTY_PARTYNAME: "Notifyparty Partyname",
  STUFFING_DATE: "Stuffingdate",
  STUFFING_LOCATION: "Stuffinglocation",
  VESSEL_NAME: "Vesselname",
  VOYAGE_NBR: "Voyagenbr",
  ETA: "Eta",
  ETD: "Etd",
  PLACE_OF_RECEIPT: "Placeofreceipt",
  PORT_OF_LOADING: "Portofloading",
  PORT_OF_DISCHARGE: "Portofdischarge",
  PLACE_OF_DELIVERY: "Placeofdelivery",
  RECEIPT_STATUS: "Receiptstatus",
  DELIVERY_STATUS: "Deliverystatus",
  ITEM_LINE_NBR: "Itemlinenbr",
  ITEM_CODE: "Itemcode",
  ITEM_DESCRIPTION: "Itemdesc",
  GROSSWT_VALUE: "Grosswt Value",
  GROSSWT_UNITCODE: "Grosswt Wtunitcode",
  GROSS_VOLUME_VALUE: "Grossvol Value",
  GROSS_VOLUME_UNITCODE: "Grossvol Volunitcode",
  TYPE_OF_PKG: "Typeofpkg",
  TOTAL_NBR_OF_PKGS: "Total Nbrofpkgs",
  MARKINGS: "Markings",
  FILE_TYPE: "File Type",
  SOURCE: "Source",
  RECORD_STATUS: "Record Status",
  PROCESSED_DATE: "Processed Date",
  PARTY: "Party",
  CARGO_DELIVERY_DATE: "Cargo Delivery Date",
  CARGO_READY_DATE: "Cargo Ready Date",
  ORIGIN_RECEIPT: "Origin Receipt",
  DESTINATION_RECEIPT: "Dest Receipt"
}

export default ShippingInstructionRequestKeys
