const TcalPartyCarrierCodeKeys = {
  TITLE: "title",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  PARTY_CC_ID: "partyCcId",
  CARRIER_CODE2: "2 Byte Code",
  CARRIER_CODE3: "3 Byte Code",
  PARTY_ID: "SI No Carrier / NVOCC / Booking Agent"
}

export default TcalPartyCarrierCodeKeys
