const SCOJob = {
    JOB_CONFIGURATION_TABLE_NAME: 'jobConfigurationTable',
    JOB_CONFIGURATION_TITLE: 'jobConfiguration',
    JOB_CONFIGURATION_NAME: 'jobConfigurationName',
    CREATED_BY: 'createdBy',
    CREATED_ON: 'createdOn',
    JOB_CONFIGURATION_WORKFLOW_TITLE: 'jobWorkflow',
    JOB_CONFIGURATION_TASK_SEQUENCE_TITLE: 'jobTaskSequence',
    JOB_CONFIGURATION_TASK_TYPE: 'jobTaskType',
    JOB_CONFIGURATION_TASK_DESCRIPTION: 'jobTaskDescription',
    JOB_CONFIGURATION_TASK_ATTRIBUTE: 'Task Attributes',
    JOB_CONFIGURATION_ESTIMATED_DURATION: 'jobEstimatedDuration',
    JOB_CONFIGURATION_CONTACT_LIST: 'jobContactListTitle',
    JOB_CONFIGURATION_EMAIL_ADDRESS: 'jobEmailAddress',
    ADD_JOB_CONFIGURATION_MODULE_TITLE: 'addJobConfig',
    VIEW_JOB_CONFIGURATION: 'viewJobConfig',
    EDIT_JOB_CONFIGURATION: 'editJobConfig',
    DISCARD_JOB_CONFIG_TITLE: 'discardJobConfigTitle',
    FIELD_REQUIRED: 'fieldRequired',
    MY_JOB: 'myJob',
    DRAFT_JOB: 'draftJob',
    JOB_ID: "jobId",
    JOB_TYPE: "jobType",
    JOB_DESCRIPTION: "jobDescription",
    SHIPPER_REF_NO: "shipperRefNo",
    COMPLETENESS: "completeness",
    CREATE_NEW_JOB: "createNewJob",
    JOB_DETAILS: "jobDetails",
    JOB_TASK_LIST: "taskList",
    JOB_STATUS: "taskStatus",
    JOB_STATUSES: "jobStatus",
    JOB_TIMEZONE: "timeZone",
    JOB_USER_ASSIGN: "userAssign",
    JOB_REMARKS: "remarks",
    JOB_RECIPIENT_NAME: "recipientName",
    JOB_RECIPIENT_ADDRESS: "recipientAddress",
    JOB_RECIPIENT_POSTAL_CODE: "recipientPostalCode",
    JOB_RECIPIENT_EMAILL_ADDRESS: "recipientEmailAddress",
    JOB_RECIPIENT_CONTACT_NUM: "recipientContactNumber",
    ADDITIONAL_TASK_DETAILS: "additionalTaskDetails",
    ORIGIN: "origin",
    DESTINATION: "destination",
    CREATED_DATE: "createdDate",
    DOCUMENTS: "documents",
    DOCUMENTS_INSTRUCTION: "documentInstruction",
    ATTACH_DOCUMENT: "attachDocument",
    LOCAL_EVENT_TIMESTAMP: "localEventTimestamp",
    UPDATED_BY: "updatedBy",
    DATE_AND_TIME: "dateAndTimeGMT",
    EVENT_TIMEZONE: "eventTimeZone",
    ADD_JOB_TASK: "addJobTask",
    TASK_STATUS: "jobTaskStatus",
    DISCARD_JOB_TITLE: "discardJobTitle",
    DISCARD_JOB: "discardJobChanges",
    FORM_ERROR: "formError",
    EDIT_JOB_DETAILS: "editJobDetails",
    IS_DRAFT: "isDraft",
    PORT_OF_LOADING: "portOfLoading",
    PORT_OF_DISCHARGE: "portOfDischarge",
    CUSTOMER: "customer",
    RATE_OWNER: "rateOwner",
    RATE_CARD: "rateCard",
    JOB_ATTRIBUTES: "jobAttributes",
    ATTRIBUTES_TO_ADD: "attributeToAdd",
    TASK_ATTRIBUTES: "taskAttributes",
    ATTRIBUTE_TYPE: "attributeType",
    ATTRIBUTE_NAME: "attributeName",
    DATA_TYPE: "dataType",
    JOB_ATTRIBUTE_CONFIGURATION: "jobAttributeConfiguration",
    ATTRIBUTE_CONFIGURATION_DETAILS: "attributeConfigurationDetails",
    CREATE_NEW_JOB_ATTRIBUTE: "createNewJobAttribute",
    EDIT_JOB_ATTRIBUTE: "editJobAttribute",
    VIEW_JOB_ATTRIBUTE: "viewJobAttribute",
    JOB_ATTRIBUTE: "jobAttribute",
    ATTRIBUTE: "attribute",
    NO_ATTRIBUTE_CONFIGURED: "noAttributeConfigure",
    SELECT_ATTRIBUTE: "selectAttribute",
    ATTRIBUTE_VALUE: "value",
    JOB_SENDER_NAME: "senderName",
    JOB_SENDER_ADDRESS: "senderAddress",
    JOB_SENDER_POSTAL_CODE: "senderPostalCode",
    JOB_SENDER_EMAILL_ADDRESS: "senderEmailAddress",
    JOB_SENDER_CONTACT_NUM: "senderContactNumber",
    CONFIRM_DELETE_TASK: "confirmDeleteTask",
    CONFIRM_RESTORE_TASK: "confirmRestoreTask",
    DELETE_TASK: "deleteTask",
    RESTORE_TASK: "restoreTask",
    ACTIVE_TASKS: "activeTasks",
    DELETED_TASKS: "deletedTasks",
    DOWNLOAD_TEMPLATE: "downloadTemplate",
    UPLOAD_DOCUMENT: "uploadDocument",
    OUTPUT_DOC_TYPE: "outputDocumentType",
    RESULTS: "results",
    NUM_OF_INPUT_DOCS: "numberOfInputDocuments",
    UPLOADING_DOC: "uploadingDocument",
    UPLOAD_COMPLETE: "uploadComplete",
    DOCUMENT_CONVERSION_IP: "documentConversionInProgress",
    DOCUMENT_CONVERSION: "documentConversion",
    DOCUMENT_CONVERSION_DETAILS: "documentConversionDetails",
    CREATE_DOCUMENT_CONVERSION: "createDocumentConversion",
    INPUT_DOCUMENT: "inputDocument",
    ACTIVE : "Active",
    COMPLETED : "Completed",
    URC_NO: "ucrNo",
    MOVEMENT_TYPE: "movementType",
    JOB_REQUEST_SENT:"jobRequestSent",
    JOB_REQUEST_RECEIVED: "jobRequestReceived",
    JOB_REQ_REF_NO: "jobReqRefNo",
    VENDOR: "vendor",
    REQUEST_STATUS: "requestStatus",
    REQUESTOR: "requestor",
    JOB_REQUEST:"jobRequest",
    SKU_NUMBER: "SKU Number",
    SKU_DESC: "SKU Description",
    LOT_NUMBER: "Lot Number",
    QUANTITY: "Quantity",
    UOM_CAPITAL: "UOM",
    UPDATE_INV_FORM_TITLE:"Update Inventory Form",
    BUYER: "Buyer",
    FROM_DUTY_STATUS: "From Duty Status",
    FROM_PERMIT_TYPE: "From Permit Type",
    LOCATION: "Location",
    OPERATOR: "Operator",
    TOTAL_QUANTITY: "Total Quantity",
    AVAILABLE_QUANTITY_LOOSE: "Available Qty (Loose)",
    REFERENCE_NUMBER: "Reference Number",
    OUTSOURCE_TO: "Outsource to",
    CUSTOM_ATTRIBUTE: "Custom Attribute Name",
    JOB_TASK_COMPLETED: "Tasks Completed",
    CANCELLED: "Cancelled",
    CANCEL_JOB: "Cancel Job",
    CLONE_JOB: "Clone"
}
export default SCOJob