import backend from 'i18next-http-backend'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(backend)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
    }
  })

export default i18n
