import React, { useEffect, useRef, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'

import {
    Chip
} from '@material-ui/core'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import useFetchCodeMaintainance from 'src/views/common/serviceHook/useFetchCodeMaintainance'

const {
    CodeMaintenanceType
} = constants

const CountryViewField = (props) => {

    const { fetchRecords } = useServices()
    const [country, setCountry] = useState([])
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {
        if(props.countryList != null && props.countryList.length > 0){
            let country = props.countryList[
                props.countryList.findIndex(
                    (country) => country.code == props.countryCode
                )
            ]
            setCountry([country])
            setShouldRender(true)
        }else{
            CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.COUNTRY,
                [{
                    field: 'code',
                    operator: 'EQUAL',
                    value: props.countryCode
                }], false, onLoadCountrySuccess)
        }
        
    },[props])

    function onLoadCountrySuccess(data) {
        console.log("onLoadCountrySuccess")
        // console.log(data)
        // if (data.length == 0) {
        //     console.log("null")
        // }
        setCountry(data)
        setShouldRender(true)
    }

    if (!shouldRender) {
        return null;
    }

    console.log('CountryViewField' + JSON.stringify(country))

    return (

        <div className={'rs-content'}>
            <div>
                <div >{(country.length === 0 ? "" : country[0].descriptionEn)}</div>
            </div>
        </div>
    )
}

export default CountryViewField