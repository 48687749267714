const AdminlogKeys = {
  TITLE: "title",
  CREATED_BY: "createdBy",
  EXPORTED_DATE: "exportedDate",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  FILE_CONTENT: "fileContent",
  FILE_NAME: "fileName",
  FILE_SIZE: "fileSize",
  PARTY_ID: "partyId",
  CUSTOMER_GROUP_CODE: "customerGroupCode",
  ORG_CODE: "orgCode",
  SCHEME: "scheme",
  PARTY_USER_GROUP: "partyUserGroup",
  FILE_CONTENT_DATE_CREATED: "fileContentDateCreated",
  FILE_CONTENT_FILENAME: "fileContentFilename",
  FILE_CONTENT_FILESIZE: "fileContentFilesize",
  RESPONSE_DATE_CREATED: "responseDateCreated",
  RESPONSE_FILENAME: "responseFilename",
  RESPONSE_FILESIZE: "responseFilesize",
  RESPONSE: "response",
  REQUEST: "request",
  INPUT: "input",
  API_RESPONSE: "apiResponse",
  ACTION: "action",
  OUTBOUND_REF_N: "outboundRefN",
  API_URL: "apiUrl",
  OUTBND_INSTR_UPLD_ID: "outbndInstrUpldId",
  PROD_CATEGORY_CODE: "productCategoryCode",
  PROD_CODE: "productCode",
}

export default AdminlogKeys
