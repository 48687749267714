const CodeMappingKeys = {
  TITLE: "title",
  CODE_MAPPING_ID: "codeMappingId",
  TYPE_NAME: "typeName",
  CALISTA_CODE: "calistaCode",
  OTHER_PLATFORM_CODE: "otherPlatformCode",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  PLATFORM: "platform",
  TYPE: "type",
  EDI_CODE: "ediCode",
  CODE_DESC: "codeDesc",
  VERSION: "version"
}

export default CodeMappingKeys
