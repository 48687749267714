const TmpSysConfigKeys = {
    TMP_SYS_CONFIG: "tmpSysConfig",
    TMP_SYS_CONFIG_TITLE: "tmpSysConfigTitle",
    TMP_SYS_CONFIG_EDIT: "tmpSysConfigEdit",
    TMP_SYS_CONFIG_VIEW: "tmpSysConfigView",
    TMP_SYS_CONFIG_GROUP: "tmpSysConfigGroup",
    TMP_SYS_CONFIG_CONFIG_CODE: "tmpSysConfigConfigCode",
    TMP_SYS_CONFIG_VALUE: "tmpSysConfigValue",
    TMP_SYS_CONFIG_DESCRIPTION: "tmpSysConfigDescription",
    TMP_SYS_CONFIG_ACTION: "tmpSysConfigAction",
    TMP_SYS_CONFIG_REMARK: "tmpSysConfigRemark",
    TMP_SYS_CONFIG_DISPLAY_NAME: "tmpSysConfigDisplayName"
}

export default TmpSysConfigKeys