const QuotationKeys = {
    MODULE_TITLE: "module-title",
    SUMMARY_HEADER: "summary-header",
    QUOTATION_HEADER: "quotation-header",
    QUOTATION_LIST: "quotation-list",
    QUOTATION_DETAIL: "quotation-detail",
    QUOTATION_DETAIL_EDIT: "quotation-detail-edit",
    FIELDS: {
        PARTY: "fields.party",
        REQUEST_UUID: "fields.request-uuid",
        QUOTATION_REF_NO: "fields.quotation-ref-no",
        STATUS: "fields.status",
        REQUEST_INFO: "fields.request-info",
        CREATED_AT: "fields.created-at",
        UPDATED_AT: "fields.updated-at"
    }
}

export default QuotationKeys

