const NmotKeys = {
  HINTERLAND_DEST_ML: 'hinterlandDestML',
  LOCODE: 'loCode',
  PLACE: 'place',
  COUNTRY: 'country',
  ACTIVE: 'active',
  CREATED_DATE: 'createdDate',
  UPDATED_DATE: 'updatedDate',
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
  BACK: 'back',
  REFRESH: 'refresh',
  DISCLAIMER_TITLE: 'disclaimerTitle',
  ACCEPT: 'accept',
  ADD_DESTINATION_TITLE: 'addDestinationTitle',
  HINTERLAND_LOCATION_DETAILS: 'hinterlandLocationDetails',
  REMOVE: 'remove',
  ADD_HINTERLAND_DEST_SUCCESS: 'addHinterlandDestSuccess',
  EDIT_DESTINATION_TITLE: 'editDestinationTitle',
  EDIT_HINTERLAND_DEST_SUCCESS: 'editHinterlandDestSuccess',
  APPLY: 'apply',
  NMOT_ADMIN: 'nmotAdmin',
  NMOT_LOGS: 'nmotLogs',
  ADD_NMOT_USER_TITLE: 'addNmotUserTitle',
  NMOT_USER_DETAILS: 'nmotUserDetails',
  ADD_NMOT_USER_SUCCESS: 'addNmotUserSuccess',
  EDIT_NMOT_USER_TITLE: 'editNmotUserTitle',
  EDIT_NMOT_USER_SUCCESS: 'editNmotUserSuccess',
  LOGIN_ID: 'loginId',
  THIRD_PARTY: 'thirdParty',
  ACTION: 'action',
  DOWNLOAD: 'download',
  UPLOAD: 'upload',
  RESET: 'reset',
  UPLOADED_FILE: 'uploadedFile',
  RESPONSE: 'response',
  SELECT_DOWNLOAD_FILE: 'selectDownloadFile',
  DOWNLOAD_UPLOAD_FILE: 'downloadUploadFile',
  DOWNLOAD_RESPONSE_FILE: 'downloadResponseFile',
  DOWNLOAD_API_LOG_FILE: 'downloadApiLogFile',
  DOWNLOAD_FILE_SUCCESS: 'downloadFileSucess',
  NMOT_ADD: 'nmotAdd',
  ADD_NMOT_SUCCESS: 'addNmotSuccess',
  UPDATE_NMOT_SUCCESS: 'updateNmotSuccess',
  NMOT_ADD_TILE: 'nmotAddTitle',
  NMOT_UPLOAD: 'nmotUpload',
  CONT_NO: 'contNo',
  NMOT: 'nmot',
  HINTERLAND_DEST: 'hinterlandDest',
  NEXT_CARRIER: 'nextCarrier',
  BLNO: 'blNo',
  GOOD_DESC: 'goodDesc',
  PICKUP_TIME: 'pickupTime',
  STATUS: 'status',
  FILE_CONTENT: 'fileContent',
  UPLOAD_FORM: 'uploadForm',
  TEMPLATE: 'template',
  UPLOAD_FILE_SUCCESS: 'uploadFileSuccess',

  NMOT_DASHBOARD: 'nmotdashboard',
  SL_NO: 'slNo',
  E_TO_D: 'eToD',
  E_TO_D_DESC: 'eToDdesc',
  GATE_OUT: 'gateOut',
  OVERALL: 'overall',
  ON_YARD_TERMINAL: 'onYardTerminal',
  COMM_RELEASE: 'commercialRelease',
  CUSTOMS_RELEASE: 'customsRelease',
  NOT_BOT: 'notBot',
  UPLOADED_CREATED_DATE: 'uploadedCreatedDate',
  ARCHIVE_DELETE_SELECTED: 'archiveDeleteSelected',
  ARCHIVE_DELETE_DOWNLOAD_ALL: 'archiveDeleteDownloadAll',
  ARCHIVE_ALL: 'archiveAll',
  DELETE_ALL: 'deleteAll',
  DOWNLOAD_ALL: 'downloadAll',
  NMOT_EDIT: 'nmotEdit',
  NMOT_EDIT_TILE: 'nmotEditTitle',
  NMOT_VIEW: 'nmotView',
  NMOT_VIEW_TILE: 'nmotViewTitle',
  ARCHIVE_SELECTED: 'archiveSelected',
  DELETE_SELECTED: 'deleteSelected',
  RECORDS_ARCHIVE_SUCCESS: 'recordsArchiveSuccess',
  RECORDS_DELETE_SUCCESS: 'recordsDeleteSuccess',
  RECORDS_DOWNLOAD_SUCCESS: 'recordsDownloadSuccess',
  WARNING: 'warning',
  CONFIRM: 'confirm',
  UPDATING: 'updating',
  JUST_REFRESHED: 'justRefreshed',
  NOTE: 'note',
  ERROR_REFRESH: 'errorRefresh'
}

export default NmotKeys
