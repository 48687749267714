const CecUomConversionKeys = {

	//------------UOM Conversion------------------
	CEC_UOM_CONVERSION: "cecUomConversion",
	CEC_UOM_CONVERSION_TITLE: "cecUomConversion",
	CEC_UOM_CONVERSION_CREATE: "cecUomConversionCreate",
	CEC_UOM_CONVERSION_EDIT: "cecUomConversionEdit",
	CEC_UOM_CONVERSION_VIEW: "cecUomConversionView",
	CEC_UOM_CONVERSION_UOMTYPE: "cecUomConversionUomType",
	CEC_UOM_CONVERSION_INPUTUOM: "cecUomConversionInputUom",
	CEC_UOM_CONVERSION_OUTPUTUOM: "cecUomConversionOutputUOM",
	CEC_UOM_CONVERSION_CONVERSION: "cecUomConversionConversion"

}

export default CecUomConversionKeys