import {
  Button,
  styled, withTheme,
  fade
} from '@material-ui/core'
import React from 'react'

const get = require('lodash/get')

const DEFAULT_COLORS = ['primary', 'secondary']
const IGNORE_COLORS = ['default', 'inherit']
const getColor = props => {
  let color = props.customColor || props.color
  if (IGNORE_COLORS.includes(color) || !color)
    return null
  if (DEFAULT_COLORS.includes(color))
    return props.theme.palette[color].main
  if (typeof color === 'string')
    return get(props.theme.palette, color, color)
}

const getDefault = props => props.theme.palette.grey[300]

export const OutlinedButton = withTheme(
  styled( ({ customColor, theme,...other }) => <Button variant='outlined' {...other} /> )
  ({
    borderColor: props => props.theme.palette.divider,
    color: getColor,
    '&:hover': {
      borderColor: props => fade(getColor(props) || getDefault(props), .4)
    }
  })
)
export const FadedButton = withTheme(
  styled( ({ customColor, theme,...other }) => <Button variant='contained' {...other} /> )
  ({
    color: getColor,
    backgroundColor: props => fade(getColor(props) || getDefault(props), .15),
    '&:hover': {
      backgroundColor: props => fade(getColor(props) || getDefault(props), .7),
      color: props => props.theme.palette.getContrastText(getColor(props) || getDefault(props))
    }
  })
)
