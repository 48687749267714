const EcommerceLabelKeys = {
	ECOMMERCE: "ecommerce",
	LABEL_PAGE_TITLE: "labelPageTitle",
	
	FILENAME: "filename",
	LAST_MILE_PROVIDER: "lastMileProvider",
	CREATED_ON: "createdOn",
	NO_OF_DO: "noOfDO",
	SYSTEM_RESPONSE: "systemResponse",
	STATUS: "status",
	ACTION: "action",

	DOWNLOAD_SUCCESS_MESSAGE: "downloadSuccessMessage",
	SEARCH_FILE_MSG: "searchFileMessage",

	//Mass Print Msg
	GENERATE_MSG: "generateMessage",
	MULTIPLE_PROVIDER_MSG: "multipleProviderMessage",
	PRINTED_MSG:"printedMessage",
	CONFIRM_MSG:"confirmMessage",
	SHIPMENT_PROVIDER_NOT_CONFIGURE_MSG: "shipmentProviderNotConfigureMessage",

	//Pop up dialog title
	WARNING_TITLE:"warningTitle",
	ERROR_TITLE:"errorTitle",
	GENERATING_LABEL_TITLE:"generatingLabelTitle"


	

}

export default EcommerceLabelKeys