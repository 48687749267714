import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
} = components

function AmendButton(buttonProps) {

  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <CngSecondaryButton {...buttonProps}><EditIcon />{translatedTextsObject.amend}</CngSecondaryButton>
    </div>
  )

}

export default AmendButton
