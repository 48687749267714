const CalistaPartyConfigKeys = {

	// General
	TITLE: "title",

	// For Table list view
	PARTY_NAME: "partyName",

	// For the party summary view form
	PARTY_SUMMARY_SECTION_HEADER: "partySummarySectionHeader",

	// System Integration
	SYS_INTEGRATION_SECTION_HEADER: "sysIntegrationSectionHeader",
	SYS_INTEGRATION_USER_ID: "sysIntegrationUserId",

	// TAS
	TAS_SECTION_HEADER: "tasSectionHeader",
	TAS_IS_TAS_PARTY: "tasIsTasParty",
	TAS_IS_SEARCH_RESTRICTED: "tasIsSearchRestricted",
	TAS_SUBSCRIPTION_TYPE_CODE: "tasSubscriptionTypeCode",
	TAS_RESTRICTED_COUNTRY_CODES: "tasRestrictedCountryCodes",
	TAS_OUTOF_RESTRICTED_COUNTRY_SEARCH_ALLOWED: "tasOutofRestrictedCountrySearchAllowed",
	TAS_TRANSACTION_LIMIT_TYPE: "tasTransactionLimitType",
	TAS_TRANSACTION_LIMIT_VALUE: "tasTransactionLimitValue",
	TAS_HS_CLASSIFICATION_LIMIT_VALUE: "tasHsClassificationLimitValue",
	TAS_COMPLIANCE_DEFAULT_LIMIT_VALUE: "tasComplianceDefaultLimitValue",
	TAS_LANDED_COST_LIMIT_VALUE: "tasLandedCostLimitValue",
	TAS_TRANS_LIMIT_FROM_DATE: "tasTransLimitFromDate",
	TAS_TRANS_LIMIT_TO_DATE: "tasTransLimitToDate",
	TAS_IS_COMPLIANCE_RESTRICTED: "tasIsComplianceRestricted",
	TAS_CIA_SUBSCRIPTION_TYPE_CODE: "tasCiaSubscriptionTypeCode",
	TAS_CIA_SUBSCRIPTION_STATUS_CODE: "tasCiaSubscriptionStatusCode",

	// CeC
	CEC_SECTION_HEADER: "cecSectionHeader",
	CEC_IS_CEC_PARTY: "cecIsCecParty",
	CEC_SUBSCRIPTION_TYPE_CODE: "cecSubscriptionTypeCode",
	CEC_SUBSCRIPTION_END_DATE: "cecSubscriptionEndDate",

	//FlexiAlert
	FLEXI_SECTION_HEADER: "flexiSectionHeader",
	FLEXI_SMS_NOTIFICATION_SUB: "flexiSmsNotificationSub",

	//Tab Titles
	TAB_TITLE_MODULE_SETTINGS: "moduleSettingsTabTitle",
	TAB_TITLE_COMPANY_INFO: "companyInfoTabTitle",
	TAB_TITLE_MICROSITE: "micrositeTabTitle"
}

export default CalistaPartyConfigKeys
