import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  button: { CngSecondaryButton }
} = components


function DiscardButton(buttonProps) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  
  return <CngSecondaryButton {...buttonProps}>{translatedTextsObject.discard}</CngSecondaryButton>
}


export default DiscardButton
