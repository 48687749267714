const NBSChargeCodeMappingKeys = {
    TITLE: "NBS Charge Code Mapping",
    FIELDS: {
        //NBS CHARGE CODE MAPPING
        CHARGE_CODE: "fields.charge-code",
        COUNT_TYPE: "fields.count-type",
        POSTING_FREQ: "fields.posting-freq",

    }
}

export default NBSChargeCodeMappingKeys