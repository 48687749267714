const ReportSchedulerKeys = {
    REPORT_SCHEDULER_ID: "Report Scheduler Id",
    TITLE: "Report Scheduler",
    PARTY: "Party",
    REPORT_NAME: "Report Name",
    SCHEDULE_NAME: "Schedule Name",
    FREQUENCY: "Schedule Frequency",
    LAST_REPORT: "Last Report",
    LAST_RUN_DATE: "Last Run Date",
    ACTION: "Action",
    SCHEDULED_START_DATE: "Schedule Start Date",
    SCHEDULED_END_DATE: "Schedule End Date",
    USERS: "Users",
    BOOKING_CREATED_FROM: "Booking Created From",
    BOOKING_CREATED_TO: "Booking Created To",
    TIMEZONE: "Time zone",
    SCHEDULED_START_TIME: "Schedule Start Time",
    BLANK_REPORT: "Include Empty Reports"
    // ACCESS_LEVEL_MASTER_CODE: "accessLevelMasterCode",
    // DATE_RANGE_MAX_LIMIT: "dateRangeMaxLimit",
    // RESULT_MAX_LIMIT: "resultMaxLimit"
}

export default ReportSchedulerKeys
