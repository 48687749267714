const SCOJMCustomAttributeKeys = {
  "MODULE_TITLE": "module-title",
  "CUSTOM_ATTRIBUTE_ADD":  "custom-attribute-add",
  "CUSTOM_ATTRIBUTE_EDIT": "custom-attribute-edit",
  "CUSTOM_ATTRIBUTE_VIEW": "custom-attribute-view",
  "HEADER": {
    "CUSTOM_ATTRIBUTE": "header.custom-attribute",
    "CUSTOM_ATTRIBUTE_VALUE": "header.custom-attribute-value"
  },
  "FIELDS": {
      "CATEGORY": "fields.category",
      "NAME": "fields.name",
      "VALUE": "fields.value"
  }
}

export default SCOJMCustomAttributeKeys