import React from 'react'
import { components } from 'cng-web-lib'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
const {
  button: { CngPrimaryButton, CngSecondaryButton },
} = components

function CreateSIButton(buttonProps) {

  const translatedTextsObject = CalistaUiComponentTranslationText()
  return (
    <div>
      <CngPrimaryButton  {...buttonProps}><OpenInNewIcon />
        {buttonProps.direct ?
          translatedTextsObject.createDirectSI :
          translatedTextsObject.createSI}</CngPrimaryButton>
    </div>
  )

}

export default CreateSIButton
